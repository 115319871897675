import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import $, { param } from 'jquery';
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import FetchCart from '../API/FetchCart.js';
// import DeleteItem from '../API/DeleteItem.js';
import FireOrder from '../API/FireOrder.js';
import DeleteCart from '../API/DeleteCart.js';
import FireOrderUpdate from '../API/FireOrderUpdate.js';
// import fetchOffer from '../API/fetchOffer.js';
import Header from '../Header/index.js';
import firebase from 'firebase/app';
import 'firebase/firestore';
import axios from 'axios';
import Select from 'react-select';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import Geocode from "react-geocode";
import language from '../API/language.json';
import imagedir from '../API/imagedir.json';
// import {
//   PayPalScriptProvider,
//   BraintreePayPalButtons,
// } from "@paypal/react-paypal-js";
import { PayPalButton } from "react-paypal-button-v2";
const google = window.google;
var XPay = require('xpay-nexi-js');
// const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });
class Checkout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dishs: [], ids: [], dates: [], times: [], datem: "", timem: "", grandtotal: 0, rcity: "", note: "", comm: "", method: "card",
            gmapsLoaded: false, rlat: 0, rlong: 0, shipping: 0, code: "", discount: 0, discountid: "", rname: "", remail: "",
            comp: localStorage.getItem('compu') && localStorage.getItem('compu') !== null ? localStorage.getItem('compu') : "",
            fullname: localStorage.getItem("firstname") + " " + localStorage.getItem("lastname"),
            page: "", zip: localStorage.getItem('zipu') && localStorage.getItem('zipu') !== null ? localStorage.getItem('zipu') : "", phone: localStorage.getItem('phoneu') && localStorage.getItem('phoneu') !== null ? localStorage.getItem('phoneu') : "", error: "", date: Date.now(), time: Date.now(), loader: "false", orderid: "",
            street: localStorage.getItem('Caddress') !== null ? localStorage.getItem('Caddress') : "",
            city: localStorage.getItem('Ccity') !== null ? localStorage.getItem('Ccity') : "",
            statename: localStorage.getItem('Cstatename') !== null ? localStorage.getItem('Cstatename') : "",
            country: localStorage.getItem('Ccountry') !== null ? localStorage.getItem('Ccountry') : "",
            Latitude: localStorage.getItem('Clatitude') !== null ? localStorage.getItem('Clatitude') : "",
            Longitude: localStorage.getItem('Clonitude') !== null ? localStorage.getItem('Clonitude') : "",
        };

    }
    componentDidMount = async () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        // console.log('params checking payments',params);


        var dish = await FetchCart();
        if (dish.data.length > 0) {
            var title = []
            for (var i = 0; i < dish.data.length; i++) {
                title[i] = {

                    image: imagedir.url + dish.data[i].image,
                    dish_name: dish.data[i].dish_name,
                    price: dish.data[i].price,
                    menu_id: dish.data[i].menu_id,
                    quantity: dish.data[i].quantity,
                    ingrident: dish.data[i].ingrident,
                    allergeni: dish.data[i].allergeni,
                    category: dish.data[i].category,
                    peso: dish.data[i].peso,
                    resturent_name: dish.data[i].resturent_name,
                    resturent_id: dish.data[i].resturent_id,
                    user_id: dish.data[i].user_id,
                    actual_price: dish.data[i].actual_price,
                    confirm_by_restu: "pending",
                    key: dish.ids[i],
                };
            }

            this.setState({ dishs: title, ids: dish.ids }, () => {
                // this.grandtotal();
                this.getcomm();

                if (params.codTrans && params.codTrans !== "" && params.messaggio && params.messaggio == "Message OK") {
                    this.fireorderdata(params.codTrans, params.codOrd);
                }
                if (params.codTrans && params.codTrans !== "" && params.messaggio && params.messaggio !== "Message OK") {
                    this.repeatpayment(params.messaggio);
                }
                if (this.props.location.state && this.props.location.state.address && this.props.location.state.address !== "") {
                    this.setState({
                        street: this.props.location.state.address, phone: this.props.location.state.phone, zip: this.props.location.state.zip, statename: this.props.location.state.statename, address: this.props.location.state.address, city: this.props.location.state.city,
                        country: this.props.location.state.country, Latitude: this.props.location.state.Latitude, Longitude: this.props.location.state.Longitude, shipping: this.props.location.state.shipping,
                        discount: this.props.location.state.discount, grandtotal: this.props.location.state.grandtotal
                    });
                }
                this.genreatedate(dish.data[0].resturent_id);
            })

        }
        setTimeout(() => {
            this.initMap();
        }, 2000);




        console.log = console.warn = console.error = () => { };


    }
    genreatedate = async (id) => {
        const body = {
            restaurant: id,
        };
        var datesdata = await axios.post('https://foodapi.ucooking.it/fetchdate', body, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });

        const moviess = await datesdata.data;
        this.setState({ dates: moviess.message });
    }
    getcomm = async () => {
        const documentSnapshot = await firebase.firestore().collection('restaurant').doc(this.state.dishs[0].resturent_id).get();
        const userData = await documentSnapshot.data();
        var ingredient = await userData;
        if (ingredient) {
            this.setState({ rcity: ingredient.city, comm: ingredient.commission, rlat: ingredient.lat, rlong: ingredient.lng, rname: ingredient.name, remail: ingredient.restaurant_email });
        }
    }
    updatecupon = async (id) => {
        const documentSnapshot = await firebase.firestore().collection('coupons').doc(id).get();
        const userData = await documentSnapshot.data();
        var ingredient = await userData;
        if (ingredient) {
            const documentSnapshot = await firebase.firestore().collection('coupons').doc(id).update({ count: Number(ingredient.count) + 1 });
        }
    }

    fetchAddress(lati, lngi) {
        $('.errorpop2').removeClass('displayblock');
        Geocode.setApiKey("AIzaSyDM4LDXtUX7rM-FHenlmmXZ7jbfbczfqWk");
        Geocode.setLanguage("en");
        Geocode.setRegion("es");
        Geocode.setLocationType("ROOFTOP");
        Geocode.enableDebug();
        Geocode.fromLatLng(lati, lngi).then(

            (response) => {
                var condigtion = response.results[0].address_components.findIndex((obj => obj.types == "street_number"));
                if (condigtion !== -1) {
                    this.fetchstreet(response.results[0].formatted_address);
                    if (response.results[0].place_id) {
                        const address = response.results[0].formatted_address;
                        let city, state, country;
                        for (let i = 0; i < response.results[0].address_components.length; i++) {
                            for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                                switch (response.results[0].address_components[i].types[j]) {
                                    case "locality":
                                        city = response.results[0].address_components[i].long_name;
                                        break;
                                    case "administrative_area_level_1":
                                        state = response.results[0].address_components[i].long_name;
                                        break;
                                    case "country":
                                        country = response.results[0].address_components[i].long_name;
                                        break;
                                }
                            }
                        }
                        this.setState({ statename: state, country: country, city: city, street: response.results[0].formatted_address }, () => {
                            localStorage.setItem('Caddress', response.results[0].formatted_address);
                            localStorage.setItem('Clatitude', lati);
                            localStorage.setItem('Clongitude', lngi);
                            localStorage.setItem('Cstatename', state);
                            localStorage.setItem('Ccountry', country);
                            localStorage.setItem('Ccity', city);
                            this.mekeshiping();

                        });
                    }
                }
                else {
                    this.setState({ error: "Please enter valid address", page: "null" });
                }
            },
            (error) => {
                console.error(error);
                this.setState({ error: "Please enter valid address", page: "null" });
            }
        );
        console.log = console.warn = console.error = () => { };
        console.error('Something bad happened.');
    }
    // deletedish= async (e)=>
    // {
    // if(this.state.dishs.length>1)
    // {
    // var id= e.currentTarget.id;
    // var key = e.currentTarget.dataset.div_name;
    // var objIndex = this.state.dishs.findIndex((obj => obj.key == key));
    // if(objIndex!== -1)
    // {
    // this.state.dishs.splice(objIndex, 1);
    // this.setState({dishs:this.state.dishs},()=>
    // {
    // this.grandtotal();
    // });
    // }
    // var deleted = await DeleteItem(key);
    // }
    // }
    repeatpayment = async (a) => {
        this.setState({ loader: "false" }, () => {
            this.setState({ error: "Payment failure", page: "null" }, () => {
                $('.errorpop2').addClass('displayblock');
            });
        })
        var dish = await FetchCart();
        if (dish.data.length > 0) {
            var title = []
            for (var i = 0; i < dish.data.length; i++) {
                title[i] = {
                    image: dish.data[i].image,
                    dish_name: dish.data[i].dish_name,
                    price: dish.data[i].price,
                    menu_id: dish.data[i].menu_id,
                    quantity: dish.data[i].quantity,
                    ingrident: dish.data[i].ingrident,
                    allergeni: dish.data[i].allergeni,
                    category: dish.data[i].category,
                    peso: dish.data[i].peso,
                    resturent_name: dish.data[i].resturent_name,
                    resturent_id: dish.data[i].resturent_id,
                    user_id: dish.data[i].user_id,
                    actual_price: dish.data[i].actual_price,
                    confirm_by_restu: "pending",
                    key: dish.ids[i],
                };
            }
            setTimeout(
                () =>
                    this.setState({ dishs: title, ids: dish.ids }, () => {
                        this.grandtotal();
                        this.getcomm();
                    }), 1000)

        }
        setTimeout(() => {
            this.initMap();
        }, 2000);



        const body = {
            restaurant: dish.data[0].resturent_id,
        };
        var datesdata = await axios.post('https://foodapi.ucooking.it/fetchdate', body, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });

        const moviess = await datesdata.data;
        this.setState({ dates: moviess.message });
    }
    fireorderdata = async (a, b) => {
        this.setState({ loader: "true" });
        var object = {
            "payment": "success",
            "trans_id": a,
        }
        var submit = await FireOrderUpdate(b, object);
        var newitems = [];
        for (let index = 0; index < this.state.dishs.length; index++) {
            newitems.push({ 'id': submit + index, 'product_id': this.state.dishs[index].menu_id, 'unit_price': this.state.dishs[index].actual_price, 'quantity': this.state.dishs[index].quantity, 'note': this.state.note });
        }
        var bodys = {
            'id': submit, 'user_id': localStorage.getItem("email"), 'date_delivery': this.state.datem, 'time_delivery': this.state.timem, 'discount': this.state.discount,
            'note': this.state.note, 'delivery_cost': this.state.shipping, 'merchant_earning': (this.state.grandtotal - Math.round(this.state.grandtotal) * Number(this.state.comm) / 100), 'discount_code': this.state.discountid,
            'phone': this.state.phone, 'intercom': this.state.comp, 'total_price': Math.round(this.state.grandtotal), 'merchant_id': this.state.dishs[0].resturent_id, 'address': this.state.address, items: newitems
        };
        await axios.post('https://foodapi.ucooking.it/update_order', bodys, {
            headers: {
                Accept: 'application/json',
                "Content-Type": 'application/json',
            },
        });

        var delted = await DeleteCart();
        if (submit && submit !== 0) {
            this.updatecupon(submit);
        }
        this.setState({ loader: "false" }, () => {
            this.setState({ error: "Thanku your order successfully", page: "home" }, () => {
                $('.errorpop2').addClass('displayblock');
            });
        })
    }
    grandtotal() {
        var pretotal = 0;
        for (let index = 0; index < this.state.dishs.length; index++) {
            pretotal += Number(this.state.dishs[index].price);
        }
        this.setState({ grandtotal: pretotal }, () => {
            this.mekeshiping();
        });
    }
    mekeshiping = async () => {
        if (this.state.rlat !== 0 && this.state.rlong !== 0 && this.state.Latitude !== 0 && this.state.Longitude !== 0 && this.state.grandtotal !== 0) {
            const body = {
                rlat: this.state.rlat,
                rlong: this.state.rlong,
                clat: this.state.Latitude,
                clong: this.state.Longitude,
                amt: this.state.grandtotal
            };
            var response3 = await axios.post('https://foodapi.ucooking.it/fetchCost', body, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });

            const movies = await response3.data;
            if (movies.message !== null && movies.message !== 'error') {
                this.setState({ shipping: movies.message.cost });
            }
        }
    }

    errorpops() {
        this.setState({ error: "pleace fill all details" }, () => {
            $('.errorpop2').addClass('displayblock');
        });
    }

    paypalorder = async (data) => {
        if (this.state.error == "") {
            this.setState({ loader: "true" });
            localStorage.setItem('phoneu', this.state.phone);
            localStorage.setItem('zipu', this.state.zip);
            localStorage.setItem('statenameu', this.state.statename);
            localStorage.setItem('countryu', this.state.country);
            localStorage.setItem('compu', this.state.comp);
            localStorage.setItem('cityu', this.state.city);
            localStorage.setItem('streetu', this.state.street);
            var object = {
                "items": this.state.dishs,
                "user_id": localStorage.getItem("userid"),
                "user_name": this.state.fullname,
                "company": this.state.comp,
                "email": localStorage.getItem("email"),
                "street": this.state.street,
                "city": this.state.city,
                "restu_id": this.state.dishs[0].resturent_id,
                "payment": "success",
                "restu_image": "",
                "payment_method": this.state.method,
                "restu_name": this.state.rname,
                "restu_email": this.state.remail,
                "restu_city": this.state.rcity,
                "commition": this.state.comm !== "" ? Math.round(this.state.grandtotal) * Number(this.state.comm) / 100 : Math.round(this.state.grandtotal) * 20 / 100,
                "country": this.state.country,
                "state": this.state.statename,
                "zip": this.state.zip,
                "trans_id": data.id,
                "shipping": this.state.shipping,
                "discount": this.state.discount,
                "coupon_id": this.state.discountid,
                "phone": this.state.phone,
                "note": this.state.note,
                "date": Number(this.state.date) / 1000,
                "time": this.state.time,
                "address": this.state.address,
                "current_date": Date.now(),
                "grandtotal": Math.round(this.state.grandtotal)
            }
            var submit = await FireOrder(object);
            var newitems = [];
            for (let index = 0; index < this.state.dishs.length; index++) {
                newitems.push({ 'id': submit + index, 'product_id': this.state.dishs[index].menu_id, 'unit_price': this.state.dishs[index].actual_price, 'quantity': this.state.dishs[index].quantity, 'note': this.state.note });
            }
            var bodys = {
                'id': submit, 'user_id': localStorage.getItem("email"), 'date_delivery': this.state.datem, 'time_delivery': this.state.timem, 'discount': this.state.discount,
                'note': this.state.note, 'delivery_cost': this.state.shipping, 'merchant_earning': (this.state.grandtotal - Math.round(this.state.grandtotal) * Number(this.state.comm) / 100), 'discount_code': this.state.discountid,
                'phone': this.state.phone, 'intercom': this.state.comp, 'total_price': Math.round(this.state.grandtotal), 'merchant_id': this.state.dishs[0].resturent_id, 'address': this.state.address, items: newitems
            };
            await axios.post('https://foodapi.ucooking.it/insert_order', bodys, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            var delted = await DeleteCart();
            if (submit) {
                this.setState({ loader: "false" }, () => {
                    this.setState({ error: "Thanku your order successfully", page: "home" }, () => {
                        $('.errorpop2').addClass('displayblock');
                    });
                })
            }
        }
        else {
            $('.errorpop2').addClass('displayblock');
        }
    }
    placeoder = async () => {
        if (this.state.error == "") {
            if (this.state.dishs.length > 0) {
                if (this.state.phone == "" || this.state.phone.length < 6 || this.state.zip == "" || this.state.zip.length < 3 || this.state.statename == "" || this.state.statename.length < 3 || this.state.country == "" || this.state.country.length < 3 || this.state.city == "" || this.state.city.length < 3 || this.state.street == "" || this.state.street.length < 3 || this.state.comp == "" || this.state.comp.length < 3 || this.state.fullname == "" || this.state.fullname.length < 3) {
                    this.setState({ error: "pleace fill all details" }, () => {
                        $('.errorpop2').addClass('displayblock');
                    });
                }
                else {
                    this.setState({ loader: "true" });
                    localStorage.setItem('phoneu', this.state.phone);
                    localStorage.setItem('zipu', this.state.zip);
                    localStorage.setItem('statenameu', this.state.statename);
                    localStorage.setItem('countryu', this.state.country);
                    localStorage.setItem('compu', this.state.comp);
                    localStorage.setItem('cityu', this.state.city);
                    localStorage.setItem('streetu', this.state.street);
                    var object = {
                        "items": this.state.dishs,
                        "user_id": localStorage.getItem("userid"),
                        "user_name": this.state.fullname,
                        "company": this.state.comp,
                        "email": localStorage.getItem("email"),
                        "street": this.state.street,
                        "city": this.state.city,
                        "restu_id": this.state.dishs[0].resturent_id,
                        "payment": "pending",
                        "payment_method": this.state.method,
                        "restu_name": this.state.rname,
                        "restu_email": this.state.remail,
                        "restu_city": this.state.rcity,
                        "commition": this.state.comm !== "" ? Math.round(this.state.grandtotal) * Number(this.state.comm) / 100 : Math.round(this.state.grandtotal) * 20 / 100,
                        "country": this.state.country,
                        "state": this.state.statename,
                        "zip": this.state.zip,
                        "trans_id": "",
                        "shipping": this.state.shipping,
                        "discount": this.state.discount,
                        "coupon_id": this.state.discountid,
                        "phone": this.state.phone,
                        "note": this.state.note,
                        "date": Number(this.state.date) / 1000,
                        "time": this.state.time,
                        "current_date": Date.now(),
                        "grandtotal": Math.round(this.state.grandtotal)
                    }
                    var submit = await FireOrder(object);
                    if (submit) {
                        var newitems = [];
                        for (let index = 0; index < this.state.dishs.length; index++) {
                            newitems.push({ 'id': submit + index, 'product_id': this.state.dishs[index].menu_id, 'unit_price': this.state.dishs[index].actual_price, 'quantity': this.state.dishs[index].quantity, 'note': this.state.note });
                        }
                        var bodys = {
                            'id': submit, 'user_id': localStorage.getItem("email"), 'date_delivery': this.state.datem, 'time_delivery': this.state.timem, 'discount': this.state.discount,
                            'note': this.state.note, 'delivery_cost': this.state.shipping, 'merchant_earning': (this.state.grandtotal - Math.round(this.state.grandtotal) * Number(this.state.comm) / 100), 'discount_code': this.state.discountid,
                            'total_price': Math.round(this.state.grandtotal), 'merchant_id': this.state.dishs[0].resturent_id, 'address': this.state.address, items: newitems
                        };
                        await axios.post('https://foodapi.ucooking.it/insert_order', bodys, {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                        });
                        this.setState({ orderid: submit }, () => {
                            var add = this.state.grandtotal + this.state.shipping;
                            var sub = add - this.state.discount
                            var mul = sub * 100;
                            var fnal = Number(mul.toFixed(2));
                            this.paymentgateway(fnal);
                        })
                    }
                }
            }
            else {
                this.setState({ error: "pleace select dishes" }, () => {
                    $('.errorpop2').addClass('displayblock');
                });
            }
        }
        else {
            $('.errorpop2').addClass('displayblock');
        }
    }
    paymentgateway(fnal) {
        var macCode;
        var xpay = new XPay();
        xpay.configure({
            XPAY_KEY: 'ULKLCGIXQAUIZDCI6AQA02JJ5P1PKPP4',
            XPAY_ALIAS: 'ALIAS_WEB_00043094',
            XPAY_RETURN_URL_OK: 'https://www.ucooking.it/Checkout?codOrd=' + this.state.orderid,
            XPAY_RETURN_URL_KO: 'https://www.ucooking.it',
            XPAY_SERVLET_URL: 'https://int-ecommerce.nexi.it/ecomm/ecomm/DispatcherServlet',
        });

        var args = {
            codTrans: "Ucooking_" + Date.now(),
            amount: fnal, // 10 euro
            divisa: 'EUR'
        };
        var xpayLink = xpay.getUrl(args, macCode);
        window.location.href = xpayLink;
        var xpayResponse = {
            codTrans: "Ucooking_" + Date.now(),
            esito: 'OK',
            importo: fnal,
            divisa: 'EUR',
            // data:'20181015',
            data: Date.now(),
            orario: '235711',
            codAut: 'CODAUTH',
            mac: 'MAC',
        };
        var paid = xpay.verify(xpayResponse);
    }

    fetchcompny(e) {
        if (e.target.value == "" || e.target.value.length < 3) {
            $(".placecomp").removeClass("displayblockclass");
            $(".placecomp").addClass("displaynoneclass");
        }
        this.setState({ comp: e.target.value });
    }
    fetchname(e) {
        if (e.target.value == "" || e.target.value.length < 3) {
            $(".placename").removeClass("displayblockclass");
            $(".placename").addClass("displaynoneclass");
        }
        this.setState({ fullname: e.target.value });
    }
    // fetchstreet(e)
    // {
    //   if(e.target.value=="" || e.target.value.length<3 )
    //   {
    //     $(".placestreet").removeClass("displayblockclass");
    //     $(".placestreet").addClass("displaynoneclass");
    //   }
    //   this.setState({street:e.target.value});
    // }

    fetchstreet = address => {
        this.setState({ street: "" });
        if (this.state.street == "") {
            $(".placestreet").removeClass("displayblockclass");
            $(".placestreet").addClass("displaynoneclass");
        }
        this.setState({ address });
    };

    handleSelect = address => {
        this.setState({ address });
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                this.setState({ Latitude: latLng.lat, Longitude: latLng.lng, error: "", street: address });
                this.fetchAddress(latLng.lat, latLng.lng);
            })
            .catch(error => console.error('Error', error));
    };

    fetchcity(e) {
        if (e.target.value == "" || e.target.value.length < 3) {
            $(".placecity").removeClass("displayblockclass");
            $(".placecity").addClass("displaynoneclass");
        }
        this.setState({ city: e.target.value });
    }
    fetchcountry(e) {
        if (e.target.value == "" || e.target.value.length < 3) {
            $(".placecountry").removeClass("displayblockclass");
            $(".placecountry").addClass("displaynoneclass");
        }
        this.setState({ country: e.target.value });
    }
    fetchstate(e) {
        if (e.target.value == "" || e.target.value.length < 3) {
            $(".placestate").removeClass("displayblockclass");
            $(".placestate").addClass("displaynoneclass");
        }
        this.setState({ statename: e.target.value });
    }
    fetchzip(e) {
        if (e.target.value == "" || e.target.value.length < 3) {
            $(".placezip").removeClass("displayblockclass");
            $(".placezip").addClass("displaynoneclass");
        }
        this.setState({ zip: e.target.value });
    }
    fetchphone(e) {
        if (e.target.value == "" || e.target.value.length < 6) {
            $(".placephone").removeClass("displayblockclass");
            $(".placephone").addClass("displaynoneclass");
        }
        this.setState({ phone: e.target.value });
    }
    fetchdate(e) {
        this.setState({ date: e.target.valueAsDate });
    }
    fetchtime(e) {
        this.setState({ time: e.target.valueAsDate });
    }
    removepop() {
        $('.errorpop2').removeClass('displayblock');
        if (this.state.page == "home") {
            this.props.history.push('/');
        }
    }
    fetchloader() {
        if (this.state.loader == "true") {
            return "loaderclass singleloder displayblock";
        }
        else {
            return "loaderclass singleloder";
        }
    }

    initMap = () => {
        this.setState({
            gmapsLoaded: true,
        })
    }
    // getcupen(e)
    // {
    //   var value=e.target.value;
    //   this.setState({code:value},()=>
    //   {
    //     this.getcode();
    //   });
    // }
    // getcode=async()=>
    // {
    //   const documentSnapshot = await firebase.firestore().collection('coupons').where('code','==', this.state.code).get();
    //   const userData = await documentSnapshot.docs.map(doc => doc.data());
    //   const userDataC = await documentSnapshot.docs.map(doc => doc.id); 
    //   if(userData.length>0)
    //   {
    //     for (let index = 0; index < userData.length; index++) {
    //       if(Number(userData[index].mini)<=Number(this.state.grandtotal) && Number(userData[index].max)>=Number(this.state.grandtotal)
    //        && new Date(userData[index].datefrom).getTime()<=new Date().getTime() && new Date(userData[index].dateto).getTime()>=new Date().getTime()
    //        && Number(userData[index].count)<Number(userData[index].count_limit))
    //       {
    //         for (let indexs = 0; indexs < userData[index].restu.length; indexs++) {
    //           if(this.state.dishs[0].resturent_id == userData[index].restu[indexs].id)
    //           {
    //             var amount=0;
    //             if(userData[index].type=="Percentage")
    //             {
    //                amount=Number(this.state.grandtotal)*Number(userData[index].amount)/100;
    //             }
    //             else{
    //                amount=Number(userData[index].amount);
    //             }
    //             this.setState({discount:amount,discountid:userDataC});
    //           }

    //          }
    //       }
    //   }
    //   }
    //   else
    //   {
    //     this.setState({discount:0});
    //   }
    // }


    note(e) {
        this.setState({ note: e.target.value });
    }

    forchanedosage = async (data) => {
        this.setState({ datem: data }, () => {
            this.gettime()
        })
    }

    gettime = async () => {
        const body = {
            date: this.state.datem,
        };
        var datesdata = await axios.post('https://foodapi.ucooking.it/fetchtime', body, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });

        const moviess = await datesdata.data;
        this.setState({ times: moviess.message });
    }

    forchanetime = async (data) => {
        this.setState({ timem: data })
    }
    fetchd(data, actions) {
        return actions.braintree.tokenizePayment(data)
            .then((payload) => {
                // call server-side endpoint to finish the sale

            })
    }
    createOrder(data, actions) {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: "0.01",
                    },
                },
            ],
        });
    }

    onApprove(data, actions) {
        // console.log('success pay data',data);
        // console.log('success pay action',actions);
        return actions.order.capture();
    }
    checkpaypal() {
        this.setState({ method: 'paypal' });
    }
    checkcart() {
        this.setState({ method: 'card' });
    }
    render() {

        return (
            <div>
                <Header />
                <div className={this.fetchloader()}>
                    <img
                        src="../../images/tiger.gif"
                        alt="loading"
                    />
                </div>
                <div className="op errorpop2 invalid_login">
                    <div className="errorbtn2 error h-100"></div>
                    <ul>
                        <li>{this.state.error}</li>
                        <button className="upbtn errorbtn2" onClick={this.removepop.bind(this)}  >{language.Checkoutpage.ok}</button>
                    </ul>
                </div>
                <div className="headingin headinginckut">
                    <h2><span>{language.Checkoutpage.check}</span>{language.Checkoutpage.out}</h2>
                    {/* <h1><span>Check</span>out</h1> */}
                </div>

                <section className="shopping_bag mmin">

                    <div className="container">
                        <button className="cllg cllg1"><i className="fas fa-bars"></i>  {language.Checkoutpage.sidebar}</button>

                        <div className="shopping_bagin">
                            <div className="row">
                                {/* <div className="col-lg-4 checkout1">
            <h3>1.Address</h3>


            <div className="checktestform">
               <form>

                    <div className="form-group">
    <label for="exampleInputEmail1">Company Name</label>
    <input type="text" onChange={this.fetchcompny.bind(this)} value={this.state.comp} className="form-control" placeholder=""/>
    <span className="placecomp displayblockclass">{this.state.comp=="" ? "please enter company name": this.state.comp.length<5 ? "please enter right name of company" : "" }</span>
  </div>

  <div className="form-group">
    <label for="exampleInputEmail1">Full Name</label>
    <input type="text" onChange={this.fetchname.bind(this)} value={this.state.fullname} className="form-control" placeholder=""/>
    <span className="placename displayblockclass">{this.state.fullname=="" ? "please enter full name": this.state.fullname.length<5 ? "please enter right your full name" : "" }</span>
  </div>

 
<div className="form-group">
    <label for="exampleInputEmail1">Email</label>
    <input type="email" value={localStorage.getItem("email")} className="form-control" placeholder="" readonly />
  </div>


 

  <div className="form-group">
    <label for="exampleInputEmail1"> Street Address</label>
  {this.state.gmapsLoaded && (<PlacesAutocomplete
        value={this.state.address}
        onChange={this.fetchstreet.bind(this)}
        onSelect={this.handleSelect.bind(this)}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: '',
                className:'form-control',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                     <ul>
                    <li>{suggestion.description}</li>
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>

          
        )}
      </PlacesAutocomplete>)}
      <span className="placeaddress displayblockclass">{this.state.street=="" ? "please enter your street address": this.state.street.length<5 ? "please enter right your street address" : "" }</span>
  </div>


<div className="form-group">
    <label for="exampleInputEmail1">City </label>
    <input type="text" onChange={this.fetchcity.bind(this)} value={this.state.city} className="form-control" placeholder=""/>
    <span className="placecity displayblockclass">{this.state.city=="" ? "please enter your city name": this.state.city.length<5 ? "please enter right your city name" : "" }</span>
</div>

  <div className="form-group">
    <label for="exampleInputEmail1">Country </label>
    <input type="text" onChange={this.fetchcountry.bind(this)} value={this.state.country} className="form-control" placeholder=""/>
    <span className="placecountry displayblockclass">{this.state.country=="" ? "please enter your country name": this.state.country.length<5 ? "please enter right your country name" : "" }</span>
  </div>

    <div className="form-group">
    <label for="exampleInputEmail1">State </label>
    <input type="text" onChange={this.fetchstate.bind(this)} value={this.state.statename} className="form-control" placeholder=""/>
    <span className="placestate displayblockclass">{this.state.statename=="" ? "please enter your state name": this.state.statename.length<5 ? "please enter right your state name" : "" }</span>
  </div>

     <div className="form-group">
    <label for="exampleInputEmail1">Zip/Postcode  </label>
    <input type="text" onChange={this.fetchzip.bind(this)} value={this.state.zip} className="form-control" placeholder=""/>
    <span className="placezip displayblockclass">{this.state.zip=="" ? "please enter your zip code": this.state.zip.length<5 ? "please enter right your zip code" : "" }</span>
  </div>

  <div className="form-group">
    <label for="exampleInputEmail1">Phone</label>
    <input type="number" onChange={this.fetchphone.bind(this)} value={this.state.phone} className="form-control" placeholder=""/>
    <span className="placephone displayblockclass">{this.state.phone=="" ? "please enter your phone number": this.state.phone.length<9 ? "please enter valid phone number" : "" }</span>
  </div>

 

</form>
            </div>
          </div> */}

                                <div className="col-lg-12 checkout2">


                                    <h3>1. {language.Checkoutpage.delivery}</h3>

                                    <div className="row">

                                        <div className="col-8">
                                            <div className="form-group">



                                                {this.state.gmapsLoaded && (<PlacesAutocomplete

                                                    value={this.state.address}
                                                    onChange={this.fetchstreet.bind(this)}
                                                    onSelect={this.handleSelect.bind(this)}
                                                // searchOptions={{
                                                //   location: new google.maps.LatLng(-34, 151),
                                                //   radius: 2000,
                                                //   types: ['address']
                                                // }}

                                                >
                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div>
                                                            <input
                                                                {...getInputProps({
                                                                    placeholder: language.cartpage.delivery_address,
                                                                    className: 'form-control',
                                                                })}
                                                            />
                                                            <div className="autocomplete-dropdown-container">
                                                                {loading && <div>Loading...</div>}
                                                                {suggestions.map(suggestion => {
                                                                    const className = suggestion.active
                                                                        ? 'suggestion-item--active'
                                                                        : 'suggestion-item';
                                                                    // inline style for demonstration purpose
                                                                    const style = suggestion.active
                                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                                    return (
                                                                        <div
                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                className,
                                                                                style,
                                                                            })}
                                                                        >
                                                                            <ul>
                                                                                <li>{suggestion.description}</li>
                                                                            </ul>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>


                                                    )}
                                                </PlacesAutocomplete>)}
                                                <span className="placeaddress displayblockclass">{this.state.street == "" ? "please enter your street address" : this.state.street.length < 5 ? "please enter right your street address" : ""}</span>

                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <input type="text" onChange={this.fetchcompny.bind(this)} value={this.state.comp} className="form-control" placeholder="Name on the Intercom" />

                                                <span className="placecomp displayblockclass">{this.state.comp == "" ? "Name on the Intercom" : this.state.comp.length < 5 ? "please enter right name of Intercom" : ""}</span>

                                            </div>
                                        </div>

                                        <div className="col-4">
                                            <div className="form-group">
                                                {/* <label for="exampleInputEmail1">Company Name</label> */}

                                                <Select
                                                    onChange={this.forchanedosage.bind(this)}
                                                    options={this.state.dates}
                                                    //  getOptionValue={opt => opt.id}
                                                    placeholder={this.state.datem !== "" ? this.state.datem : "Select Date"}
                                                    value={this.state.datem}
                                                    getOptionLabel={opt => opt}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-4">
                                            {/* <input type="date" id="birthday" onChange={this.fetchdate.bind(this)} name="birthday" className="form-control"/> */}


                                            <Select
                                                onChange={this.forchanetime.bind(this)}
                                                options={this.state.times}
                                                //  getOptionValue={opt => opt.id}
                                                placeholder={this.state.timem !== "" ? this.state.timem : "Select Time"}

                                                getOptionLabel={opt => opt}
                                            />

                                        </div>

                                        <div className="col-4">
                                            <input type="number" onChange={this.fetchphone.bind(this)} value={this.state.phone} className="form-control" placeholder="" />
                                            <span className="placephone displayblockclass">{this.state.phone == "" ? "please enter your phone number" : this.state.phone.length < 9 ? "please enter valid phone number" : ""}</span>
                                        </div>
                                        <div className="col-sm-12">
                                            <textarea placeholder="delivery comment" value={this.state.note} onChange={this.note.bind(this)}></textarea>
                                        </div>
                                    </div>

                                    <h3>2. {language.Checkoutpage.payment_method}</h3>
                                    <ul>
                                        <li><label>{language.Checkoutpage.Credit_Debit_Card}<img src="images/visacolor.png" alt="visacolor" />
                                            <input type="checkbox" onClick={this.checkcart.bind(this)} checked={this.state.method == "card" ? true : ""} />
                                            <span className="checkmark"></span>
                                        </label>
                                        </li>
                                        <li><label>PayPal<img src="images/visacolor.png" alt="visacolor" />
                                            <input type="checkbox" onClick={this.checkpaypal.bind(this)} checked={this.state.method == "paypal" ? true : ""} />
                                            <span className="checkmark"></span>
                                        </label>
                                        </li>


                                        {/* <PayPalScriptProvider
            options={{
                "client-id": "Ab4ZTpAqPBUEO85vxBbiceahg6yWNdvtlei-rfCXORAMF92_VeUozjtfvXMrQHGAEJCXvMORLOcsvQAO",
              //  "data-client-token": "abc123xyz==",
            }}
        >
            <BraintreePayPalButtons
                createOrder={(data, actions) => {
                    return actions.braintree.createPayment({
                        flow: "checkout",
                        amount: "10.0",
                        currency: "USD",
                        intent: "capture"
                    });
                }}
                onApprove={this.fetchd.bind(this)}
          />
        </PayPalScriptProvider> */}

                                        {/* <li>
<label>Paytm<img src="images/paytm.png" alt="visacolor"/>
  <input type=""/>
  <span className="checkmark"></span>
</label>
  </li>
  <li><label>AT Wallet (Available Balance: ₹1,500.00)
  <input type=""/>
  <span className="checkmark"></span>
</label> </li>
  <li className="foemm"><form>
        <input className="w-100" type="text" name="firstname" placeholder="Enter Code"/>
        <button type="submit" value=""><i className="fas fa-arrow-right"></i></button>
       </form>
  </li>
  <li><label>Google Pay / UPI<img src="images/pay.png" alt="pay"/>
  <input type=""/>
  <span className="checkmark"></span>
</label> 
</li>
  <li><label>Cash on Delivery
  <input type="" />
  <span className="checkmark"></span>
</label>
</li> */}
                                    </ul>




                                </div>
                            </div>




                            <div className="shopping_side">

                                <span className="cllg">×</span>
                                {/* <div className="test">
    <h6><i className="fas fa-tag"></i> Gift / Discount Code</h6>
    <form>
                     <input className="w-100" value={this.state.code} onChange={this.getcupen.bind(this)}  type="text" name="firstname" placeholder="Enter Code"/>
                     <button type="button" value=""><i className="fas fa-arrow-right"></i></button>
                  </form>
  </div> */}

                                <div className="test">
                                    <h3>{language.Checkoutpage.order_summary}</h3>

                                    <ul>
                                        {this.state.dishs.length > 0 ? this.state.dishs.map((j, x) => {
                                            return (
                                                <li>
                                                    <img src={j.image} alt="pro" />
                                                    <p>{j.dish_name}</p>
                                                    <p>{language.Checkoutpage.quantity}: {j.quantity}</p>
                                                    <h6>{"€ " + Number(j.price).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</h6>
                                                    {/* <a className="delete" id={j.menu_id} data-div_name={j.key} onClick={this.deletedish.bind(this) }><i className="far fa-trash-alt"></i> Delete</a> */}
                                                </li>)
                                        }) : ""}


                                    </ul>


                                    <h5><b>{language.Checkoutpage.subtotal}</b><span>{"€ " + Number(this.state.grandtotal).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></h5>
                                    <h5><b>{language.Checkoutpage.discount}</b><span>{"€ " + Number(this.state.discount).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></h5>
                                    <h5><b>{language.Checkoutpage.shipping}</b><span>{"€ " + Number(this.state.shipping).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></h5>
                                    <h4><b>{language.Checkoutpage.grand_total}</b> <span>{"€ " + Number(this.state.grandtotal + this.state.shipping - this.state.discount).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></h4>
                                    <div className="text-center mb-4 ">
                                        {this.state.method == "card" ? <a className="btn" onClick={this.placeoder.bind(this)}>{language.Checkoutpage.place_order}</a> :
                                            <div class="changeable">
                                                <span>{language.Checkoutpage.place_order}</span>

                                                {this.state.dishs.length == 0 || this.state.phone == "" || this.state.phone.length < 6 || this.state.statename == "" || this.state.statename.length < 3 || this.state.country == "" || this.state.country.length < 3 || this.state.city == "" || this.state.city.length < 3 || this.state.street == "" || this.state.street.length < 3 || this.state.comp == "" || this.state.comp.length < 3 || this.state.fullname == "" || this.state.fullname.length < 3 ?
                                                    <PayPalButton
                                                        onClick={this.errorpops.bind(this)} />
                                                    :
                                                    <PayPalButton
                                                        amount={Number(this.state.grandtotal + this.state.shipping - this.state.discount)}

                                                        options={{
                                                            clientId: "Ab4ZTpAqPBUEO85vxBbiceahg6yWNdvtlei-rfCXORAMF92_VeUozjtfvXMrQHGAEJCXvMORLOcsvQAO"
                                                            , currency: "EUR"
                                                        }}
                                                        onSuccess={(details, data) => {
                                                            $('.errorpop2').removeClass('displayblock');
                                                            this.paypalorder(details);


                                                            alert("Transaction completed by " + details.payer.name.given_name);


                                                            return fetch("/paypal-transaction-complete", {
                                                                method: "post",
                                                                body: JSON.stringify({
                                                                    orderID: "Ucooking_pay" + Date.now()
                                                                })
                                                            });
                                                        }}
                                                    />}
                                            </div>}
                                        <Link to={'/'} className="contine_btn" >{language.Checkoutpage.contine_shopping}</Link>
                                    </div>

                                </div>



                            </div>


                        </div>
                    </div>
                </section>
            </div>
        );

    }
}
export default Checkout;