import React, { Component } from 'react';
import FireOrderUpdate from '../API/FireOrderUpdate.js';
var XPay = require('xpay-nexi-js');
class xpaysubmit extends  React.Component {
    constructor(props) {
       super(props);
      }
componentDidMount= async ()=>
{
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if(params.amount && params.amount>0 && params.oid && params.oid!=="")
    {
    this.paymentgateway(params.amount,params.oid);
    }
    if(params.codTrans && params.codTrans!=="" && params.messaggio && params.messaggio=="Message OK")
    {
    var object={
    "payment":"success",
    "trans_id":params.codTrans,
    }
    var submit= await FireOrderUpdate(params.codOrd,object);
    }
    if(params.cancel && params.cancel=="ok")
    {
    var object={
    "payment":"cancel",
    }
    var submit= await FireOrderUpdate(params.codOrd,object);
    }
    if(params.codTrans && params.codTrans!=="" && params.messaggio && params.messaggio!=="Message OK")
    {
    var object={
    "payment":"cancel",
    }
    var submit= await FireOrderUpdate(params.codOrd,object);
    }
}
paymentgateway(fnal,oid)
{
    var macCode;
    var xpay = new XPay();
    xpay.configure({
    XPAY_KEY: 'ULKLCGIXQAUIZDCI6AQA02JJ5P1PKPP4',
    XPAY_ALIAS: 'ALIAS_WEB_00043094',
    XPAY_RETURN_URL_OK: 'https://fooddelivery-cae0d.web.app/xpaysubmit?codOrd='+oid,
    XPAY_RETURN_URL_KO: 'https://fooddelivery-cae0d.web.app/xpaysubmit?cancel=ok',
    XPAY_SERVLET_URL: 'https://int-ecommerce.nexi.it/ecomm/ecomm/DispatcherServlet',
    });
    // fooddelivery-cae0d.web.app
    var args = {
    codTrans: "Ucooking_" + Date.now(),
    amount:Number((fnal*100).toFixed(2)), // 10 euro
    divisa:'EUR'
    };
    var xpayLink = xpay.getUrl(args, macCode);
    window.location.href = xpayLink;
    var xpayResponse = {
    codTrans:"Ucooking_" + Date.now(),
    esito:'OK',
    importo:fnal,
    divisa:'EUR',
    // data:'20181015',
    data:Date.now(),
    orario:'235711',
    codAut:'CODAUTH',
    mac:'MAC',
    };
    var paid = xpay.verify(xpayResponse);
}
render()
{
    return( <></>);
}
}
export default xpaysubmit;