import firebase from "firebase/app";
import 'firebase/firestore';
// import firestore from "./firestore";
import moment from "moment";
export default async function addIp(data) {
// var datq = Math.floor(new Date() / 1000);
    var elx = new Date();
    var datq= moment(elx).format('DD/MM/YYYY');
    // var maxo = moment(newMaximum,'DD/MM/YYYY');
  try{
    var response2 =  await firebase.firestore().collection("views").where('address', '==', data).where('date', '==', datq).get();
    const data2 = await response2.docs.map(doc => doc.id);
    if(data2.length==0)
    {
        const userRef2 = await firebase.firestore().collection('views').add({address:data,date:datq,from:'web'});
        const data4 = await userRef2.docs.map(doc => doc.data());
        return data4;
    }

  }
  catch(e){
    return "error"
  }
}