
import firebase from "firebase/app";
import firestore from "./firestore";
export default async function updateIds (ids) {
               try {
                   if(ids.length>0 && localStorage.getItem("userid")!==null)
                   {
                       var documentSnapshot;
                       for (let index = 0; index < ids.length; index++) {
                            documentSnapshot = await firebase.firestore().collection('Cart').doc(ids[index]).update({
                            user_id:localStorage.getItem("userid")
                        });
                       }
               return await documentSnapshot;
                   }
              } catch {
           return 'error';
              }
}
