
import firebase from "firebase/app";
import 'firebase/firestore';
// import firestore from "./firestore";
export default async function FireOrderUpdate (a,object) {
                var documentSnapshot = await firebase.firestore().collection('orders').doc(a).update(object);
               // return await documentSnapshot.id;
               const documentSnapshots = await firebase.firestore().collection('orders').doc(a).get();
               const userData = await documentSnapshots.data();
              var ingredient = await userData;
               if(ingredient.coupon_id && ingredient.coupon_id!=="")
               {
                  return userData.coupon_id[0];
               }
               else{
                  return 0;
               }
            }
              

