import React, { Component } from 'react';
// import { Link  } from "react-router-dom";
import firebase from "firebase/app";
import 'firebase/firestore';
import Header from '../Header';
import language from'../API/language.json';
// import GoogleMapReact from 'google-map-react';
const db = firebase.firestore();
// const AnyReactComponent = ({ text }) => <div>{text}</div>;
class Contact extends  React.Component {
    constructor(props) {
       super(props);
       this.state={phone:"",address:"",email:"",firstname:"",secondname:"",phones:"",emails:"",text:"",lat:"",long:""
      }

      }

      componentDidMount()
      {
         db.collection("contact").doc("bHkLg4huDqqP9ulyyixFContact").get().then((docRef) => { 
            var data = docRef.data();
            this.setState({
                phone:data.phone,
               email:data.email,
               address:data.address,
               lat:data.lat,
               long:data.long,
                  });
             });
      }
      first(e)
      {
         this.setState({firstname:e.target.value});
      }
      second(e)
      {
         this.setState({secondname:e.target.value});
      }
      emailchnage(e)
      {
         this.setState({emails:e.target.value});
      }
      mobile(e)
      {
         this.setState({phones:e.target.value});
      }
      textarea(e)
      {
         this.setState({text:e.target.value});
      }
      sendsms()
      {
         if(this.state.firstname=="" ||this.state.secondname=="" ||this.state.emails=="" || this.state.phones=="" || this.state.text=="")
         {
            alert('please fill all fields');
         }
         else{
            var object={name:this.state.firstname+" "+this.state.secondname,email:this.state.emails,phone:this.state.phones,messages:this.state.text,date:Date.now()};
            db.collection('messages').add(object);
            alert('sms send successfully');
            this.setState({firstname:"",secondname:"",emails:"",phones:"",text:""});
         }
         
      }
      render()
      {
          return(<><Header/>
<div class="headingin">
   <h2><span>{language.footerpages.contact}</span> {language.footerpages.us}</h2>
   <h1><span>{language.footerpages.contact}</span> {language.footerpages.us}</h1>
</div>
<section class="contact_us">
   <div class="container">
      <div class="row">
         <div class="col-md-6 col-lg-6 contact_right">
            <h2><span>{language.footerpages.Get_in_Touch}</span> {language.footerpages.with_us}</h2>
            <p><span><i class="fas fa-map-marker-alt"></i></span>{this.state.address}</p>
            <p><span><i class="fas fa-phone-alt"></i></span> <a href="tel:07088401110">{this.state.phone}</a></p>
            <p><span><i class="far fa-envelope"></i></span> <a href={"mailto:"+this.state.email}>{this.state.email}</a></p>
            <ul>
               <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
               <li><a href="#"><i class="fab fa-twitter"></i></a></li>
               <li><a href="#"><i class="fab fa-instagram"></i></a></li>
               <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
               <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
            </ul>
         </div>
         <div class="col-md-6 col-lg-6 contact_right">
            <div class="form-signin">
               <h2><span>{language.footerpages.contact}</span> {language.footerpages.me}</h2>
               <div class="row">
                  <div class="col-sm-6">
                     <input type="text" onChange={this.first.bind(this)} value={this.state.firstname} class="form-control" placeholder={language.footerpages.first_name}/>
                  </div>
                  <div class="col-sm-6">
                     <input type="text" onChange={this.second.bind(this)} value={this.state.secondname} class="form-control" placeholder={language.footerpages.last_name}/>
                  </div>
                  <div class="col-sm-12">
                     <input type="text"onChange={this.emailchnage.bind(this)} value={this.state.emails} class="form-control" placeholder={language.footerpages.email}/>
                  </div>
                  <div class="col-sm-12">
                     <input type="text" onChange={this.mobile.bind(this)} value={this.state.phones} class="form-control" placeholder={language.footerpages.mobile}/>
                  </div>
                  <div class="col-sm-12">
                     <textarea placeholder={language.footerpages.write_your_message} onChange={this.textarea.bind(this)} value={this.state.text}></textarea>
                  </div>
                  <div class="col-sm-12">
                     <button class="btn wow zoomIn" data-wow-delay=".2" type="button" onClick={this.sendsms.bind(this)} defaultStyle="visibility: visible; animation-name: zoomIn;">{language.footerpages.submit}</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="map">
   {/* <GoogleMapReact
         //  bootstrapURLKeys={{key:`https://maps.googleapis.com/maps/api/js?key=AIzaSyDM4LDXtUX7rM-FHenlmmXZ7jbfbczfqWk&libraries=places&callback=initMap`}}
       
        >
          <AnyReactComponent
            lat={this.state.lat}
            lng={this.state.long}
            text="My Marker"
          />
        </GoogleMapReact> */}
   </div>
</section>
</>

)
}
}
export default Contact;