import firebase from 'firebase/app';
import 'firebase/firestore';
// import firestore from "./firestore";
import axios from 'axios';
export default async function FetchSelectedResturent() {
    const body = {
        lat: localStorage.getItem('lat'),
        long: localStorage.getItem('long'),
    };
    var response3 = await axios.post(
        'https://foodapi.ucooking.it/fetchSeachTop',
        body,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('token')
            },
        }
    );
    const movies = await response3.data;
    var totalR = response3.data.message;
    if (totalR.length > 0 && totalR !== 'error') {
        var dishes = [];
        var dishesids = [];
        for (let index = 0; index < totalR.length; index++) {
            const element = totalR[index];
            var response2 = await firebase
                .firestore()
                .collection('menus')
                .where('resturent_id', '==', totalR[index].resturent_id)
                .where('status', '==', 'active')
                .get();
            var data2 = await response2.docs.map((doc) => doc.data());
            dishes.push(data2);
            var idst = await response2.docs.map((doc) => doc.id);
            dishesids.push(idst);
        }

        var obj = { resturent: totalR, dishs: dishes, idst: dishesids };
        return await obj;
    } else {
        var obj = { resturent: [], dishs: [], idst: [] };
        return await 'error';
    }

    //   var response =  await firebase.firestore().collection("restaurant")
    //     .where('home', '==', "true").get();
    //     const data = await response.docs.map(doc => doc.data());
    //     if(data!='')
    //     {
    //        var ids = data[0].resturent_id;
    //        if(ids)
    //        {
    //         var response2 =  await firebase.firestore().collection("menus")
    //         .where('resturent_id', '==', ids).get();
    //         const data2 = await response2.docs.map(doc => doc.data());
    //             var idst = await response2.docs.map(doc => doc.id);
    //         var obj={'resturent':data,'dishs':data2,'idst':idst};

    //         return await obj;

    //        }
    //        else{
    //            return await 'error';
    //        }
    //     }
    //     else{
    //         return await 'error';
    //     }
}
