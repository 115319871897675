import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/auth';
// import firestore from "./firestore";
export default async function Loginapi(email, password) {
    try {
        const authdb = firebase.auth();
        var login = await authdb.signInWithEmailAndPassword(email, password);
        if (login.user.emailVerified == true) {
            var response = await firebase.firestore().collection("Users").where('uid', '==', login.user.uid).get();
            const data2 = await response.docs.map(doc => doc.data());
            if (data2.length == 0) {
                var response2 = await firebase.firestore().collection("restaurant").where('resturent_id', '==', login.user.uid).get();
                const data = await response2.docs.map(doc => doc.data());
                localStorage.setItem('phone', data[0].phone_no);
                localStorage.setItem('userid', login.user.uid);
                localStorage.setItem('token', 'Basic ' + btoa(email + ":" + password));
                localStorage.setItem('resturent', "right");
                localStorage.setItem('name', data[0].name);
                localStorage.setItem('email', data[0].restaurant_email);
                localStorage.setItem('city', data[0].city ? data[0].city : null);
                localStorage.setItem('web', data[0].web ? data[0].web : null);
                localStorage.setItem('image', data[0].image ? data[0].image : null);
                return 'data';
            } else {
                const data = await response.docs.map(doc => doc.data());
                localStorage.setItem('phone', data[0].phone);
                localStorage.setItem('userid', login.user.uid);
                localStorage.setItem('resturent', "empty");
                localStorage.setItem('firstname', data[0].first_name);
                localStorage.setItem('lastname', data[0].last_name);
                localStorage.setItem('email', data[0].email);
                localStorage.setItem('city', data[0].city ? data[0].city : null);
                localStorage.setItem('web', data[0].web ? data[0].web : null);
                localStorage.setItem('image', data[0].image ? data[0].image : null);
                return 'data'
            }
        }
        await login.user.sendEmailVerification({
            url: "https://fooddelivery-cae0d.web.app"
        });
        return 'sendmail'
    }
    catch (e) {
        return 'invalid';
    }
}

