import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import Loginapi from '../API/Loginapi.js';
import Registerapi from '../API/Registerapi.js';
import Findemail from '../API/Findemail.js';
import axios from 'axios';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import language from '../API/language.json';
import firebase from 'firebase/app';
import Geocode from 'react-geocode';
import FetchCart from '../API/FetchCart.js';
import imagedir from '../API/imagedir.json';
//  import firestore from '../API/firestore.js';
import 'firebase/firestore';

// import { unstable_renderSubtreeIntoContainer } from 'react-dom';
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchPerameter: '',
            remail: '',
            address: '',
            pop: '',
            errorpop: '',
            restulist: [],
            gmapsLoaded: false,
            errort: '',
            email: '',
            cart: 0,
            pass: '',
            Latitude: '',
            Longitude: '',
            firstname: '',
            logemail: '',
            logpass: '',
            lastname: '',
            passtype2: 'password',
            setemail: '',
            setpassword: '',
            number: '',
            passtype: 'password',
            url: '',
        };
    }

    facebooklogin() {
        var provider = new firebase.auth.FacebookAuthProvider();
        firebase
            .auth()
            .signInWithPopup(provider)
            .then((result) => {
                /** @type {firebase.auth.OAuthCredential} */
                var credential = result.credential;
                var user = result.user;
                if (user.email) {
                    const userRef = firebase
                        .firestore()
                        .collection('Users')
                        .doc(user.ba.X)
                        .set({
                            first_name: user.displayName.split(' ')[0],
                            last_name: user.displayName.split(' ')[1],
                            email: user.email,
                            phone: '',
                            created_date: Date.now(),
                            uid: user.ba.X,
                        });
                    localStorage.setItem('phone', null);
                    localStorage.setItem('userid', user.ba.X);
                    localStorage.setItem('resturent', 'empty');
                    localStorage.setItem(
                        'firstname',
                        user.displayName.split(' ')[0]
                    );
                    localStorage.setItem(
                        'lastname',
                        user.displayName.split(' ')[1]
                    );
                    localStorage.setItem('email', user.email);
                    this.fetchImage(user.ba.X);
                }
                var accessToken = credential.accessToken;
            })
            .catch((error) => {
                console.log('error', error);
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
            });
    }
    fetchImage = async (id) => {
        var dataimage = await firebase
            .firestore()
            .collection('Users')
            .doc(id)
            .get();
        const userData = await dataimage.data();
        localStorage.setItem('phone', userData.phone ? userData.phone : null);
        localStorage.setItem('city', userData.city ? userData.city : null);
        localStorage.setItem('web', userData.web ? userData.web : null);
        localStorage.setItem(
            'image',
            userData.image ? userData.image : 'images/empty.webp'
        );
        window.location.reload();
    };
    googlelogin() {
        var provider = new firebase.auth.GoogleAuthProvider();
        firebase
            .auth()
            .signInWithPopup(provider)
            .then((result) => {
                /** @type {firebase.auth.OAuthCredential} */
                var credential = result.credential;
                var token = credential.accessToken;
                var user = result.user;

                if (user.emailVerified == true) {
                    const userRef = firebase
                        .firestore()
                        .collection('Users')
                        .doc(user.ba.X)
                        .set({
                            first_name: user.displayName.split(' ')[0],
                            last_name: user.displayName.split(' ')[1],
                            email: user.email,
                            phone: '',
                            created_date: Date.now(),
                            uid: user.ba.X,
                        });
                    localStorage.setItem('phone', null);
                    localStorage.setItem('userid', user.ba.X);
                    localStorage.setItem('resturent', 'empty');
                    localStorage.setItem(
                        'firstname',
                        user.displayName.split(' ')[0]
                    );
                    localStorage.setItem(
                        'lastname',
                        user.displayName.split(' ')[1]
                    );
                    localStorage.setItem('email', user.email);
                    this.fetchImage(user.ba.X);
                }
                // ...
            })
            .catch((error) => {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
            });
    }

    componentDidMount = async () => {
        this.setState({ pop: '' });
        if (localStorage.getItem('lat') == null) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    localStorage.setItem('lat', position.coords.latitude);
                    localStorage.setItem('long', position.coords.longitude);
                    this.setState(
                        {
                            Latitude: position.coords.latitude,
                            Longitude: position.coords.longitude,
                        },
                        () => {
                            this.timer = setTimeout(
                                this.fetchAddress(
                                    this.state.Latitude,
                                    this.state.Longitude
                                ),
                                1000
                            );
                        }
                    );
                }.bind(this)
            );
        } else {
            this.setState({
                Latitude: localStorage.getItem('lat'),
                Longitude: localStorage.getItem('long'),
                address:
                    localStorage.getItem('addressm') &&
                        localStorage.getItem('addressm') !== null
                        ? localStorage.getItem('addressm')
                        : '',
            });
        }
        try {
            this.timer = setTimeout((window.initMap = this.initMap), 1000);
            // const gmapScriptEl = document.createElement(`script`)
            // gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDM4LDXtUX7rM-FHenlmmXZ7jbfbczfqWk&libraries=places`;
            // // https://maps.googleapis.com/maps/api/js?key=SECRET_EATING&libraries=places&callback=initMap
            // document.querySelector(`body`).insertAdjacentElement(`beforeend`, gmapScriptEl)
            var dish = await FetchCart();
            if (dish.data !== 'error' && dish.data.length > 0) {
                this.setState({ cart: dish.data.length });
            }
        } catch {
            console.log = console.warn = console.error = () => { };
            console.error('Something bad happened.');
        }
    };
    initMap = () => {
        this.setState({
            gmapsLoaded: true,
        });
    };
    fetchAddress(lati, lngi) {
        if (lati !== undefined && lngi !== undefined) {
            Geocode.setApiKey('AIzaSyDM4LDXtUX7rM-FHenlmmXZ7jbfbczfqWk');
            Geocode.setLanguage('en');
            Geocode.setRegion('es');
            Geocode.setLocationType('ROOFTOP');
            Geocode.enableDebug();
            Geocode.fromLatLng(lati, lngi)
                .then(
                    (response) => {
                        const address = response.results[0].formatted_address;
                        let city, state, country;
                        for (
                            let i = 0;
                            i < response.results[0].address_components.length;
                            i++
                        ) {
                            for (
                                let j = 0;
                                j <
                                response.results[0].address_components[i].types
                                    .length;
                                j++
                            ) {
                                switch (
                                response.results[0].address_components[i]
                                    .types[j]
                                ) {
                                    case 'locality':
                                        city =
                                            response.results[0]
                                                .address_components[i]
                                                .long_name;
                                        break;
                                    case 'administrative_area_level_1':
                                        state =
                                            response.results[0]
                                                .address_components[i]
                                                .long_name;
                                        break;
                                    case 'country':
                                        country =
                                            response.results[0]
                                                .address_components[i]
                                                .long_name;
                                        break;
                                }
                            }
                        }
                        this.setState({
                            address: city + ', ' + state + ', ' + country,
                        });
                        localStorage.setItem(
                            'addressm',
                            city + ', ' + state + ', ' + country
                        );
                        window.location.reload();
                    },
                    (error) => {
                        console.error(error);
                    }
                )
                .catch((error) => {
                    console.log = console.warn = console.error = () => { };
                    console.error('Something bad happened.');
                });
        }

        // this.getDocumentNearBy(lati, lngi, 50);
    }
    // getDocumentNearBy=async(latitude, longitude, distance)=> {

    // ~1 mile of lat and lon in degrees
    // let lat = 0.0144927536231884
    // let lon = 0.0181818181818182

    // let lowerLat = latitude - (lat * distance)
    // let lowerLon = longitude - (lon * distance)

    // let greaterLat = latitude + (lat * distance)
    // let greaterLon = longitude + (lon * distance)

    // let lesserGeopoint = new GeoPoint(lowerLat,lowerLon);
    // let greaterGeopoint = new  GeoPoint(greaterLat,greaterLon);
    // console.log('lesserGeopoint',lesserGeopoint._latitude);
    // console.log('greaterGeopoint',greaterGeopoint);

    // let docRef = await  firebase.firestore().collection("restaurant");
    //    let query = await firebase.firestore().collection("restaurant").where('lat', '>=', lesserGeopoint._latitude).where('lng', '=<', greaterGeopoint._longitude)
    // console.log('query',query);
    //    var dataas = await query.docs.map(doc => doc.data());
    // console.log('dataas',dataas);
    // query.getDocuments { snapshot, error in
    //     if let error = error {
    //         print("Error getting documents: \(error)")
    //     } else {
    //         for document in snapshot!.documents {
    //             print("\(document.documentID) => \(document.data())")
    //         }
    //     }
    // }

    //}

    getemail(e) {
        if (e.target.value == '') {
            this.setState({ logemail: 'Please enter email' }, () => {
                $('.lagemail').addClass('commonerror');
            });
        } else {
            this.setState({ logemail: '' }, () => {
                $('.logemail').removeClass('commonerror');
            });
        }
        this.setState({ email: e.target.value });
    }
    getpass(e) {
        if (e.target.value == '') {
            this.setState({ logpass: 'Please enter password' }, () => {
                $('.logpass').addClass('commonerror');
            });
        } else {
            this.setState({ logpass: '' }, () => {
                $('.logpass').removeClass('commonerror');
            });
        }
        this.setState({ pass: e.target.value });
    }
    setfirstname(e) {
        if (e.target.value == '') {
            this.setState({ firsterror: 'Please enter first name' }, () => {
                $('.firsterror').addClass('commonerror');
            });
        } else {
            this.setState({ firsterror: '' }, () => {
                $('.firsterror').removeClass('commonerror');
            });
        }
        this.setState({ firstname: e.target.value });
    }
    logout() {
        window.localStorage.clear();
        window.location.reload();
    }
    setlastname(e) {
        if (e.target.value == '') {
            this.setState({ lasterror: 'Please enter last name' }, () => {
                $('.lasterror').addClass('commonerror');
            });
        } else {
            this.setState({ lasterror: '' }, () => {
                $('.lasterror').removeClass('commonerror');
            });
        }
        this.setState({ lastname: e.target.value });
    }
    setemail = async (e) => {
        this.setState({ setemail: e.target.value });
        var condition = new RegExp(
            /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
        ).test(e.target.value);
        if (condition == false) {
            this.setState(
                { emailerror: 'Please enter valid email address' },
                () => {
                    $('.emailerror').addClass('commonerror');
                }
            );
        } else {
            var notifiedp = await Findemail(e.target.value);
            if (notifiedp == 'false') {
                this.setState({ emailerror: 'Email already exist' }, () => {
                    $('.emailerror').addClass('commonerror');
                });
            } else {
                this.setState({ emailerror: '' }, () => {
                    $('.emailerror').removeClass('commonerror');
                });
            }
        }
    };
    setpassword(e) {
        //setTimeout(() => {
            if (e.target.value.length > 5) {
                this.setState({ passworderror: '' }, () => {
                    $('.passworderror').removeClass('commonerror');
                });
            } else {
                this.setState(
                    { passworderror: 'Please enter minimum 6 digit password' },
                    () => {
                        $('.passworderror').addClass('commonerror');
                    }
                );
            }
        //}, 1500);
        this.setState({ setpassword: e.target.value });
    }
    setnumber(e) {
        //setTimeout(() => {
            if (e.target.value.length > 9) {
                this.setState({ numbererror: '' }, () => {
                    $('.numbererror').removeClass('commonerror');
                });
            } else {
                this.setState(
                    { numbererror: 'Please enter minimum 10 digit number' },
                    () => {
                        $('.numbererror').addClass('commonerror');
                    }
                );
            }
        //}, 1000);
        this.setState({ number: e.target.value });
    }
    getauthdata = async (e) => {
        e.preventDefault();
        if (this.state.email == '') {
            this.setState({ logemail: 'Please enter email address' }, () => {
                $('.logemail').addClass('commonerror');
            });
        }
        if (this.state.pass == '') {
            this.setState({ logpass: 'Please enter password ' }, () => {
                $('.logpass').addClass('commonerror');
            });
        }
        if (this.state.pass.length < 5) {
            this.setState(
                { logpass: 'Please enter password minmum 6 digit' },
                () => {
                    $('.logpass').addClass('commonerror');
                }
            );
        }
        if (
            this.state.logemail == '' &&
            this.state.logpass == '' &&
            this.state.pass.length > 5 &&
            this.state.email !== ''
        ) {
            this.setState({ loader: true });
            try {
                const notified = await Loginapi(
                    this.state.email,
                    this.state.pass
                );
                if (notified == 'data') {
                    window.location.reload();
                } else {
                    if (notified == 'sendmail') {
                        this.setState(
                            {
                                loader: false,
                                pop: 'log',
                                errort: 'Verification email send successfully',
                            },
                            () => {
                                $('.errorpop2').addClass('displayblock');
                            }
                        );
                    } else {
                        this.setState(
                            {
                                loader: false,
                                pop: 'log',
                                errort: 'Invalid login',
                            },
                            () => {
                                $('.errorpop2').addClass('displayblock');
                            }
                        );
                    }
                }
            } catch (e) {
                this.setState({ loader: false, pop: 'log' }, () => {
                    $('.errorpop2').addClass('displayblock');
                });
            }
        }
    };
    fetchclass() {
        if (this.state.loader == true) {
            return 'loaderclass loginloader displayblock';
        } else {
            return 'loaderclass loginloader';
        }
    }
    register = async () => {
        if (this.state.firstname == '') {
            this.setState(
                { firsterror: 'Please enter your first name' },
                () => {
                    $('.firsterror').addClass('commonerror');
                }
            );
        }
        if (this.state.lastname == '') {
            this.setState({ lasterror: 'Please enter your last name' }, () => {
                $('.lasterror').addClass('commonerror');
            });
        }
        if (this.state.setemail == '') {
            this.setState(
                { emailerror: 'Please enter your email address' },
                () => {
                    $('.emailerror').addClass('commonerror');
                }
            );
        }
        if (this.state.setpassword.length < 5) {
            this.setState({ passworderror: 'Please enter password' }, () => {
                $('.passworderror').addClass('commonerror');
            });
        }
        if (this.state.number.length < 9) {
            this.setState({ numbererror: 'Please enter phone number' }, () => {
                $('.numbererror').addClass('commonerror');
            });
        }
        if (
            this.state.number.length > 9 &&
            this.state.setpassword.length > 5 &&
            this.state.lastname !== '' &&
            this.state.firstname !== '' &&
            this.state.setemail !== '' &&
            this.state.numbererror == '' &&
            this.state.passworderror == '' &&
            this.state.emailerror == '' &&
            this.state.lasterror == '' &&
            this.state.firsterror == ''
        ) {
            this.setState({ loader: true });
            try {
                const notified = await Registerapi(
                    this.state.firstname,
                    this.state.lastname,
                    this.state.setemail,
                    this.state.setpassword,
                    this.state.number
                );
                if (
                    localStorage.getItem('mainsend') &&
                    localStorage.getItem('mainsend') !== null &&
                    localStorage.getItem('mainsend') == 'email'
                ) {
                    this.setState({ loader: false }, () => {
                        $('#signupmodal').css('display', 'none');
                        $('.modal-backdrop').css('display', 'none');
                        alert('Verification email send successfully');
                        localStorage.removeItem('mainsend');
                    });
                } else {
                    this.setState({ loader: false }, () => {
                        alert('invalid Register');
                    });
                }
            } catch (e) {
                console.log(e);
            }
        }
    };
    showpassword() {
        if (this.state.passtype == 'password') {
            $('#mongo').addClass('eyscolor');
            this.setState({ passtype: 'text' });
        } else {
            $('#mongo').removeClass('eyscolor');
            this.setState({ passtype: 'password' });
        }
    }
    showpasswordLogin() {
        if (this.state.passtype2 == 'password') {
            $('#mongos').addClass('eyscolor');
            this.setState({ passtype2: 'text' });
        } else {
            $('#mongos').removeClass('eyscolor');
            this.setState({ passtype2: 'password' });
        }
    }
    removepop() {
        $('.errorpop2').removeClass('displayblock');
    }
    handleChange = (address) => {
        this.setState({ address });
    };
    handleSelect = (address) => {
        this.setState({ address });
        geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {
                localStorage.setItem('lat', latLng.lat);
                localStorage.setItem('long', latLng.lng);
                localStorage.setItem('addressm', address);
                localStorage.setItem('mongo', '5');
                this.setState(
                    {
                        Latitude: latLng.lat,
                        Longitude: latLng.lng,
                        permanent_address: latLng,
                    },
                    () => {
                        window.location.reload();
                    }
                );
            })
            .catch((error) => console.error('Error', error));
    };

    //  gorestu(e)
    //  {
    //    this.props.history.push('/Singlepage/'+e.currentTarget.id)
    //  }
    fetchsearching(event) {
        this.setState(
            { searchPerameter: event.target.value, searchloader: true },
            () => {
                this.timeout = setTimeout(
                    function () {
                        this.searchUser();
                    }.bind(this),
                    2000
                );
            }
        );
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }
    async searchUser() {
        if (this.state.searchPerameter != '') {
            const body = {
                lat: this.state.Latitude,
                long: this.state.Longitude,
                search: this.state.searchPerameter,
            };
            var response = await axios.post(
                'https://foodapi.ucooking.it/fetchSeachRestu',
                body,
                {
                    headers: {
                        Accept: 'multipart/form-data',
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            const movies = await response.data;
            if (response.data.message !== 'error') {
                this.setState({ restulist: response.data.message });
            } else {
                this.setState({ restulist: [] });
            }
        }
    }

    setresutname(e) {
        this.setState({ searchPerameter: e.currentTarget.id, restulist: [] });
    }

    resetemail(e) {
        this.setState({ remail: e.currentTarget.value });
    }

    sendmail() {
        if (this.state.remail !== '') {
            var actionCodeSettings = {
                // After password reset, the user will be give the ability to go back
                // to this page.
                url: 'https://fooddelivery-cae0d.web.app/',
                handleCodeInApp: false,
            };
            firebase
                .auth()
                .sendPasswordResetEmail(this.state.remail, actionCodeSettings)
                .then(() => {
                    this.setState(
                        {
                            pop: 'pass',
                            errorpop: 'Email Send Successfully',
                            remail: '',
                        },
                        () => {
                            $('.errorpop2').addClass('displayblock');
                        }
                    );
                })
                .catch((e) => {
                    this.setState(
                        { pop: 'pass', errorpop: 'Please Check Your Email Id' },
                        () => {
                            $('.errorpop2').addClass('displayblock');
                        }
                    ).bind(this);
                });
        } else {
            this.setState(
                { pop: 'pass', errorpop: 'Please Enter Your Email Id' },
                () => {
                    $('.errorpop2').addClass('displayblock');
                }
            );
        }
    }
    // gohome()
    // {
    //    localStorage.setItem('mongo',"5");
    // }

    opendahs() {
        $('#signupmodal').css('display', 'block');
        $('.modal-backdrop').css('display', 'block');
    }
    render() {
        return (
            <div>
                <header className='main_header'>
                    <div className='container'>
                        <nav className='navbar navbar-expand-md navbar-dark p-0'>
                            <Link to={'/'} className='navbar-brand'>
                                <img src='../images/logo.png' />
                            </Link>
                            <div
                                className='collapse navbar-collapse'
                                id='navbarCollapse'
                            >
                                <form>
                                    <div className='conta_form'>
                                        <i className='fas fa-map-marker-alt'></i>
                                        {/* <input type="text" placeholder="Your Location"/> */}
                                        {this.state.gmapsLoaded && (
                                            <PlacesAutocomplete
                                                value={this.state.address}
                                                onChange={this.handleChange.bind(
                                                    this
                                                )}
                                                onSelect={this.handleSelect.bind(
                                                    this
                                                )}
                                            >
                                                {({
                                                    getInputProps,
                                                    suggestions,
                                                    getSuggestionItemProps,
                                                    loading,
                                                }) => (
                                                    <div>
                                                        <input
                                                            {...getInputProps({
                                                                placeholder:
                                                                    language
                                                                        .header
                                                                        .location_search,
                                                                className:
                                                                    'location-search-input',
                                                            })}
                                                        />
                                                        <div className='autocomplete-dropdown-container'>
                                                            {loading && (
                                                                <div>
                                                                    Loading...
                                                                </div>
                                                            )}
                                                            {suggestions.map(
                                                                (
                                                                    suggestion
                                                                ) => {
                                                                    const className =
                                                                        suggestion.active
                                                                            ? 'suggestion-item--active'
                                                                            : 'suggestion-item';
                                                                    // inline style for demonstration purpose
                                                                    const style =
                                                                        suggestion.active
                                                                            ? {
                                                                                backgroundColor:
                                                                                    '#fafafa',
                                                                                cursor: 'pointer',
                                                                            }
                                                                            : {
                                                                                backgroundColor:
                                                                                    '#ffffff',
                                                                                cursor: 'pointer',
                                                                            };
                                                                    return (
                                                                        <div
                                                                            {...getSuggestionItemProps(
                                                                                suggestion,
                                                                                {
                                                                                    className,
                                                                                    style,
                                                                                }
                                                                            )}
                                                                        >
                                                                            <ul>
                                                                                <li>
                                                                                    <Link
                                                                                        to={
                                                                                            '/'
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            suggestion.description
                                                                                        }
                                                                                    </Link>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </PlacesAutocomplete>
                                        )}
                                    </div>
                                    <div className='conta_form conta_form2'>
                                        <i className='fas fa-search'></i>
                                        <input
                                            type='text'
                                            onChange={this.fetchsearching.bind(
                                                this
                                            )}
                                            value={this.state.searchPerameter}
                                            placeholder={
                                                language.header
                                                    .resturent_search_title
                                            }
                                        />
                                        <div className='autocomplete-dropdown-container'>
                                            <ul>
                                                {this.state.restulist.length > 0
                                                    ? this.state.restulist.map(
                                                        (j, x) => {
                                                            return j.dish_name &&
                                                                j.dish_name !=
                                                                '' ? (
                                                                <li>
                                                                    <Link
                                                                        onClick={this.setresutname.bind(
                                                                            this
                                                                        )}
                                                                        id={
                                                                            j.dish_id
                                                                        }
                                                                        to={
                                                                            '/Singledish/' +
                                                                            j.dish_id
                                                                        }
                                                                    >
                                                                        {
                                                                            j.dish_name
                                                                        }
                                                                        <span>
                                                                            Dish
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                            ) : (
                                                                <li>
                                                                    <Link
                                                                        onClick={this.setresutname.bind(
                                                                            this
                                                                        )}
                                                                        id={
                                                                            j.name
                                                                        }
                                                                        to={
                                                                            '/Singlepage/' +
                                                                            j.uid
                                                                        }
                                                                    >
                                                                        {
                                                                            j.name
                                                                        }
                                                                        <span>
                                                                            Restaurants
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                            );
                                                        }
                                                    )
                                                    : ''}
                                            </ul>
                                        </div>
                                    </div>
                                </form>
                                <div className='right_side'>
                                    {localStorage.getItem('userid') &&
                                        localStorage.getItem('userid') !== null ? (
                                        <span>
                                            <div className='dropdown '>
                                                <button
                                                    className='btn dropdown-toggle text-left'
                                                    type='button'
                                                    id='dropdownMenuButton'
                                                    data-toggle='dropdown'
                                                    aria-haspopup='true'
                                                    aria-expanded='false'
                                                >
                                                    <span className='user'>
                                                        <img
                                                            src={
                                                                localStorage.getItem(
                                                                    'image'
                                                                ) &&
                                                                    localStorage.getItem(
                                                                        'image'
                                                                    ) !== null &&
                                                                    localStorage.getItem(
                                                                        'image'
                                                                    ) !== 'null'
                                                                    ? imagedir.url +
                                                                    localStorage.getItem(
                                                                        'image'
                                                                    )
                                                                    : 'images/empty.webp'
                                                            }
                                                            alt='icon'
                                                        />
                                                    </span>
                                                    <h6>
                                                        {localStorage.getItem(
                                                            'resturent'
                                                        ) == 'empty'
                                                            ? localStorage.getItem(
                                                                'firstname'
                                                            ) +
                                                            ' ' +
                                                            localStorage.getItem(
                                                                'lastname'
                                                            )
                                                            : localStorage.getItem(
                                                                'name'
                                                            )}
                                                    </h6>
                                                    <p>
                                                        {localStorage.getItem(
                                                            'email'
                                                        )}
                                                    </p>
                                                </button>
                                                <div
                                                    class='dropdown-menu'
                                                    aria-labelledby='dropdownMenuButton'
                                                >
                                                    <Link
                                                        to={'/Myprofile'}
                                                        class='dropdown-item'
                                                    >
                                                        {
                                                            language.header
                                                                .my_profile
                                                        }
                                                    </Link>
                                                    <Link
                                                        to={'/delivery_address'}
                                                        class='dropdown-item'
                                                    >
                                                        {
                                                            language.header
                                                                .delivery_address
                                                        }
                                                    </Link>
                                                    <Link
                                                        to={'/Myorder'}
                                                        class='dropdown-item'
                                                    >
                                                        {
                                                            language.header
                                                                .order_history
                                                        }
                                                    </Link>
                                                    <a
                                                        class='dropdown-item'
                                                        onClick={this.logout.bind(
                                                            this
                                                        )}
                                                    >
                                                        {language.header.logout}
                                                    </a>
                                                </div>
                                            </div>
                                        </span>
                                    ) : (
                                        <span>
                                            {' '}
                                            <a
                                                className='sign_up'
                                                data-toggle='modal'
                                                data-target='#loginmodal'
                                            >
                                                {language.header.login}
                                            </a>
                                            <a
                                                className='sign_up'
                                                data-toggle='modal'
                                                onClick={this.opendahs.bind(
                                                    this
                                                )}
                                                data-target='#signupmodal'
                                            >
                                                {language.header.signup}
                                            </a>{' '}
                                        </span>
                                    )}
                                    <span className='carticon'>
                                        <Link to={'/Cart'}>
                                            <label>{this.state.cart}</label>
                                            <img src='/images/cart.png' />
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>

                {/* loginmodal  */}
                <div
                    className='modal fade'
                    id='loginmodal'
                    tabindex='-1'
                    role='dialog'
                    aria-labelledby='exampleModalCenterTitle'
                    aria-hidden='true'
                >
                    <div
                        className='modal-dialog modal-dialog-centered modal-lg'
                        role='document'
                    >
                        <div className='modal-content'>
                            <div className='modal-body loginpage'>
                                {this.state.pop == 'log' ? (
                                    <div className='op errorpop2 invalid_login'>
                                        <div className='errorbtn2 error h-100'></div>
                                        <ul>
                                            <li>{this.state.errort}</li>
                                            <button
                                                className='upbtn errorbtn2'
                                                onClick={this.removepop.bind(
                                                    this
                                                )}
                                            >
                                                {language.Checkoutpage.ok}
                                            </button>
                                        </ul>
                                    </div>
                                ) : (
                                    ''
                                )}
                                <div className={this.fetchclass()}>
                                    <img src='images/tiger.gif' alt='loading' />
                                </div>
                                <button
                                    type='button'
                                    className='close'
                                    data-dismiss='modal'
                                    aria-label='Close'
                                >
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                                <div className='headingin'>
                                    <h2>
                                        <span>{language.header.sign}</span>{' '}
                                        {language.header.in}
                                    </h2>
                                    <h1>
                                        <span>{language.header.sign}</span>{' '}
                                        {language.header.in}
                                    </h1>
                                </div>

                                <div className='row'>
                                    <div className='col-lg-6 text-center'>
                                        <div className='mr-4'>
                                            <div
                                                id='myCarousel'
                                                className='carousel slide pointer-event'
                                                data-ride='carousel'
                                            >
                                                <div
                                                    className='carousel-inner wow zoomIn'
                                                    data-wow-delay='.2s'
                                                >
                                                    <div className='carousel-item active'>
                                                        <img
                                                            className='w-75 loginbg px-4'
                                                            src='images/food3.jpg'
                                                            alt='bg'
                                                        />
                                                    </div>
                                                    <div className='carousel-item '>
                                                        <img
                                                            className='w-75 loginbg px-4'
                                                            src='images/food4.jpg'
                                                            alt='bg'
                                                        />
                                                    </div>
                                                </div>
                                                <ol className='carousel-indicators'>
                                                    <li
                                                        data-target='#myCarousel'
                                                        data-slide-to='0'
                                                        className='active'
                                                    ></li>
                                                    <li
                                                        data-target='#myCarousel'
                                                        data-slide-to='1'
                                                        className=''
                                                    ></li>
                                                </ol>
                                            </div>
                                            <h3>{language.header.sign_in}</h3>
                                            <p>
                                                Lorem Ipsum is simply dummy text
                                                of the printing and typesetting
                                                industry. Lorem Ipsum has been
                                                the industry's standard dummy
                                                text ever since the 1500s,{' '}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 text-center'>
                                        <form>
                                            <div className='form-signin'>
                                                <div className='form-group'>
                                                    <label>
                                                        {
                                                            language.header
                                                                .user_id
                                                        }
                                                    </label>
                                                    <input
                                                        type='email'
                                                        name='email'
                                                        value={this.state.email}
                                                        onChange={this.getemail.bind(
                                                            this
                                                        )}
                                                        className='form-control'
                                                        placeholder={
                                                            language.header
                                                                .user_name
                                                        }
                                                        required=''
                                                    />
                                                    <span className='logemail'>
                                                        {this.state.logemail}
                                                    </span>
                                                </div>
                                                <div className='form-group'>
                                                    <label>
                                                        {
                                                            language.header
                                                                .password
                                                        }
                                                    </label>
                                                    <span className='into password d-block'>
                                                        <input
                                                            type={
                                                                this.state
                                                                    .passtype2
                                                            }
                                                            name='password'
                                                            autocomplete='on'
                                                            value={
                                                                this.state.pass
                                                            }
                                                            onChange={this.getpass.bind(
                                                                this
                                                            )}
                                                            className='form-control'
                                                            placeholder={
                                                                language.header
                                                                    .password
                                                            }
                                                            required=''
                                                        />
                                                        <span className='logpass'>
                                                            {this.state.logpass}
                                                        </span>
                                                        <i
                                                            onClick={this.showpasswordLogin.bind(
                                                                this
                                                            )}
                                                            id='mongos'
                                                            className='fas fa-eye'
                                                        ></i>
                                                    </span>
                                                </div>
                                                <button
                                                    className='btn btn-lg btn-warning btn-block'
                                                    onClick={this.getauthdata.bind(
                                                        this
                                                    )}
                                                >
                                                    {language.header.login}
                                                </button>
                                                <div className='row checkbox text-left'>
                                                    <div className='col-6'>
                                                        <label>
                                                            <input
                                                                type='checkbox'
                                                                value='remember-me'
                                                            />{' '}
                                                            {
                                                                language.header
                                                                    .remember_me
                                                            }
                                                        </label>
                                                    </div>
                                                    <div className='col-6 text-right'>
                                                        <a
                                                            data-toggle='modal'
                                                            data-target='#Forgotmodal'
                                                        >
                                                            {language.header
                                                                .forgot_password +
                                                                '?'}
                                                        </a>
                                                    </div>
                                                </div>
                                                <h5 className='or text-center py-3'>
                                                    {language.header.or}
                                                </h5>
                                                <div className='row social text-center mt-3'>
                                                    <div
                                                        className='col-6 wow zoomIn'
                                                        data-wow-delay='.1s'
                                                    >
                                                        <a
                                                            onClick={this.facebooklogin.bind(
                                                                this
                                                            )}
                                                        >
                                                            <i className='fab fa-facebook-f'></i>
                                                            <br />
                                                            {
                                                                language.header
                                                                    .login_with_facebook
                                                            }
                                                        </a>
                                                    </div>
                                                    <div
                                                        className='col-6 wow zoomIn'
                                                        data-wow-delay='.2s'
                                                    >
                                                        <a
                                                            className='google'
                                                            onClick={this.googlelogin.bind(
                                                                this
                                                            )}
                                                        >
                                                            <i className='fab fa-google-plus-g'></i>
                                                            <br />
                                                            {language.header
                                                                .login_with_google +
                                                                '+'}
                                                        </a>
                                                    </div>
                                                </div>
                                                <p className='mt-4 text-center'>
                                                    {
                                                        language.header
                                                            .Donthave_an_account
                                                    }{' '}
                                                    <a
                                                        data-toggle='modal'
                                                        data-target='#signupmodal'
                                                    >
                                                        {language.header.signup}
                                                    </a>
                                                </p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* signupmodal */}
                <div
                    className='modal fade'
                    id='signupmodal'
                    tabindex='-1'
                    role='dialog'
                    aria-labelledby='exampleModalCenterTitle'
                    aria-hidden='true'
                >
                    <div
                        className='modal-dialog modal-dialog-centered modal-lg'
                        role='document'
                    >
                        <div className='modal-content'>
                            <div className='modal-body loginpage'>
                                <div className={this.fetchclass()}>
                                    <img src='images/tiger.gif' alt='loading' />
                                </div>
                                <button
                                    type='button'
                                    className='close'
                                    data-dismiss='modal'
                                    aria-label='Close'
                                >
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                                <div className='headingin'>
                                    <h2>
                                        <span>{language.header.sign}</span>{' '}
                                        {language.header.up}
                                    </h2>
                                    <h1>
                                        <span>{language.header.sign}</span>{' '}
                                        {language.header.up}
                                    </h1>
                                </div>

                                <div className='row'>
                                    <div className='col-lg-6 text-center'>
                                        <div className='mr-4'>
                                            <div
                                                id='myCarousel'
                                                className='carousel slide pointer-event'
                                                data-ride='carousel'
                                            >
                                                <div
                                                    className='carousel-inner wow zoomIn'
                                                    data-wow-delay='.2s'
                                                >
                                                    <div className='carousel-item active'>
                                                        <img
                                                            className='w-75 loginbg px-4'
                                                            src='images/food1.jpg'
                                                            alt='bg'
                                                        />
                                                    </div>
                                                    <div className='carousel-item '>
                                                        <img
                                                            className='w-75 loginbg px-4'
                                                            src='images/food2.jpg'
                                                            alt='bg'
                                                        />
                                                    </div>
                                                </div>
                                                <ol className='carousel-indicators'>
                                                    <li
                                                        data-target='#myCarousel'
                                                        data-slide-to='0'
                                                        className='active'
                                                    ></li>
                                                    <li
                                                        data-target='#myCarousel'
                                                        data-slide-to='1'
                                                        className=''
                                                    ></li>
                                                </ol>
                                            </div>
                                            <h3>{language.header.signup}</h3>
                                            <p>
                                                Lorem Ipsum is simply dummy text
                                                of the printing and typesetting
                                                industry. Lorem Ipsum has been
                                                the industry's standard dummy
                                                text ever since the 1500s,{' '}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 text-center'>
                                        <form>
                                            <div className='form-signin'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className='form-group'>
                                                            <label>
                                                                {
                                                                    language
                                                                        .header
                                                                        .first_name
                                                                }
                                                            </label>
                                                            <input
                                                                type='text'
                                                                className='form-control'
                                                                value={
                                                                    this.state
                                                                        .firstname
                                                                }
                                                                onChange={this.setfirstname.bind(
                                                                    this
                                                                )}
                                                                placeholder={
                                                                    language
                                                                        .header
                                                                        .first_name
                                                                }
                                                                required=''
                                                            />
                                                            <span className='firsterror'>
                                                                {
                                                                    this.state
                                                                        .firsterror
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='form-group'>
                                                            <label>
                                                                {
                                                                    language
                                                                        .header
                                                                        .last_name
                                                                }
                                                            </label>
                                                            <input
                                                                type='text'
                                                                className='form-control'
                                                                value={
                                                                    this.state
                                                                        .lastname
                                                                }
                                                                onChange={this.setlastname.bind(
                                                                    this
                                                                )}
                                                                placeholder={
                                                                    language
                                                                        .header
                                                                        .last_name
                                                                }
                                                                required=''
                                                            />
                                                            <span className='lasterror'>
                                                                {
                                                                    this.state
                                                                        .lasterror
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='form-group'>
                                                            <label>
                                                                {
                                                                    language
                                                                        .header
                                                                        .email_id
                                                                }
                                                            </label>
                                                            <input
                                                                type='email'
                                                                name='email'
                                                                className='form-control'
                                                                value={
                                                                    this.state
                                                                        .setemail
                                                                }
                                                                onChange={this.setemail.bind(
                                                                    this
                                                                )}
                                                                placeholder={
                                                                    language
                                                                        .header
                                                                        .email_id
                                                                }
                                                                required=''
                                                            />
                                                            <span className='emailerror'>
                                                                {
                                                                    this.state
                                                                        .emailerror
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='form-group'>
                                                            <label>
                                                                {
                                                                    language
                                                                        .header
                                                                        .phone_number
                                                                }
                                                            </label>
                                                            <input
                                                                type='email'
                                                                name='email'
                                                                className='form-control'
                                                                value={
                                                                    this.state
                                                                        .number
                                                                }
                                                                onChange={this.setnumber.bind(
                                                                    this
                                                                )}
                                                                placeholder={
                                                                    language
                                                                        .header
                                                                        .phone_number
                                                                }
                                                                required=''
                                                            />
                                                            <span className='numbererror'>
                                                                {
                                                                    this.state
                                                                        .numbererror
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='form-group'>
                                                            <label>
                                                                {
                                                                    language
                                                                        .header
                                                                        .password
                                                                }
                                                            </label>
                                                            <span className='into password d-block'>
                                                                <input
                                                                    type={
                                                                        this
                                                                            .state
                                                                            .passtype
                                                                    }
                                                                    name='password'
                                                                    autocomplete='on'
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .setpassword
                                                                    }
                                                                    onChange={this.setpassword.bind(
                                                                        this
                                                                    )}
                                                                    className='form-control'
                                                                    placeholder={
                                                                        language
                                                                            .header
                                                                            .password
                                                                    }
                                                                    required=''
                                                                />
                                                                <span class='passworderror'>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .passworderror
                                                                    }
                                                                </span>
                                                                <i
                                                                    onClick={this.showpassword.bind(
                                                                        this
                                                                    )}
                                                                    id='mongo'
                                                                    className='fas fa-eye'
                                                                ></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button
                                                    className='btn btn-lg btn-warning btn-block'
                                                    onClick={this.register.bind(
                                                        this
                                                    )}
                                                    type='button'
                                                >
                                                    {language.header.submit}
                                                </button>
                                                <p className='mt-4 text-center'>
                                                    {
                                                        language.header
                                                            .Donthave_an_account
                                                    }{' '}
                                                    <a
                                                        data-toggle='modal'
                                                        data-target='#loginmodal'
                                                    >
                                                        {
                                                            language.header
                                                                .sign_in
                                                        }
                                                    </a>
                                                </p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* signupmodal  */}
                <div
                    className='modal fade'
                    id='Forgotmodal'
                    tabindex='-1'
                    role='dialog'
                    aria-labelledby='exampleModalCenterTitle'
                    aria-hidden='true'
                >
                    <div
                        className='modal-dialog modal-dialog-centered modal-lg'
                        role='document'
                    >
                        <div className='modal-content'>
                            <div className='modal-body loginpage forgot'>
                                <button
                                    type='button'
                                    className='close'
                                    data-dismiss='modal'
                                    aria-label='Close'
                                >
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                                <div className='headingin'>
                                    <h2>
                                        <span>{language.header.for}</span>
                                        {language.header.got}
                                    </h2>
                                    <h1>
                                        <span>{language.header.for}</span>
                                        {language.header.got}
                                    </h1>
                                </div>

                                <div className='row'>
                                    <div className='col-lg-6 text-center'>
                                        <div className='mr-4'>
                                            <div
                                                id='myCarousel'
                                                className='carousel slide pointer-event'
                                                data-ride='carousel'
                                            >
                                                <div
                                                    className='carousel-inner wow zoomIn'
                                                    data-wow-delay='.2s'
                                                >
                                                    <div className='carousel-item active'>
                                                        <img
                                                            className='w-75 loginbg px-4'
                                                            src='images/food3.jpg'
                                                            alt='bg'
                                                        />
                                                    </div>
                                                    <div className='carousel-item'>
                                                        <img
                                                            className='w-75 loginbg px-4'
                                                            src='images/food4.jpg'
                                                            alt='bg'
                                                        />
                                                    </div>
                                                </div>
                                                <ol className='carousel-indicators'>
                                                    <li
                                                        data-target='#myCarousel'
                                                        data-slide-to='0'
                                                        className='active'
                                                    ></li>
                                                    <li
                                                        data-target='#myCarousel'
                                                        data-slide-to='1'
                                                        className=''
                                                    ></li>
                                                </ol>
                                            </div>
                                            <h3>{language.header.forgot}</h3>
                                            <p>
                                                Lorem Ipsum is simply dummy text
                                                of the printing and typesetting
                                                industry. Lorem Ipsum has been
                                                the industry's standard dummy
                                                text ever since the 1500s,{' '}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 text-center'>
                                        <div className='form-signin forgotpass'>
                                            {this.state.pop == 'pass' ? (
                                                <div className='op errorpop2 invalid_login'>
                                                    <div className='errorbtn2 error h-100'></div>
                                                    <ul>
                                                        <li>
                                                            {
                                                                this.state
                                                                    .errorpop
                                                            }
                                                        </li>
                                                        <button
                                                            className='upbtn errorbtn2'
                                                            onClick={this.removepop.bind(
                                                                this
                                                            )}
                                                        >
                                                            {
                                                                language
                                                                    .Checkoutpage
                                                                    .ok
                                                            }
                                                        </button>
                                                    </ul>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            <div className='form-group'>
                                                <label>
                                                    {
                                                        language.header
                                                            .enter_your_email
                                                    }
                                                </label>
                                                <span className='into password d-block'>
                                                    <input
                                                        type='text'
                                                        name='chemial'
                                                        valua={
                                                            this.state.remail
                                                        }
                                                        onChange={this.resetemail.bind(
                                                            this
                                                        )}
                                                        className='form-control'
                                                        placeholder={
                                                            language.header
                                                                .email_id
                                                        }
                                                        required=''
                                                    />
                                                </span>
                                            </div>
                                            <button
                                                className='btn btn-lg btn-warning btn-block'
                                                onClick={this.sendmail.bind(
                                                    this
                                                )}
                                                type='button'
                                            >
                                                {language.header.submit}
                                            </button>
                                            <p className='mt-4 text-center'>
                                                {
                                                    language.header
                                                        .Donthave_an_account
                                                }{' '}
                                                <a
                                                    data-toggle='modal'
                                                    data-target='#loginmodal'
                                                >
                                                    {language.header.sign_in}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
