import firebase from "firebase/app";
import 'firebase/firestore';
// import firestore from "./firestore";
export default async function Fetchdish (id) {
               try {
                const documentSnapshot = await firebase.firestore().collection('menus')
                  .doc(id)
                  .get();
                const userData = await documentSnapshot.data();
               return await userData;
              } catch {
           return 'error';
              }
}