import firebase from "firebase/app";
import 'firebase/firestore';
// import firestore from "./firestore";
export default async function FetchMenus (id) {
    try{
          var response =  await firebase.firestore().collection("menus")
            .where('resturent_id', '==', id).where('status', '==', "active").get();
            const data = await response.docs.map(doc => doc.data());
            const idsdata = await response.docs.map(doc => doc.id);
            var object ={"data":data,"ids":idsdata};
               if(data.length>0)
               {
                   return await object;
               }
               else{
                   return await "empty";
               }
                        
            }
            catch
            {

            }
}