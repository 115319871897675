import firebase from "firebase/app";
import 'firebase/firestore';
// import firestore from "./firestore";
export default async function matchDish(id) {
  try{
    var response2 =  await firebase.firestore().collection("orders").where('user_id', '==', localStorage.getItem('userid')).get();
    const data2 = await response2.docs.map(doc => doc.data());
    if(data2.length>0)
    {
        var index2;
        for (let index = 0; index < data2.length; index++) {

            index2 = await data2[index].items.findIndex(x => x.menu_id === id);
             if(index2!==1)
             {
                return await true
             }
            
        }
        if(index2!==1)
             {
                return await true;
             }
             else{
                return await false;
             }

//         const userRef = await firebase.firestore().collection('Cart').doc(data2[0]).set({
//             dish_name:object.dish_name,image:object.image,actual_price:object.actual_price,
//             price:object.price,menu_id:object.menu_id,quantity:object.quantity,ingrident:object.ingrident,allergeni:object.allergeni,category:object.category,
//          peso:object.peso,resturent_name:object.resturent_name,resturent_id:object.resturent_id,user_id:localStorage.getItem("sessionId"),confirm_by_restu:"pending"
//           });
//           const data3 = await userRef.docs.map(doc => doc.data());
//           return data3;
//     }
//     else{
//     const userRef2 = await firebase.firestore().collection('Cart').add({dish_name:object.dish_name,image:object.image,actual_price:object.actual_price,
//       price:object.price,menu_id:object.menu_id,quantity:object.quantity,ingrident:object.ingrident,allergeni:object.allergeni,category:object.category,
//    peso:object.peso,resturent_name:object.resturent_name,resturent_id:object.resturent_id,user_id:localStorage.getItem("sessionId"),confirm_by_restu:"pending"
//     });
//     const data4 = await userRef2.docs.map(doc => doc.data());
//     return data4;
}
else{
    return await false;
}
  }
  catch(e){
    return await false;
  }
}