import firebase from "firebase/app";
import 'firebase/firestore';
// import firestore from "./firestore";
export default async function Findemail (email) {
          var response =  await firebase.firestore().collection("Users")
            .where('email', '==', email).get();
            const data = await response.docs.map(doc => doc.data());
               if(data!='')
               {
                   return await "false";
               }
               else{
                var response2 =  await firebase.firestore().collection("restaurant").where('restaurant_email', '==', email).get();
                const data2 = await response2.docs.map(doc => doc.data());
                if(data2!='')
                {
                    return await "false";
                }
                else{
                  return  await "true";
                }
               }              
}