import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import FetchCart from '../API/FetchCart.js';
import AddtoCart from '../API/AddtoCart.js';
import DeleteItem from '../API/DeleteItem.js';
import updateIds from '../API/updateIds.js';
import Header from '../Header';
import axios from 'axios';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import Geocode from 'react-geocode';
import firebase from 'firebase/app';
import 'firebase/firestore';
import language from '../API/language.json';
import imagedir from '../API/imagedir.json';

const google = window.google;

class Cart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dishs: [],
            ids: [],
            grandtotal: 0,
            error: '',
            activePage: 1,
            street:
                localStorage.getItem('Caddress') !== null
                    ? localStorage.getItem('Caddress')
                    : '',
            city:
                localStorage.getItem('Ccity') !== null
                    ? localStorage.getItem('Ccity')
                    : '',
            statename:
                localStorage.getItem('Cstatename') !== null
                    ? localStorage.getItem('Cstatename')
                    : '',
            country:
                localStorage.getItem('Ccountry') !== null
                    ? localStorage.getItem('Ccountry')
                    : '',
            Latitude:
                localStorage.getItem('Clatitude') !== null
                    ? localStorage.getItem('Clatitude')
                    : '',
            Longitude:
                localStorage.getItem('Clonitude') !== null
                    ? localStorage.getItem('Clonitude')
                    : '',
            rlat: 0,
            rlong: 0,
            shipping: 0,
            phone: localStorage.getItem('phone'),
            zip: '121212',
            subtotal: 0,
            comp: '',
            address:
                localStorage.getItem('Caddress') !== null
                    ? localStorage.getItem('Caddress')
                    : '',
            shipping: 0,
            gmapsLoaded: false,
            code: '',
            discount: 0,
            discountid: '',
        };
    }
    componentDidMount = async () => {
        var dish = await FetchCart();
        if (dish.data.length > 0) {
            var title = [];
            for (var i = 0; i < dish.data.length; i++) {
                title[i] = {
                    image: imagedir.url + dish.data[i].image,
                    dish_name: dish.data[i].dish_name,
                    price: dish.data[i].price,
                    menu_id: dish.data[i].menu_id,
                    quantity: dish.data[i].quantity,
                    ingrident: dish.data[i].ingrident,
                    allergeni: dish.data[i].allergeni,
                    category: dish.data[i].category,
                    peso: dish.data[i].peso,
                    resturent_name: dish.data[i].resturent_name,
                    resturent_id: dish.data[i].resturent_id,
                    user_id: dish.data[i].user_id,
                    actual_price: dish.data[i].actual_price,
                    key: dish.ids[i],
                };
            }
            setTimeout(
                () =>
                    this.setState({ dishs: title, ids: dish.ids }, () => {
                        this.getcomm();
                    }),
                1500
            );
        } else {
            this.props.history.push('/');
        }
        setTimeout(() => {
            this.initMap();
        }, 2000);
    };

    getcomm = async () => {
        const documentSnapshot = await firebase
            .firestore()
            .collection('restaurant')
            .doc(this.state.dishs[0].resturent_id)
            .get();
        const userData = await documentSnapshot.data();
        var ingredient = await userData;
        if (ingredient) {
            this.setState(
                {
                    rcity: ingredient.city,
                    comm: ingredient.commission,
                    rlat: ingredient.lat,
                    rlong: ingredient.lng,
                    rname: ingredient.name,
                    remail: ingredient.restaurant_email,
                },
                () => {
                    this.grandtotal();
                }
            );
        }
    };
    initMap = () => {
        this.setState({
            gmapsLoaded: true,
        });
    };
    fetchstreet = (address) => {
        this.setState({ street: '' });
        if (this.state.street == '') {
            $('.placestreet').removeClass('displayblockclass');
            $('.placestreet').addClass('displaynoneclass');
        }
        this.setState({ address });
    };
    mekeshiping = async () => {
        if (
            this.state.rlat !== 0 &&
            this.state.rlong !== 0 &&
            this.state.Latitude !== 0 &&
            this.state.Longitude !== 0 &&
            this.state.grandtotal !== 0
        ) {
            const body = {
                restLat: this.state.rlat,
                restLon: this.state.rlong,
                custLat: this.state.Latitude,
                custLon: this.state.Longitude,
                totalOrder: this.state.grandtotal,
            };
            var response3 = await axios.post(
                'https://foodapi.ucooking.it/fetchCost',
                body,
                {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                }
            );
            const movies = await response3.data;
            if (movies.message !== null && movies.message !== 'error') {
                this.setState({ shipping: movies.cost });
            }
        }
    };
    fetchAddress(lati, lngi) {
        $('.errorpop2').removeClass('displayblock');
        Geocode.setApiKey('AIzaSyDM4LDXtUX7rM-FHenlmmXZ7jbfbczfqWk');
        Geocode.setLanguage('en');
        Geocode.setRegion('es');
        Geocode.setLocationType('ROOFTOP');
        Geocode.enableDebug();
        Geocode.fromLatLng(lati, lngi).then(
            (response) => {
                var condigtion =
                    response.results[0].address_components.findIndex(
                        (obj) => obj.types == 'street_number'
                    );
                if (condigtion !== -1) {
                    this.fetchstreet(response.results[0].formatted_address);

                    //  const address = response.results[0].formatted_address;
                    let city, state, country;
                    for (
                        let i = 0;
                        i < response.results[0].address_components.length;
                        i++
                    ) {
                        for (
                            let j = 0;
                            j <
                            response.results[0].address_components[i].types
                                .length;
                            j++
                        ) {
                            switch (
                            response.results[0].address_components[i].types[
                            j
                            ]
                            ) {
                                case 'locality':
                                    city =
                                        response.results[0].address_components[
                                            i
                                        ].long_name;
                                    break;
                                case 'administrative_area_level_1':
                                    state =
                                        response.results[0].address_components[
                                            i
                                        ].long_name;
                                    break;
                                case 'country':
                                    country =
                                        response.results[0].address_components[
                                            i
                                        ].long_name;
                                    break;
                            }
                        }
                    }
                    this.setState(
                        {
                            statename: state,
                            country: country,
                            city: city,
                            street: response.results[0].formatted_address,
                        },
                        () => {
                            localStorage.setItem(
                                'Caddress',
                                response.results[0].formatted_address
                            );
                            localStorage.setItem('Clatitude', lati);
                            localStorage.setItem('Clongitude', lngi);
                            localStorage.setItem('Cstatename', state);
                            localStorage.setItem('Ccountry', country);
                            localStorage.setItem('Ccity', city);
                            this.mekeshiping();
                        }
                    );
                } else {
                    this.setState({ error: 'Please enter valid address' });
                    return;
                }
            },
            (error) => {
                console.error(error);
                this.setState({ error: 'Please enter valid address' });
                return;
            }
        );
    }
    handleSelect = (address) => {
        this.setState({ address });
        geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {
                this.setState({
                    Latitude: latLng.lat,
                    Longitude: latLng.lng,
                    error: '',
                    street: address,
                });
                this.fetchAddress(latLng.lat, latLng.lng);
            })
            .catch((error) => console.error('Error', error));
    };
    grandtotal() {
        var pretotal = 0;
        for (let index = 0; index < this.state.dishs.length; index++) {
            pretotal += Number(this.state.dishs[index].price);
        }
        this.setState({ grandtotal: pretotal, subtotal: pretotal }, () => {
            this.mekeshiping();
            this.getcode();
        });
    }
    deletedish = async (e) => {
        var id = e.currentTarget.id;
        var key = e.currentTarget.dataset.div_name;
        var objIndex = this.state.dishs.findIndex((obj) => obj.key == key);
        if (objIndex !== -1) {
            this.state.dishs.splice(objIndex, 1);
            this.setState({ dishs: this.state.dishs }, () => {
                this.grandtotal();
            });
        }
        var deleted = await DeleteItem(key);
        this.componentDidMount();
    };
    subtract = async (e) => {
        var price = e.currentTarget.dataset.div_name;
        var quantity = e.currentTarget.dataset.div_roll;
        var id = e.currentTarget.id;
        if (quantity > 1) {
            var num = Number(quantity) - 1;
            var aprice = Number(price) * num;
            var objIndex = this.state.dishs.findIndex(
                (obj) => obj.menu_id == id
            );
            if (objIndex !== -1) {
                this.state.dishs[objIndex].quantity = num;
                this.state.dishs[objIndex].price = aprice;
                this.setState({ dishs: this.state.dishs });
                var object = {
                    image: this.state.dishs[objIndex].image,
                    dish_name: this.state.dishs[objIndex].dish_name,
                    price: (this.state.dishs[objIndex].price = aprice),
                    menu_id: this.state.dishs[objIndex].menu_id,
                    quantity: (this.state.dishs[objIndex].quantity = num),
                    ingrident: this.state.dishs[objIndex].ingrident,
                    allergeni: this.state.dishs[objIndex].allergeni,
                    category: this.state.dishs[objIndex].category,
                    peso: this.state.dishs[objIndex].peso,
                    resturent_name: this.state.dishs[objIndex].resturent_name,
                    resturent_id: this.state.dishs[objIndex].resturent_id,
                    user_id: this.state.dishs[objIndex].user_id,
                    actual_price: this.state.dishs[objIndex].actual_price,
                    confirm_by_restu: 'pending',
                };
                var fire = await AddtoCart(object);
            }
        }
        this.grandtotal();
    };
    addition = async (e) => {
        var price = e.currentTarget.dataset.div_name;
        var quantity = e.currentTarget.dataset.div_roll;
        var id = e.currentTarget.id;
        var num = Number(quantity) + 1;
        var aprice = Number(price) * num;
        var objIndex = this.state.dishs.findIndex((obj) => obj.menu_id == id);
        if (objIndex !== -1) {
            this.state.dishs[objIndex].quantity = num;
            this.state.dishs[objIndex].price = aprice;
            this.setState({ dishs: this.state.dishs });
            var object = {
                image: this.state.dishs[objIndex].image,
                dish_name: this.state.dishs[objIndex].dish_name,
                price: (this.state.dishs[objIndex].price = aprice),
                menu_id: this.state.dishs[objIndex].menu_id,
                quantity: (this.state.dishs[objIndex].quantity = num),
                ingrident: this.state.dishs[objIndex].ingrident,
                allergeni: this.state.dishs[objIndex].allergeni,
                category: this.state.dishs[objIndex].category,
                peso: this.state.dishs[objIndex].peso,
                resturent_name: this.state.dishs[objIndex].resturent_name,
                resturent_id: this.state.dishs[objIndex].resturent_id,
                user_id: this.state.dishs[objIndex].user_id,
                actual_price: this.state.dishs[objIndex].actual_price,
                confirm_by_restu: 'pending',
            };
            var fire = await AddtoCart(object);
        }
        this.grandtotal();
    };
    fetchloader() {
        if (this.state.dishs.length > 0) {
            return 'loaderclass singleloder';
        } else {
            return 'loaderclass singleloder displayblock';
        }
    }
    checkout = async () => {
        if (this.state.error == '') {
            if (
                this.state.dishs.length > 0 &&
                localStorage.getItem('userid') &&
                localStorage.getItem('userid') !== null &&
                this.state.street !== '' &&
                this.state.zip !== ''
            ) {
                var data = await updateIds(this.state.ids);
                localStorage.setItem(
                    'sessionId',
                    localStorage.getItem('userid')
                );
                this.props.history.push({
                    pathname: `/Checkout`,
                    state: {
                        zip: this.state.zip,
                        phone: this.state.phone,
                        address: this.state.address,
                        city: this.state.city,
                        statename: this.state.statename,
                        country: this.state.country,
                        Latitude: this.state.Latitude,
                        Longitude: this.state.Longitude,
                        shipping: this.state.shipping,
                        discount: this.state.discount,
                        shipping: this.state.shipping,
                        grandtotal: this.state.grandtotal,
                    },
                });
                return;
            }
            if (localStorage.getItem('userid') == null) {
                this.setState({ error: 'Please login your email' }, () => {
                    $('.errorpop2').addClass('displayblock');
                });
                return;
            }
            if (this.state.dishs == [] || this.state.dishs.length == 0) {
                this.setState({ error: 'Please select dish' }, () => {
                    $('.errorpop2').addClass('displayblock');
                });
                return;
            }
            if (
                this.state.phone == '' ||
                this.state.street == '' ||
                this.state.zip == ''
            ) {
                this.setState({ error: 'Please fill all fields' }, () => {
                    $('.errorpop2').addClass('displayblock');
                });
                return;
            }
        } else {
            $('.errorpop2').addClass('displayblock');
        }
        //  if(this.state.dishs.length>0)
        //  {
        //  if(localStorage.getItem("userid") && localStorage.getItem("userid")!==null)
        //  {
        //    var data = await updateIds(this.state.ids);
        //    localStorage.setItem("sessionId",localStorage.getItem("userid"));
        //    this.props.history.push('/Checkout');
        //  }
        //  else{
        //     this.setState({error:"Please login your email"},()=>
        //     {
        //    $('.errorpop2').addClass('displayblock');
        //    });
        //  }
        // }
        // else{
        //    this.setState({error:"Please select dish"},()=>
        //     {
        //    $('.errorpop2').addClass('displayblock');
        //    });
        // }
    };
    removepop() {
        $('.errorpop2').removeClass('displayblock');
    }
    // fetchzip(e)
    // {
    //   if(e.target.value=="" || e.target.value.length<3 )
    //   {
    //     $(".placezip").removeClass("displayblockclass");
    //     $(".placezip").addClass("displaynoneclass");
    //   }
    //   this.setState({zip:e.target.value});
    // }
    // fetchphone(e)
    // {
    //   if(e.target.value=="" || e.target.value.length<6 )
    //   {
    //     $(".placephone").removeClass("displayblockclass");
    //     $(".placephone").addClass("displaynoneclass");
    //   }
    //   this.setState({phone:e.target.value});
    // }
    // fetchcompny(e)
    // {
    //   if(e.target.value=="" || e.target.value.length<3 )
    //   {
    //     $(".placecomp").removeClass("displayblockclass");
    //     $(".placecomp").addClass("displaynoneclass");
    //   }
    //   this.setState({comp:e.target.value});
    // }
    goback() {
        this.props.history.goBack();
    }

    getcupen(e) {
        var value = e.target.value;
        this.setState({ code: value }, () => {
            this.getcode();
        });
    }
    getcode = async () => {
        const documentSnapshot = await firebase
            .firestore()
            .collection('coupons')
            .where('code', '==', this.state.code)
            .get();
        const userData = await documentSnapshot.docs.map((doc) => doc.data());
        const userDataC = await documentSnapshot.docs.map((doc) => doc.id);
        if (userData.length > 0) {
            for (let index = 0; index < userData.length; index++) {
                if (
                    Number(userData[index].mini) <=
                    Number(this.state.subtotal) &&
                    Number(userData[index].max) >=
                    Number(this.state.subtotal) &&
                    new Date(userData[index].datefrom).getTime() <=
                    new Date().getTime() &&
                    new Date(userData[index].dateto).getTime() >=
                    new Date().getTime() &&
                    Number(userData[index].count) <
                    Number(userData[index].count_limit)
                ) {
                    for (
                        let indexs = 0;
                        indexs < userData[index].restu.length;
                        indexs++
                    ) {
                        if (
                            this.state.dishs[0].resturent_id ==
                            userData[index].restu[indexs].id
                        ) {
                            var amount = 0;
                            if (userData[index].type == 'Percentage') {
                                amount =
                                    (Number(this.state.subtotal) *
                                        Number(userData[index].amount)) /
                                    100;
                            } else {
                                amount = Number(userData[index].amount);
                            }
                            this.setState({
                                discount: amount,
                                discountid: userDataC,
                            });
                        } else {
                            this.setState({ discount: 0 });
                        }
                    }
                } else {
                    this.setState({ discount: 0 });
                }
            }
        } else {
            this.setState({ discount: 0 });
        }
    };

    render() {
        return (
            <div>
                <Header />
                <div className={this.fetchloader()}>
                    <img src='../../images/tiger.gif' alt='loading' />
                </div>

                <section className='cartpage'>
                    <div className='container'>
                        <div className='incart'>
                            <div className='row'>
                                <div className='col-lg-8'>
                                    <div className='my_cart'>
                                        <h2>
                                            {' '}
                                            <button
                                                className='backbtn'
                                                onClick={this.goback.bind(this)}
                                            >
                                                <i class='fas fa-arrow-left'></i>
                                            </button>{' '}
                                            <span>{language.cartpage.ca}</span>
                                            {language.cartpage.rt}{' '}
                                            <b>
                                                ({this.state.dishs.length}{' '}
                                                {language.cartpage.articles})
                                            </b>
                                        </h2>
                                        {this.state.dishs.length > 0
                                            ? this.state.dishs.map((j, x) => {
                                                return (
                                                    <div className='test'>
                                                        <div className='userimg'>
                                                            <img
                                                                src={
                                                                    imagedir.url +
                                                                    j.image
                                                                }
                                                                alt='user'
                                                            />
                                                        </div>
                                                        <h3>{j.dish_name}</h3>
                                                        <h6>
                                                            <b>
                                                                {
                                                                    language
                                                                        .cartpage
                                                                        .ingredienti
                                                                }
                                                            </b>{' '}
                                                            :{' '}
                                                            {this.state.dishs
                                                                .length > 0
                                                                ? this.state.dishs[
                                                                    x
                                                                ].ingrident.map(
                                                                    (
                                                                        p,
                                                                        x
                                                                    ) => {
                                                                        return (
                                                                            <span>
                                                                                {p +
                                                                                    ',' +
                                                                                    ' '}
                                                                            </span>
                                                                        );
                                                                    }
                                                                )
                                                                : ''}
                                                        </h6>
                                                        <h6>
                                                            <b>
                                                                {
                                                                    language
                                                                        .cartpage
                                                                        .allergeni
                                                                }
                                                            </b>{' '}
                                                            :{' '}
                                                            {this.state.dishs
                                                                .length > 0
                                                                ? this.state.dishs[
                                                                    x
                                                                ].allergeni.map(
                                                                    (
                                                                        m,
                                                                        x
                                                                    ) => {
                                                                        return (
                                                                            <span>
                                                                                {m +
                                                                                    ',' +
                                                                                    ' '}
                                                                            </span>
                                                                        );
                                                                    }
                                                                )
                                                                : ''}
                                                        </h6>
                                                        <h6>
                                                            <b>
                                                                {
                                                                    language
                                                                        .cartpage
                                                                        .minutes_of_preparation
                                                                }
                                                            </b>{' '}
                                                            : {j.peso}
                                                        </h6>
                                                        <p>
                                                            {' '}
                                                            <a
                                                                className='delete'
                                                                id={j.menu_id}
                                                                data-div_name={
                                                                    j.key
                                                                }
                                                                onClick={this.deletedish.bind(
                                                                    this
                                                                )}
                                                            >
                                                                <i className='far fa-trash-alt'></i>{' '}
                                                                {
                                                                    language
                                                                        .cartpage
                                                                        .delete
                                                                }
                                                            </a>
                                                        </p>
                                                        <h5>
                                                            <div className='number'>
                                                                <span
                                                                    className='minus'
                                                                    id={
                                                                        j.menu_id
                                                                    }
                                                                    data-div_roll={
                                                                        j.quantity
                                                                    }
                                                                    data-div_name={
                                                                        j.actual_price
                                                                    }
                                                                    onClick={this.subtract.bind(
                                                                        this
                                                                    )}
                                                                >
                                                                    -
                                                                </span>
                                                                <input
                                                                    type='text'
                                                                    value={
                                                                        j.quantity
                                                                    }
                                                                />
                                                                <span
                                                                    className='plus'
                                                                    id={
                                                                        j.menu_id
                                                                    }
                                                                    data-div_roll={
                                                                        j.quantity
                                                                    }
                                                                    data-div_name={
                                                                        j.actual_price
                                                                    }
                                                                    onClick={this.addition.bind(
                                                                        this
                                                                    )}
                                                                >
                                                                    +
                                                                </span>
                                                            </div>
                                                        </h5>

                                                        <div className='risparmi'>
                                                            <h3>
                                                                {'€ ' +
                                                                    Number(
                                                                        j.price
                                                                    ).toLocaleString(
                                                                        'sv-SE',
                                                                        {
                                                                            maximumFractionDigits: 2,
                                                                            minimumFractionDigits: 2,
                                                                        }
                                                                    )}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                            : ''}
                                        <Link
                                            to={
                                                this.state.dishs.length > 0
                                                    ? 'Singlepage/' +
                                                    this.state.dishs[
                                                        this.state.dishs
                                                            .length - 1
                                                    ].resturent_id
                                                    : '/'
                                            }
                                            className='continue'
                                        >
                                            {
                                                language.cartpage
                                                    .continue_shopping
                                            }
                                        </Link>

                                        {/* <nav aria-label="Page navigation example">
  <ul className="pagination">
    <li className="page-item arrow"><a className="page-link" href="#"><i className="fas fa-angle-double-left"></i> Previous</a></li>
    <li className="page-item"><a className="page-link" href="#">1</a></li>
    <li className="page-item"><a className="page-link" href="#">2</a></li>
    <li className="page-item"><a className="page-link" href="#">3</a></li>
    <li className="page-item"><a className="page-link" href="#">4</a></li>
    <li className="page-item"><a className="page-link" href="#">5</a></li>
    <li className="page-item arrow"><a className="page-link" href="#">Next <i className="fas fa-angle-double-right"></i></a></li>
  </ul>
</nav> */}
                                    </div>
                                </div>
                                <div className='col-lg-4 '>
                                    <div className='shopping_bag2'>
                                        <div className='test'>
                                            <h6>
                                                <i className='fas fa-tag'></i>{' '}
                                                {language.cartpage.coupon_lable}
                                            </h6>
                                            <form>
                                                <input
                                                    className='w-100'
                                                    value={this.state.code}
                                                    onChange={this.getcupen.bind(
                                                        this
                                                    )}
                                                    type='text'
                                                    name='firstname'
                                                    placeholder={
                                                        language.cartpage
                                                            .enter_code
                                                    }
                                                />
                                                <button type='button' value=''>
                                                    <i className='fas fa-arrow-right'></i>
                                                </button>
                                            </form>
                                        </div>
                                    </div>

                                    <div className='checkout_kamal'>
                                        <div className='row'>
                                            <div className='col-sm-12'>
                                                <div class='form-group'>
                                                    <label for='exampleInputEmail1'>
                                                        {
                                                            language.cartpage
                                                                .address
                                                        }
                                                    </label>
                                                    {this.state.gmapsLoaded && (
                                                        <PlacesAutocomplete
                                                            value={
                                                                this.state
                                                                    .address
                                                            }
                                                            onChange={this.fetchstreet.bind(
                                                                this
                                                            )}
                                                            onSelect={this.handleSelect.bind(
                                                                this
                                                            )}
                                                            searchOptions={{
                                                                location:
                                                                    new google.maps.LatLng(
                                                                        localStorage.getItem(
                                                                            'lat'
                                                                        ),
                                                                        localStorage.getItem(
                                                                            'long'
                                                                        )
                                                                    ),
                                                                radius: 50,
                                                                // types: ['address']
                                                            }}
                                                        >
                                                            {({
                                                                getInputProps,
                                                                suggestions,
                                                                getSuggestionItemProps,
                                                                loading,
                                                            }) => (
                                                                <div>
                                                                    <input
                                                                        {...getInputProps(
                                                                            {
                                                                                placeholder:
                                                                                    language
                                                                                        .cartpage
                                                                                        .delivery_address,
                                                                                className:
                                                                                    'form-control',
                                                                            }
                                                                        )}
                                                                    />
                                                                    <div className='autocomplete-dropdown-container'>
                                                                        {loading && (
                                                                            <div>
                                                                                Loading...
                                                                            </div>
                                                                        )}
                                                                        {suggestions.map(
                                                                            (
                                                                                suggestion
                                                                            ) => {
                                                                                const className =
                                                                                    suggestion.active
                                                                                        ? 'suggestion-item--active'
                                                                                        : 'suggestion-item';
                                                                                // inline style for demonstration purpose
                                                                                const style =
                                                                                    suggestion.active
                                                                                        ? {
                                                                                            backgroundColor:
                                                                                                '#fafafa',
                                                                                            cursor: 'pointer',
                                                                                        }
                                                                                        : {
                                                                                            backgroundColor:
                                                                                                '#ffffff',
                                                                                            cursor: 'pointer',
                                                                                        };
                                                                                return (
                                                                                    <div
                                                                                        {...getSuggestionItemProps(
                                                                                            suggestion,
                                                                                            {
                                                                                                className,
                                                                                                style,
                                                                                            }
                                                                                        )}
                                                                                    >
                                                                                        <ul>
                                                                                            <li>
                                                                                                {
                                                                                                    suggestion.description
                                                                                                }
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </PlacesAutocomplete>
                                                    )}
                                                    <span className='placeaddress displayblockclass'>
                                                        {this.state.street == ''
                                                            ? 'please enter your street address'
                                                            : this.state.street
                                                                .length < 5
                                                                ? 'please enter right your street address'
                                                                : ''}
                                                    </span>
                                                </div>
                                            </div>
                                            {/* <div className="col-sm-12">
            <div class="form-group">
               <label for="exampleInputEmail1">Phone No</label>
               <input type="text" class="form-control"  placeholder="Phone no" onChange={this.fetchphone.bind(this)} value={this.state.phone} required/>
               <span className="placephone displayblockclass">{this.state.phone=="" ? "please enter your phone number": this.state.phone.length<9 ? "please enter valid phone number" : "" }</span>
                  </div>
                  </div> */}
                                            {/* <div className="col-sm-12">
            <div class="form-group">
               <label for="exampleInputEmail1">Zip Code</label>
               <input type="text" class="form-control" placeholder="Zip code"  onChange={this.fetchzip.bind(this)} value={this.state.zip} required/>
               <span className="placezip displayblockclass">{this.state.zip=="" ? "please enter your zip code": this.state.zip.length<5 ? "please enter right your zip code" : "" }</span>
                  </div>
                  </div>
                  <div className="col-sm-12">
            <div class="form-group">
               <label for="exampleInputEmail1">Company Name </label>
               <input type="text" class="form-control" placeholder="Company name" onChange={this.fetchcompny.bind(this)} value={this.state.comp} required/>
               <span className="placecomp displayblockclass">{this.state.comp=="" ? "please enter company name": this.state.comp.length<5 ? "please enter right name of company" : "" }</span>
                  </div>
                  </div> */}
                                        </div>
                                    </div>
                                    <div className='checkout_main'>
                                        <h2 className='mb-4'>
                                            {language.cartpage.totale}
                                        </h2>
                                        <table className='table text-left'>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {
                                                            language.cartpage
                                                                .totale
                                                        }
                                                    </td>
                                                    <td className='text-right'>
                                                        {'€ ' +
                                                            Number(
                                                                this.state
                                                                    .subtotal
                                                            ).toLocaleString(
                                                                'sv-SE',
                                                                {
                                                                    maximumFractionDigits: 2,
                                                                    minimumFractionDigits: 2,
                                                                }
                                                            )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {' '}
                                                        {
                                                            language.cartpage
                                                                .discount
                                                        }
                                                    </td>
                                                    <td className='text-right'>
                                                        {'€ ' +
                                                            Number(
                                                                this.state
                                                                    .discount
                                                            ).toLocaleString(
                                                                'sv-SE',
                                                                {
                                                                    maximumFractionDigits: 2,
                                                                    minimumFractionDigits: 2,
                                                                }
                                                            )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {
                                                            language.cartpage
                                                                .delivery_charges
                                                        }
                                                    </td>
                                                    <td className='text-right'>
                                                        {'€ ' +
                                                            Number(
                                                                this.state
                                                                    .shipping
                                                            ).toLocaleString(
                                                                'sv-SE',
                                                                {
                                                                    maximumFractionDigits: 2,
                                                                    minimumFractionDigits: 2,
                                                                }
                                                            )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        className='pt-4'
                                                        defalutStyle='border-top:2px solid #d83a56'
                                                    >
                                                        <b>
                                                            {
                                                                language
                                                                    .cartpage
                                                                    .grand_total
                                                            }
                                                        </b>
                                                    </td>
                                                    <td
                                                        className='text-right pt-4'
                                                        defalutStyle='border-top:2px solid #d83a56'
                                                    >
                                                        <b>
                                                            {'€ ' +
                                                                Number(
                                                                    this.state
                                                                        .grandtotal +
                                                                    this
                                                                        .state
                                                                        .shipping -
                                                                    this
                                                                        .state
                                                                        .discount
                                                                ).toLocaleString(
                                                                    'sv-SE',
                                                                    {
                                                                        maximumFractionDigits: 2,
                                                                        minimumFractionDigits: 2,
                                                                    }
                                                                )}
                                                        </b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <a
                                        className='checkout'
                                        onClick={this.checkout.bind(this)}
                                    >
                                        {language.cartpage.checkout}
                                    </a>

                                    {/* <div className="checkout_main mt-3">
               <div className="dropdown">
  <button className="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
   Inserisci un codice sconto (facoltalovo)
  </button>
  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a className="dropdown-item" href="#">Action</a>
    <a className="dropdown-item" href="#">Another action</a>
    <a className="dropdown-item" href="#">Something else here</a>
  </div>
</div>
            </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='op errorpop2 invalid_login'>
                    <div className='errorbtn2 error h-100'></div>
                    <ul>
                        <li>{this.state.error}</li>
                        <button
                            className='upbtn errorbtn2'
                            onClick={this.removepop.bind(this)}
                        >
                            {language.Checkoutpage.ok}
                        </button>
                    </ul>
                </div>
            </div>
        );
    }
}
export default Cart;
