import React, { Component } from 'react';
import { PayPalButton } from "react-paypal-button-v2";
import FireOrderUpdate from '../API/FireOrderUpdate.js';
class paypalrs extends  React.Component {
    constructor(props) {
       super(props);
       this.state={amounts:"",oid:""};
      }
componentDidMount= async ()=>
{
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if(params.amount && params.amount>0 && params.oid && params.oid!=="")
    {
   this.setState({amounts:params.amount,oid:params.oid});
    }
}

paypalorder=async(data)=>
{ 
    if(data.id && data.status=="COMPLETED" && data.id!=="")
    {
    var object={
    "payment":"success",
    "trans_id":data.id,
    }
    var submit= await FireOrderUpdate(this.state.oid,object);
    }
    else{
    var object={
    "payment":"cancel",
    "trans_id":"",
    }
    var submit= await FireOrderUpdate(this.state.oid,object);
    }
}
cancel=async(data)=>
{
    var object={
        "payment":"cancel",
        "trans_id":"",
        }
        var submit= await FireOrderUpdate(this.state.oid,object);
}
render()
{
    return(<>{this.state.amounts && this.state.amounts>0 && this.state.oid && this.state.oid!==""?
          <div class="changeable mypaypalbutton">
  <span>{"Press here to continue"}</span>  
    <PayPalButton 
        amount={Number(this.state.amounts)}
        options={{
          clientId: "Ab4ZTpAqPBUEO85vxBbiceahg6yWNdvtlei-rfCXORAMF92_VeUozjtfvXMrQHGAEJCXvMORLOcsvQAO"
          ,currency:"EUR"
        }}
       onSuccess={(details, data) => {
         this.paypalorder(details);
           return fetch("/paypal-transaction-complete", {
             method: "post",
             body: JSON.stringify({
               orderID: this.state.oid
             })
         });
       }}
       onCancel={(details, data) => {
        this.cancel(details);
      }}
     /></div>:""}</>);
}
}
export default paypalrs;


