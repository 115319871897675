import React, { Component } from 'react';
import { Link, Redirect  } from "react-router-dom";
import firebase from 'firebase/app';
import 'firebase/firestore';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
   import Geocode from "react-geocode";
import Header from '../Header/index.js';
import language from'../API/language.json';
class delivery_address extends  React.Component {
    constructor(props) {
       super(props);
     
       this.state={imageVideoUrl:"",loader:false,finalydata:[],phone:localStorage.getItem("phone") && localStorage.getItem("phone")!=="null" ? localStorage.getItem("phone") :"",web:localStorage.getItem("web") && localStorage.getItem("web")!=="null" ? localStorage.getItem("web") :"",lastname:localStorage.getItem("lastname"),firstname:localStorage.getItem("firstname"),city:localStorage.getItem("city") && localStorage.getItem("city")!=="null" ? localStorage.getItem("city") :"",fullname:localStorage.getItem("firstname")+" "+localStorage.getItem("lastname"),userImage:"",date:Date.now(),time:Date.now(),loader:"false" ,
       gmapsLoaded: false,address:"",street:"",city:"",statename:"",country:"",  
      }
      }
      componentDidMount=async()=>
      {

        firebase.firestore().collection("delivery_address").doc('delivery'+localStorage.getItem('userid')).get().then((docRef) => { 
            var data = docRef.data();
            this.setState({title:data.name,address:data.address,state:data.state,country:data.country,city:data.city,Latitude:data.Latitude,Longitude:data.Longitude,});
             })
            .catch((error) => { 
        
            })
        setTimeout(() => {
            this.initMap();
          }, 2000);
      }
      initMap = () => {
        this.setState({
          gmapsLoaded: true,
        })
      }
      
  handleSelect = address => {
    this.setState({ address });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState({Latitude:latLng.lat,Longitude:latLng.lng});
        this.fetchAddress(latLng.lat,latLng.lng);
      })
      .catch(error => console.error('Error', error));
  };

  fetchstreet = address => {
    this.setState({ address,street:address });
  };

  fetchAddress(lati,lngi)
  {
    Geocode.setApiKey("AIzaSyDM4LDXtUX7rM-FHenlmmXZ7jbfbczfqWk");
    Geocode.setLanguage("en");
    Geocode.setRegion("es");
    Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();
    Geocode.fromLatLng(lati,lngi).then(
       (response) => {
         const address = response.results[0].formatted_address;
         let city, state, country;
         for (let i = 0; i < response.results[0].address_components.length; i++) {
           for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
             switch (response.results[0].address_components[i].types[j]) {
               case "locality":
                 city = response.results[0].address_components[i].long_name;
                 break;
               case "administrative_area_level_1":
                 state = response.results[0].address_components[i].long_name;
                 break;
               case "country":
                 country = response.results[0].address_components[i].long_name;
                 break;
             }
           }
         }
         this.setState({statename:state,country:country,city:city});
       },
       (error) => {
         console.error(error);
       }
     );
  }
  changeaddress()
  {

        firebase.firestore().collection("delivery_address").doc('delivery'+localStorage.getItem('userid')).set({
          address: this.state.address,
          state:this.state.statename,
          country:this.state.country,
          city:this.state.city,
          Latitude:this.state.Latitude,
          Longitude:this.state.Longitude,
            uid:'delivery'+localStorage.getItem('userid')
      }).then(() =>
          alert('Address Update successfully'),
        );
  
  
  }
      render()
      {
return(
    <><Header/>
<section class="profile_section">
   {/* <div class="pro_profile">
      <div class="container">
         <div class="profilehead">
            <span>
            <img src={localStorage.getItem('image') && localStorage.getItem('image')!==null ? localStorage.getItem('image') : "images/empty.webp"} alt="img"/>
            </span>
            <h5>{this.state.fullname}</h5>
            <p>
            </p>
         </div>
      </div>
   </div> */}

   <div class="container">
      <div class="profile_in">
         <div class="profile_sider">
         <h4>{language.profilePages.ACTIVITY}</h4>
            <ul>
            <li><Link to={'/Myprofile'} class="reviews"> {language.profilePages.my_profile}</Link></li> 
             <li><Link to={'/delivery_address'}>{language.profilePages.delivery_address}</Link></li>
               <li><Link to={'/Myorder'}>{language.profilePages.order_history}</Link></li>
               <li><Link to={'/Myfavorite'}>{language.profilePages.favorite_dish}</Link></li>
               <li><Link to={'/ChnagePassword'}>{language.profilePages.change_password}</Link></li>
            </ul>
         </div>
         
         <div class="reviews">
            <h3>{language.profilePages.delivery_address}</h3>
            <div class="form-group">
            <label for="exampleInputEmail1">{language.profilePages.address}</label>
  {this.state.gmapsLoaded && (<PlacesAutocomplete
        value={this.state.address}
        onChange={this.fetchstreet.bind(this)}
        onSelect={this.handleSelect.bind(this)}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: language.profilePages.delivery_address,
                className:'form-control',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                     <ul>
                    <li>{suggestion.description}</li>
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>

          
        )}
      </PlacesAutocomplete>)}
                  </div>
               <div class="modal-footer">
               <button type="button" onClick={this.changeaddress.bind(this)} id="changepassword">{this.state.loader==true ? language.profilePages.update+"..." : language.profilePages.update}</button>
            </div>
       
    
         </div>
      </div>
   </div>
</section>
</>
)}}
export default delivery_address;
