import firebase from "firebase/app";
import 'firebase/firestore';
// import firestore from "./firestore";
export default async function DeleteCart() {

    if(localStorage.getItem("userid") && localStorage.getItem("userid")!==null)
    {
      var uid=localStorage.getItem("userid");
    }
    else
    {
      var uid=localStorage.getItem("sessionId")
    }
var response =  await firebase.firestore().collection("Cart")
.where('user_id', '==', uid).get();
const data4 = await response.docs.map(doc => doc.id);
if(data4.length>0)
{
    var error;
    for (let index = 0; index < data4.length; index++) {
        error = await firebase.firestore().collection('Cart').doc(data4[index]).delete();
    }
    return await error;
}
}

