import firebase from "firebase/app";
import 'firebase/firestore';
// import firestore from "./firestore";
export default async function FetchCart () {
               try {
               
                   var id = localStorage.getItem('sessionId');
                   var response =  await firebase.firestore().collection("Cart").where('user_id', '==', id).get();
                //    const userData = await response.docs.map(doc => doc.data());
                   const ids = await response.docs.map(doc => doc.id);
                   if(localStorage.getItem('userid')!==null && localStorage.getItem('phone')!==null)
                   {
                     for (let index = 0; index < ids.length; index++) {
                         var response =  await firebase.firestore().collection("Cart").doc(ids[index]).update({user_id:localStorage.getItem('userid')});
                     }
                     var response1 =  await firebase.firestore().collection("Cart").where('user_id', '==', localStorage.getItem('userid')).get();
                     const userData = await response1.docs.map(doc => doc.data());
                     const idsp = await response1.docs.map(doc => doc.id);
                     var obj={'data':userData,'ids':idsp};
                     return await obj;
                   }
                   else{
                    var response =  await firebase.firestore().collection("Cart").where('user_id', '==', localStorage.getItem('sessionId')).get();
                    const userData = await response.docs.map(doc => doc.data());
                    const idsp = await response.docs.map(doc => doc.id);
                    var obj={'data':userData,'ids':idsp};
                    return await obj;
                   }
         
                  
              } catch {
           return 'error';
              }
}