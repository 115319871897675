import firebase from "firebase/app";
import 'firebase/firestore';
// import firestore from "./firestore";
export default async function Fetchrecomended (id) {
               try {
                const documentSnapshot = await firebase.firestore().collection('menus').where('resturent_id', '==', id).where('recommended', '==', "true").where('status', '==', "active").get();
                const data2 = await documentSnapshot.docs.map(doc => doc.data());
                const data = await documentSnapshot.docs.map(doc => doc.id);
                var obj={'data':data2,'ids':data};
              if(data.length>0)
              {
               return await obj;
              }
              else{
                  var ods=[]
                return await ods;
              }
              } catch {
           return 'error';
              }
            }