import React, { Component } from 'react';
import { Link  } from "react-router-dom";
// import $ from 'jquery';
// import Loginapi from '../API/Loginapi.js';
// import Registerapi from '../API/Registerapi.js';
// import Findemail from '../API/Findemail.js';
class Login extends  React.Component {
    constructor(props) {
       super(props);
       this.state={email:"",pass:"",firstname:"",logemail:"",logpass:"",lastname:"",passtype2:"password",setemail:"",setpassword:"",number:"",passtype:"password"
      }
      }
   
      render()
      {
          return(<div>
        <div className="loginbody"></div>
		<div className="grad"></div>
		<div className="header">
			<div>Site<span>Random</span></div>
		</div>
		<br/>
		<div className="login">
				<input type="text" placeholder="username" name="user"/><br/>
				<input type="password" placeholder="password" name="password"/><br/>
				<input type="button" value="Login"/>
		</div>



          </div>)
      }
      }

      export default Login;


