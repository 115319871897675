import React, { Component } from 'react';
// import { Link  } from "react-router-dom";
import firebase from 'firebase/app';
import 'firebase/firestore';
import fetchgoodwords from '../API/fetchgoodwords.js';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from 'jquery';
import Header from '../Header/index.js';
import language from '../API/language.json';
import imagedir from '../API/imagedir.json';
const db = firebase.firestore();

class Terms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            url: '',
            description: '',
            goodwords: [],
            gallerys: [],
            food: [],
            Ambience: [],
            final: [],
            options3: {
                center: true,
                items: 2,
                loop: true,
                autoplay: false,
                autoplayTimeout: 5000,
                margin: 10,
                responsiveClass: true,
                responsive: {
                    0: { items: 1, nav: true },
                    576: { items: 1, nav: true },
                    768: { items: 2, nav: true },
                    992: { items: 3, nav: true },
                    1200: { items: 3, nav: true, margin: 30 },
                },
            },
        };
    }

    componentDidMount = async () => {
        var response = await db.collection('gallerys').get();
        const userData = await response.docs.map((doc) => doc.data());
        this.setState({ gallerys: userData, final: userData });
        var gooodwords = await fetchgoodwords();
        if (gooodwords !== '' && gooodwords !== null && gooodwords.length > 0) {
            this.setState({ goodwords: gooodwords });
        }
        $('.cloned .active').addClass('center');

        var response2 = await db
            .collection('gallerys')
            .where('category', '==', 'Food')
            .get();
        const userData2 = await response2.docs.map((doc) => doc.data());
        this.setState({ food: userData2 });
        var response3 = await db
            .collection('gallerys')
            .where('category', '==', 'Ambience')
            .get();
        const userData3 = await response3.docs.map((doc) => doc.data());
        this.setState({ Ambience: userData3 });
    };

    getdata(data) {
        return { __html: data };
    }
    all() {
        $('#food').removeClass('active');
        $('#ambiences').removeClass('active');
        $('#all').addClass('active');
        this.setState({ final: this.state.gallerys });
    }
    foods() {
        $('#all').removeClass('active');
        $('#ambiences').removeClass('active');
        $('#food').addClass('active');
        this.setState({ final: this.state.food });
    }
    Ambiences() {
        $('#all').removeClass('active');
        $('#food').removeClass('active');
        $('#ambiences').addClass('active');
        this.setState({ final: this.state.Ambience });
    }

    render() {
        return (
            <>
                <Header />
                <div class='headingin'>
                    <h2>
                        <span>{language.footerpages.our}</span>{' '}
                        {language.footerpages.news}
                    </h2>
                    <h1>
                        <span>{language.footerpages.our}</span>{' '}
                        {language.footerpages.news}
                    </h1>
                </div>
                <section class='our_galary'>
                    <div class='container'>
                        <ul class='galleryUl'>
                            <li
                                id='all'
                                onClick={this.all.bind(this)}
                                class='active'
                            >
                                {'All (' + this.state.gallerys.length + ')'}
                            </li>
                            <li
                                id='food'
                                onClick={this.foods.bind(this)}
                                class=''
                            >
                                {'Food (' + this.state.food.length + ')'}
                            </li>
                            <li
                                id='ambiences'
                                onClick={this.Ambiences.bind(this)}
                                class=''
                            >
                                {'Ambience (' +
                                    this.state.Ambience.length +
                                    ')'}
                            </li>
                        </ul>
                        <div class='row'>
                            {this.state.final.map((j, x) => {
                                return (
                                    <div class='intab'>
                                        <div class='test1'>
                                            <span>
                                                <i class='fas fa-compress'></i>
                                            </span>
                                            <a
                                                href={imagedir.url + j.image}
                                                data-fancybox='images1'
                                            >
                                                <img
                                                    class='w-100'
                                                    src={imagedir.url + j.image}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>

                <section class='client' st>
                    <div class='container-flude'>
                        <h2>{language.footerpages.good_words}</h2>

                        {this.state.goodwords.length > 0 ? (
                            <OwlCarousel
                                center={true}
                                {...this.state.options3}
                                items={3}
                                className='owl-carousel owl-carousel_testi owl-theme'
                                loop
                                margin={10}
                                nav
                            >
                                {this.state.goodwords.map((j, x) => {
                                    return (
                                        <div class='item'>
                                            <div class='usr'>
                                                <img
                                                    class='user'
                                                    src={imagedir.url + j.image}
                                                    alt='img'
                                                />
                                                <h4>{j.name}</h4>
                                            </div>
                                            <p>
                                                <span
                                                    dangerouslySetInnerHTML={this.getdata(
                                                        j.description
                                                    )}
                                                />
                                            </p>
                                        </div>
                                    );
                                })}
                            </OwlCarousel>
                        ) : (
                            ''
                        )}
                    </div>
                </section>
            </>
        );
    }
}
export default Terms;
