import React, { Component } from 'react';
import { Link } from "react-router-dom";
// import $, { param } from 'jquery';
// import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/firestore';
// import updateuser from '../API/updateuser.js';
import Pagination from "react-js-pagination";
import moment from 'moment';
import Header from '../Header/index.js';
import language from'../API/language.json';
import imagedir from'../API/imagedir.json';

class Myfavorite extends  React.Component {
    constructor(props) {
       super(props);
     
       this.state={imageVideoUrl:"",orders:[],loader:false,activePage:1,finalydata:[],phone:localStorage.getItem("phone") && localStorage.getItem("phone")!=="null" ? localStorage.getItem("phone") :"",web:localStorage.getItem("web") && localStorage.getItem("web")!=="null" ? localStorage.getItem("web") :"",lastname:localStorage.getItem("lastname"),firstname:localStorage.getItem("firstname"),city:localStorage.getItem("city") && localStorage.getItem("city")!=="null" ? localStorage.getItem("city") :"",fullname:localStorage.getItem("firstname")+" "+localStorage.getItem("lastname"),userImage:"",date:Date.now(),time:Date.now(),loader:"false"   
      }
      }
      componentDidMount=async()=>
      {
        var response =  await firebase.firestore().collection("Favourites").where('user_id', '==', localStorage.getItem("userid")).get();
        const userData = await response.docs.map(doc => doc.data());
        if(userData.length>0)
        {
                this.setState({orders:userData},()=>
                {
                    this.handlePageChange(1);
                });
        }
        
      }
weekDays = (dayNumber) => {
    if(dayNumber==0)
    {
       return"Sunday";
    }
    if(dayNumber==6)
    {
       return"Saturday";
    }
    if(dayNumber==5)
    {
       return"Friday";
    }
    if(dayNumber==4)
    {
       return"Thursday";
    }
    if(dayNumber==3)
    {
       return"Wednesday";
    }
    if(dayNumber==2)
    {
       return"Tuesday";
    }
    if(dayNumber==1)
    {
       return"Monday";
    }
    }
 getdateformat(datedata)
 {
   if (datedata) {
      var postedDate=moment.unix(datedata).format("YYYY-MM-DD");
      var currentDate=moment(new Date()).format("YYYY-MM-DD");
      var eventDate=datedata;
      var date = "";
      var duration = moment.duration(moment(postedDate).diff(moment(currentDate)));
      var daysDifference = duration.asDays();
      if (daysDifference <= -8 || daysDifference > 7) {
      const day = moment.unix(eventDate).format("D");
      const month = moment.unix(eventDate).format("MMM");
      const time = moment.unix(eventDate).format("h:mma");
      var lasttag = "";
      switch (day % 10) {
      case 1:
      if (day != 11) {
      lasttag = "st";
      } else {
      lasttag = "th";
      }
      break;
      case 2:
      if (day != 12) {
      lasttag = "nd";
      } else {
      lasttag = "th";
      }
      break;
      case 3:
      if (day != 13) {
      lasttag = "rd";
      } else {
      lasttag = "th";
      }
      break;
      default: lasttag = "th";
      break;
      }
      date = `${month} ${day}${lasttag}, ${time}`;
      return date;
      }
      if (daysDifference < -1 && daysDifference > -8) {
      date = "last"+" "+this.weekDays(moment(postedDate).weekday()) +" " + "at"+" "+ moment.unix(eventDate).format("h:mma");
      return date;
      }
      if (daysDifference < 0 && daysDifference >= -1) {
      date = "yesterday at "+ moment.unix(eventDate).format("h:mma");
      return date;
      }
      if (daysDifference >= 0 && daysDifference < 1) {
      date = moment.unix(eventDate).format("h:mma");
      return date;
      }
      if (daysDifference >= 1 && daysDifference < 2) {
      date = "tomorrow at "+ moment.unix(eventDate).format("h:mma");
      return date;
      }
      if (daysDifference >= 2 && daysDifference < 7) {
      date = "this" +" "+this.weekDays(moment(postedDate).weekday()) +" "+ "at" +" "+ moment.unix(eventDate).format("h:mma");
      return date;
      }
      if (daysDifference == 7) {
      date = "next"+" " + this.weekDays(moment(postedDate).weekday()) +" "+"at" +" "+ moment.unix(eventDate).format("h:mma");
      return date;
      }
      }
 }
handlePageChange(pageNumber) {
var title=[];
    for (let index = 0; index < this.state.orders.length; index++) {
       
        if((index<=pageNumber*5)&&(pageNumber*5-5<=index))
        {
            if(title.length<=4)
            {
                title.push(this.state.orders[index])
            }
        }
    }
    this.setState({activePage: pageNumber,finalydata:title});
  }
      render()
      {
return(
    <><Header/>
<section class="profile_section">
   {/* <div class="pro_profile">
      <div class="container">
         <div class="profilehead">
            <span>
            <img src={localStorage.getItem('image') && localStorage.getItem('image')!==null ? localStorage.getItem('image') : "images/empty.webp"} alt="img"/>
            </span>
            <h5>{this.state.fullname}</h5>
            <p>
              
            </p>
         </div>
        

      </div>
   </div> */}

   <div class="container">
      <div class="profile_in">
         <div class="profile_sider">
         <h4>{language.profilePages.ACTIVITY}</h4>
            <ul>
            <li><Link to={'/Myprofile'} class="reviews"> {language.profilePages.my_profile}</Link></li> 
             <li><Link to={'/delivery_address'}>{language.profilePages.delivery_address}</Link></li>
               <li><Link to={'/Myorder'}>{language.profilePages.order_history}</Link></li>
               <li><Link to={'/Myfavorite'}>{language.profilePages.favorite_dish}</Link></li>
               <li><Link to={'/ChnagePassword'}>{language.profilePages.change_password}</Link></li>
            </ul>
            {/* <h4>ONLINE ORDERING</h4>
            <ul>
               <li><a href="order_history.php">Order History</a></li>
               <li><a href="my_addresses.php">My addresses</a></li>
               <li><a href="favorite_orders.php">Favorite Orders</a></li>
            </ul>
            <h4>PAYMENTS</h4>
            <ul>
               <li><a href="ucooking_credits.php">Ucooking Credits</a></li>
               <li><a href="manage_wallets.php">Manage Wallets</a></li>
               <li><a href="manage_cards.php">Manage Cards</a></li>
            </ul>
            <h4>TABLE BOOKING</h4>
            <ul>
               <li><a href="#">Your Bookings</a></li>
            </ul> */}
         </div>
         
         <div class="reviews">
            <h3>{language.profilePages.favorite_dish} </h3>

            {this.state.finalydata.length>0 ? this.state.finalydata.map((j, x) => {
        return(
            <div class="test">
               <img src={imagedir.url+j.image} alt="user"/>
               <h4><b>{language.profilePages.dish_name+' : '+j.name}</b> <span></span></h4>
               <h2><b>{language.profilePages.restaurant_name+' : '+j.resturent_name}</b></h2>
         
            </div>)}):""}
       
            <div class="w-100 text-center">
        
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={5}
          totalItemsCount={this.state.orders.length}
          pageRangeDisplayed={5}
          prevPageText={language.profilePages.previous}
          nextPageText={language.profilePages.next}
          onChange={this.handlePageChange.bind(this)}
        />
   
            </div>
         </div>
      </div>
   </div>
</section>
</>
)}}
export default Myfavorite;
