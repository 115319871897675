import React, { Component } from 'react';
import { Link  } from "react-router-dom";
// import $ from 'jquery';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Fetchrestu from '../API/Fetchrestu.js';
import Fetchrecomended from '../API/Fetchrecomended.js';
import FetchMenus from '../API/FetchMenus.js';
import LikedDish from '../API/LikedDish.js';
import FetchLikes from '../API/FetchLikes.js';
import LikeResturest from '../API/LikeResturest.js';
import fetchrestulike from '../API/fetchrestulike.js';
// import fetchOffer from '../API/fetchOffer.js';
// import AddedComment from '../API/AddedComment.js';
// import fetchRestuReview from '../API/fetchRestuReview.js';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import moment from 'moment';
import 'react-tabs/style/react-tabs.css';
import ShareLink from 'react-facebook-share-link';
import Header from '../Header/index.js';
import language from'../API/language.json';
import imagedir from'../API/imagedir.json';

// import {InstapaperShareButton,TwitterShareButton,} from "react-share";

class Singlepage extends  React.Component {
    constructor(props) {
       super(props);
       let restuid = null;
       const path = this.props.location.pathname;
       const params = this.props.location.pathname.split('/');
       restuid = params[params.length-1];
       this.state={restuid:restuid,details:{},recomended:[],reviewlist:[],comment:"",likerest:[],gallery:[],lastname:"",firstname:"",menus:[],likes:[],offerss:[],options3: {
         center: true,
            items:2,
             loop: true,
            autoplay: false,
            autoplayTimeout: 5000,
            margin:10,
            responsiveClass: true,
                  responsive: {
                    0: { items: 1, nav: true },
                    576: { items: 1, nav: true },
                    768: { items: 2, nav: true },
                    992: { items: 3, nav: true },
                    1200: { items: 3, nav: true, margin: 30 }
                  }
                 } 
      }

      }

      componentDidUpdate()
      {
         if(this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1]!=="" && this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1]!== undefined)
         {
            if(this.state.restuid!==this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1])
            {
              
               this.setState({restuid:this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1]},()=>
               {
                  this.componentDidMount();
               });
            } 
         }
      }
      componentDidMount= async ()=>
      {
       var returentdetail = await Fetchrestu(this.state.restuid);
       if(localStorage.getItem('userid') && localStorage.getItem('userid')!==null)
            {
         var fetchlike= await fetchrestulike(this.state.restuid);
         if(fetchlike.length>0 && fetchlike !==  "empty")
         {
         this.setState({likerest:fetchlike});
         }
         }
      var recomen = await Fetchrecomended(this.state.restuid);
      if(recomen.length>0 && recomen.data.length>0 && recomen.ids.length>0)
      {
         var title6=[]
         for (var i = 0; i < recomen.data.length; i++) {
         title6[i] = {
         image: recomen.data[i].featured_image,
         name: recomen.data[i].dish_name,
         price:recomen.data[i].price,
         key:recomen.ids[i],
         };
         }
         this.setState({recomended:title6})
      }
      // var fetchreview= await fetchRestuReview(this.state.restuid);
      // if(fetchreview!=="empty" && fetchreview.data.length>0 && fetchreview.ids.length>0)
      //  {
      //    var title3=[]
      //    for (var i = 0; i < fetchreview.data.length; i++) {
      //    title3[i] = {
      //    image: fetchreview.data[i].image,
      //    name: fetchreview.data[i].name,
      //    comment:fetchreview.data[i].comment,
      //    like:fetchreview.data[i].like,
      //    dislike:fetchreview.data[i].dislike,
      //    date:fetchreview.data[i].timestamp/1000,
      //    key:fetchreview.ids[i],
      //    };
      //    }
      //    this.setState({reviewlist:title3})
      // }
       if(returentdetail.length>0)
       {
      var likelist = await FetchLikes();
      if(likelist!=="empty" && likelist.length>0)
      {
      this.setState({likes:likelist});
      }
       var menus=await FetchMenus(returentdetail[0].resturent_id);
       if(menus!=="empty" && menus.data.length>0 && likelist.length>0)
       {
         var title=[]
         for (var i = 0; i < menus.data.length; i++) {
         title.push({
         image: menus.data[i].featured_image,
         name: menus.data[i].dish_name,
         price:menus.data[i].price,
         key:menus.ids[i],
         count:menus.data[i].number ? menus.data[i].number :0
         });
         }
      }
         this.setState({details:returentdetail[0],gallery:returentdetail[0].featured_images,menus:title.sort((a, b) => (a.count > b.count) ? 1 : -1)},()=>
         {
           this.setState({firstname:this.state.details.name.split(" ")[0]})
            this.lastname();
         });
       }
      //  var offers= await fetchOffer(this.state.restuid);
      //  if(offers && offers.length>0)
      //  {
      //     this.setState({offerss:offers});
      //  }
      }

      // weekDays = (dayNumber) => {
      //    if(dayNumber==0)
      //    {
      //       return"Sunday";
      //    }
      //    if(dayNumber==6)
      //    {
      //       return"Saturday";
      //    }
      //    if(dayNumber==5)
      //    {
      //       return"Friday";
      //    }
      //    if(dayNumber==4)
      //    {
      //       return"Thursday";
      //    }
      //    if(dayNumber==3)
      //    {
      //       return"Wednesday";
      //    }
      //    if(dayNumber==2)
      //    {
      //       return"Tuesday";
      //    }
      //    if(dayNumber==1)
      //    {
      //       return"Monday";
      //    }
      //    }
      // getdateformat(datedata)
      // {
      //   if (datedata) {
      //      var postedDate=moment.unix(datedata).format("YYYY-MM-DD");
      //      var currentDate=moment(new Date()).format("YYYY-MM-DD");
      //      var eventDate=datedata;
      //      var date = "";
      //      var duration = moment.duration(moment(postedDate).diff(moment(currentDate)));
      //      var daysDifference = duration.asDays();
      //      if (daysDifference <= -8 || daysDifference > 7) {
      //      const day = moment.unix(eventDate).format("D");
      //      const month = moment.unix(eventDate).format("MMM");
      //      const time = moment.unix(eventDate).format("h:mma");
      //      var lasttag = "";
      //      switch (day % 10) {
      //      case 1:
      //      if (day != 11) {
      //      lasttag = "st";
      //      } else {
      //      lasttag = "th";
      //      }
      //      break;
      //      case 2:
      //      if (day != 12) {
      //      lasttag = "nd";
      //      } else {
      //      lasttag = "th";
      //      }
      //      break;
      //      case 3:
      //      if (day != 13) {
      //      lasttag = "rd";
      //      } else {
      //      lasttag = "th";
      //      }
      //      break;
      //      default: lasttag = "th";
      //      break;
      //      }
      //      date = `${month} ${day}${lasttag}, ${time}`;
      //      return date;
      //      }
      //      if (daysDifference < -1 && daysDifference > -8) {
      //      date = "last"+" "+this.weekDays(moment(postedDate).weekday()) +" " + "at"+" "+ moment.unix(eventDate).format("h:mma");
      //      return date;
      //      }
      //      if (daysDifference < 0 && daysDifference >= -1) {
      //      date = "yesterday at "+ moment.unix(eventDate).format("h:mma");
      //      return date;
      //      }
      //      if (daysDifference >= 0 && daysDifference < 1) {
      //      date = moment.unix(eventDate).format("h:mma");
      //      return date;
      //      }
      //      if (daysDifference >= 1 && daysDifference < 2) {
      //      date = "tomorrow at "+ moment.unix(eventDate).format("h:mma");
      //      return date;
      //      }
      //      if (daysDifference >= 2 && daysDifference < 7) {
      //      date = "this" +" "+this.weekDays(moment(postedDate).weekday()) +" "+ "at" +" "+ moment.unix(eventDate).format("h:mma");
      //      return date;
      //      }
      //      if (daysDifference == 7) {
      //      date = "next"+" " + this.weekDays(moment(postedDate).weekday()) +" "+"at" +" "+ moment.unix(eventDate).format("h:mma");
      //      return date;
      //      }
      //      }
      // }
      lastname()
      {
            var str = this.state.details.name.split(" ");
            var str88 = str.pop();
             var stirng = str88.toString();
             this.setState({lastname:stirng});
         }
      
      createMarkup()
      {
        return {__html: this.state.details.description};
      }
      createMarkup2()
      {
         return {__html: this.state.details.about_us};
      }

      fetchloader()
      {
         if(this.state.firstname=="")
         {
            return "loaderclass singleloder displayblock";
         }
         else{
            return "loaderclass singleloder";
         }
      }
      fetchmainclass()
      {
         if(this.state.firstname=="")
         {
            return "sibngle_site noneclass";
         }
         else{
            return "sibngle_site";
         }
      }
     
      fetchmainclass2()
      {
         if(this.state.firstname=="")
         {
            return "popular_dishes popular_dishes2 noneclass";
         }
         else{
            return "popular_dishes popular_dishes2";
         }
      }
      likefood=async(e)=>
      {
         var Obj={'image':e.currentTarget.dataset.div_image,'name':e.currentTarget.dataset.div_name,
         'menu_id':e.currentTarget.id,'resturent_id':this.state.restuid,'resturent_name':this.state.details.name};
         if(e.currentTarget.dataset.div_roll=="true")
         {
            var objIndex = this.state.likes.findIndex((obj => obj.menu_id == e.currentTarget.id));
            if(objIndex!== -1)
            {
            this.state.likes.splice(objIndex, 1);
            this.setState({likes:this.state.likes});
            }
         }
         else{
            this.state.likes.push(Obj);
            this.setState({likes:this.state.likes});
         }
            var store = await LikedDish(Obj,e.currentTarget.dataset.div_roll);
         }
         likerestu=async(e)=>
         {
            if(localStorage.getItem('userid') && localStorage.getItem('userid')!==null)
            {
            var obj={resturent_id:this.state.restuid,resturent_name:this.state.details.name,image:this.state.gallery[0],user_id:localStorage.getItem('userid')};
            if(e.currentTarget.id=="true")
            {
               this.setState({likerest:[]});
            }
            else{
               this.state.likerest.push(obj);
               this.setState({likerest:this.state.likerest});
            }
            var addlike = await LikeResturest(obj,e.currentTarget.id);

         }
         }
         // getcomment(e)
         // {
         //    console.log(e.target.value);
         //    this.setState({comment:e.target.value});
         // }
         // gocomment=async()=>
         // {
         //    if(this.state.comment!=="")
         //    {
         //    var object={user_id:localStorage.getItem('userid'),image:"",name:localStorage.getItem('firstname'),comment:this.state.comment,resturent_id:this.state.restuid,
         //    like:0,dislike:0,timestamp:Date.now()}
         //    this.state.reviewlist.push(object);
         //    this.setState({reviewlist:this.state.reviewlist});
         //    var addedcomment= await AddedComment(object);
         //    if(addedcomment)
         //    {
         //       this.setState({comment:""});
         //    }
         // }
         // }
         gosingledish(e)
         {
            this.props.history.push("/Singledish/"+e.currentTarget.id);
         }
      render()
      {
          return(
              <div><Header/>
<div className={this.fetchloader()}>
      <img
         src="../../images/tiger.gif"
         alt="loading"
      />
      </div>
<section className={this.fetchmainclass()} >
   <div className="container">
      {/* {this.state.offerss && this.state.offerss.length>0 ? <div className="coupons">{this.state.offerss.map((p, x) => {
return(<><h4>Offer</h4><p>{p.name+ " | Use code "+p.code}</p></>)})}</div> :"" } */}
   <h2><span>{this.state.firstname!=="" ? this.state.firstname  : ""}</span> {this.state.lastname!=="" ? this.state.lastname :""} 
   {/* <span className="stars">  { Math.round(this.state.details.ratting)==1 || Math.round(this.state.details.ratting)>1 ? <img src="../../images/fs.png" alt="icon"/>: <img src="../../images/ns.png" alt="icon"/>}
   { Math.round(this.state.details.ratting)==2 || Math.round(this.state.details.ratting)>2 ? <img src="../../images/fs.png" alt="icon"/> : <img src="../../images/ns.png" alt="icon"/>}
   { Math.round(this.state.details.ratting)==3 || Math.round(this.state.details.ratting)>3 ? <img src="../../images/fs.png" alt="icon"/> : <img src="../../images/ns.png" alt="icon"/>}
   { Math.round(this.state.details.ratting)==4 || Math.round(this.state.details.ratting)>4 ? <img src="../../images/fs.png" alt="icon"/> : <img src="../../images/ns.png" alt="icon"/>}
   { Math.round(this.state.details.ratting)==5 || Math.round(this.state.details.ratting)>5 ? <img src="../../images/fs.png" alt="icon"/> : <img src="../../images/ns.png" alt="icon"/>}
   {this.state.details.ratting>0 ? <i>{Math.round(this.state.details.ratting)+".0"}</i>:""}
</span> */}
</h2>
   <ul className="heart_share">
   {/* {localStorage.getItem("userid") && localStorage.getItem("userid")!==null ? <li data-toggle="modal" data-target="#comment"><img src="../../images/comms.png" alt="icon"/> Comment</li> : ""} */}
      <li onClick={this.likerestu.bind(this)} id={this.state.likerest.length>0 ? "true" : "false"}><img src={this.state.likerest.length>0 ? "../../images/flike.png" : "../../images/heart.png"} alt="icon"/> {language.singleResturentpage.like}</li>
      <li class="dropdown">
  <span class="btn dropdown-toggle"id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="../../images/share.png" alt="icon"/> {language.singleResturentpage.share}</span>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

  <ShareLink link={'https://ucooking-fb04b.web.app/Sinlepage/'+this.state.restuid}>
   {link => (
      <a class="dropdown-item" target='_blank' href={link} ><i class="fab fa-facebook-f"></i> {language.singleResturentpage.share_with_facebook}</a>
   )}
</ShareLink>
{/* <TwitterShareButton url={'https://ucooking-fb04b.web.app/Sinlepage/'+this.state.restuid}>
  {shareCount => <a class="dropdown-item" ><i class="fab fa-twitter"></i>Share Twitter</a>}
</TwitterShareButton> */}
    {/* <a class="dropdown-item"><i class="fab fa-instagram"></i> Share Instagram</a> */}
  
  </div>
</li>
   </ul>
   {/* <!------ Include the above in your HEAD tag ----------> */}
   <div className="slider">
      <div className="row">
         <div className="col-sm-7 pr-0">
            <div className="slidermainim">
            <img className="h-100" src={this.state.details.banner_image ? imagedir.url+this.state.details.banner_image : this.state.gallery.length>0 ? imagedir.url+this.state.gallery[0] : ""} alt="img"/>
         </div>
         </div>

         <div className="col-sm-5 pl-2">
                <div className="sliderimg">
         <div className="row">
            <div className="col-6 col-sm-6 mb-2">
               <div className="test">
                  <img src={this.state.gallery.length>0 ? imagedir.url+this.state.gallery[1] : ""} alt="img"/>
               </div>
            </div>
            <div className="col-6 col-sm-6 mb-2">
               <div className="test">
                  <img src={this.state.gallery.length>0 ? imagedir.url+this.state.gallery[2] : ""} alt="img"/>
                  <a href="gallery.php">View Gallery</a>
               </div>
            </div>
            <div className="col-6 col-sm-6">
               <div className="test">
                  <img src={this.state.gallery.length>0 ? imagedir.url+this.state.gallery[3] : ""} alt="img"/>
               </div>
            </div>
            <div className="col-6 col-sm-6">
               <div className="test">
                  <img src={this.state.gallery.length>0 ? imagedir.url+this.state.gallery[4] : ""} alt="img"/>
                  <span className="uploadIus">
                  <input type="file" name=""/>
                  <i className="fas fa-camera"></i>
                  Add Photos
               </span>
               </div>
            </div>
         </div>
      </div>
  </div>
         </div>

      

      

   

  


   </div>
   <Tabs className="tab-content">
    <TabList>
    <ul className="nav nav-tabs">
    <Tab><li><a >{language.singleResturentpage.menu}</a></li></Tab>
    <Tab><li><a >{language.singleResturentpage.overview}</a></li></Tab>
    {/* <Tab><li><a >Reviews</a></li></Tab> */}

      </ul>
    </TabList>
    <TabPanel>  
      <div className="row" id="menu">

      {this.state.menus && this.state.menus.length>0 ? this.state.menus.map((p, x) => {
return(
<div className="col-6 col-md-4 col-lg-3 mb-4" id={p.key} data-div_menu={p.menu} >
   <div className="item">
       <Link to={'/Singledish/'+p.key}>
            <div className="imgdiv">
               <img src={imagedir.url+p.image} alt="pro" className="w-100"/>
            </div>
            <div className="protest pt-1 ">
               <p>{p.name}</p>
              <div className="diffprice"><span>{"€ "+Number(p.price).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></div>
            </div>
         </Link>
            {localStorage.getItem("userid") && localStorage.getItem("userid")!== null ? <ul className="up1">
               <li onClick={this.likefood.bind(this)} id={p.key} data-div_name={p.name} data-div_roll={this.state.likes.findIndex((obj => obj.menu_id == p.key))!== -1 ? "true" : "false"} data-div_image={p.image}>{this.state.likes.findIndex((obj => obj.menu_id == p.key))!== -1 ? <i class="fas fa-heart"></i>:<i className="far fa-heart"></i> }</li>
            </ul>:""}
         </div>
</div>
      )}):""}

   </div>
   </TabPanel>

    <TabPanel>
    <div >
      <h3><span>{language.singleResturentpage.about}</span> {language.singleResturentpage.resturent} </h3>
      <div dangerouslySetInnerHTML={this.createMarkup2()}  />
      <div className="row">
         <div className="col-sm-8">
            <ul>
               <li>
                  <img src="../../images/call.png" alt="icon"/> 
                  <a href="tel:1457489456245">
                     {this.state.details ? this.state.details.phone_no:""}</a>
               </li>
               <li><img src="../../images/icon2.png" alt="icon"/> <a href="mailto:Info.Osteria Francescana@gmail.com">{this.state.details ? this.state.details.restaurant_email :""}</a></li>
               <li><img src="../../images/icon3.png" alt="icon"/> {this.state.details ? this.state.details.address:""}
               </li>
               {/* <li><img src="../../images/icon4.png" alt="icon"/> {this.state.details ? this.state.details.timings:""}</li> */}
            </ul>
         
         <div className="col-sm-4">
            {/* <a className="eatmenu" data-toggle="tab" href="#menu"><img src="../../images/eat.png" alt="icon"/> Menu</a> */}
         </div>
      </div>
   </div>
  

   </div>
    </TabPanel>
    {/* <TabPanel>
    <div className="reviews">
      <h3><span>Our</span> Reviews</h3>
    

      { this.state.reviewlist.length>0 ? this.state.reviewlist.map((j, x) => {
   return( 
      <div className="test">
         <img src="../../images/user1.jpg" alt="user"/>
         <h4><b>{j.name}</b> <span>{this.getdateformat(j.date)}</span></h4>
         <p>{j.comment}</p>
         <ul>
            <li><i className="fas fa-thumbs-up"></i> {j.like>0 ? j.like : ""}</li>
            <li><i className="fas fa-thumbs-down"></i>{j.dislike>0 ? j.dislike : ""}</li>
         </ul>
      </div>)}):<span>Empty Reviews</span>}
    
    
    
   </div>
    </TabPanel> */}

   
  </Tabs>

  </div>
</section>


{/* <!---------------- popular_dishes2 -----------------------> */}
{ this.state.recomended.length>0 ? 
<section className={this.fetchmainclass2()}>
   <div className="container">
      <h2><span>{language.singledish.recomended}</span> {language.singledish.for_you}</h2>
      <OwlCarousel {...this.state.options3} items={4} className="owl-carousel popular_dishes2_carousel owl.carousel.min owl-theme mt-4" loop margin={10} nav>
      { this.state.recomended.length>0 ? this.state.recomended.map((j, x) => {
   return( <div className="item">
            <div className="imgdiv2">
            <span onClick={this.gosingledish.bind(this)} id={j.key} >
               <img src={imagedir.url+j.image} alt="pro"/>
               </span>
               {localStorage.getItem("userid") && localStorage.getItem("userid")!== null ? <ul className="butm">
               <li onClick={this.likefood.bind(this)} id={j.key} data-div_name={j.name} data-div_roll={this.state.likes.findIndex((obj => obj.menu_id == j.key))!== -1 ? "true" : "false"} data-div_image={j.image}>{this.state.likes.findIndex((obj => obj.menu_id == j.key))!== -1 ? <i class="fas fa-heart"></i>:<i className="far fa-heart"></i> }</li>
            </ul>:""}
            </div>
            <div className="protest pt-1 ">
               <p>{j.name}</p>
               <h4>{"€ "+Number(j.price).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</h4>
            </div>
         </div>)}):<span>{language.singledish.empty_related_dishes}</span>}
       </OwlCarousel>
   </div>
</section>
:""}

{/* <div className="modal fade" id="comment" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">

      <div className="modal-body comment">
         <h5 className="modal-title" id="exampleModalLongTitle">Comment</h5>
        <span type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </span>
        <div>
         <textarea onChange={this.getcomment.bind(this)} value={this.state.comment} placeholder="Comments...."></textarea>
<button type="button" onClick={this.gocomment.bind(this)} data-dismiss="modal" className="btn btn-primary">Submit</button>
        </div>
      </div>
    </div>
  </div>
</div> */}



</div>
)
}
}
export default Singlepage;