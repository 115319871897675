import React, { Component } from 'react';
// import { Link  } from "react-router-dom";
import firebase from 'firebase/app';
import 'firebase/firestore';
import fetchgoodwords from '../API/fetchgoodwords.js';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from '../Header/index.js';
import language from '../API/language.json';
import imagedir from '../API/imagedir.json';

const db = firebase.firestore();

class Policy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            url: '',
            goodwords: [],
            options3: {
                center: true,
                items: 2,
                loop: true,
                autoplay: false,
                autoplayTimeout: 5000,
                margin: 10,
                responsiveClass: true,
                responsive: {
                    0: { items: 1, nav: true },
                    576: { items: 1, nav: true },
                    768: { items: 2, nav: true },
                    992: { items: 3, nav: true },
                    1200: { items: 3, nav: true, margin: 30 },
                },
            },
        };
    }

    componentDidMount = async () => {
        db.collection('privacy')
            .doc('RRzqilR9XFlEAMGdCEvCPrivacy')
            .get()
            .then((docRef) => {
                var data = docRef.data();
                this.setState({
                    title: data.title,
                    description: data.description,
                });
            });
        var gooodwords = await fetchgoodwords();
        if (gooodwords !== '' && gooodwords !== null && gooodwords.length > 0) {
            this.setState({ goodwords: gooodwords });
        }
    };

    getdata(data) {
        return { __html: data };
    }
    gatdata() {
        return { __html: this.state.description };
    }

    render() {
        return (
            <>
                <Header />
                <div class='headingin'>
                    <h2>
                        <span>{language.footerpages.privacy}</span>{' '}
                        {language.footerpages.policy}
                    </h2>
                    <h1>
                        <span>{language.footerpages.privacy}</span>{' '}
                        {language.footerpages.policy}
                    </h1>
                </div>
                <section class='aboutus'>
                    <div class='container'>
                        <div class='row'>
                            <span dangerouslySetInnerHTML={this.gatdata()} />
                        </div>
                    </div>
                </section>

                <section class='client'>
                    <div class='container-flude'>
                        <h2>{language.footerpages.good_words}</h2>

                        {this.state.goodwords.length > 0 ? (
                            <OwlCarousel
                                {...this.state.options3}
                                center={true}
                                items={3}
                                className='owl-carousel owl-carousel_testi owl-theme'
                                loop
                                margin={10}
                                nav
                            >
                                {this.state.goodwords.map((j, x) => {
                                    return (
                                        <div class='item'>
                                            <div class='usr'>
                                                <img
                                                    class='user'
                                                    src={imagedir.url + j.image}
                                                    alt='img'
                                                />
                                                <h4>{j.name}</h4>
                                            </div>
                                            <p
                                                dangerouslySetInnerHTML={this.getdata(
                                                    j.description
                                                )}
                                            ></p>
                                        </div>
                                    );
                                })}
                            </OwlCarousel>
                        ) : (
                            ''
                        )}
                    </div>
                </section>
            </>
        );
    }
}
export default Policy;
