import firebase from "firebase/app";
import 'firebase/firestore';
// import firestore from "./firestore";
export default async function AddtoCart(object) {

    var response2 =  await firebase.firestore().collection("Cart").where('menu_id', '==', object.menu_id).where('user_id', '==', localStorage.getItem("sessionId")).get();
    const data2 = await response2.docs.map(doc => doc.id);
    const data = await response2.docs.map(doc => doc.data());
    var objectc={dish_name:object.dish_name,image:object.image,actual_price:object.actual_price,
    price:object.price,menu_id:object.menu_id,quantity:object.quantity,ingrident:object.ingrident,allergeni:object.allergeni,category:object.category,
    peso:object.peso,resturent_name:object.resturent_name,resturent_id:object.resturent_id,user_id:localStorage.getItem("sessionId"),confirm_by_restu:"pending"};
    if(data2.length>0)
    {
          const userRef = await firebase.firestore().collection('Cart').doc(data2[0]).update({quantity:data[0].quantity+1,price:Number(data[0].price)+Number(data[0].actual_price)});
          return 'data3';
    }
    else{
      var response23 =  await firebase.firestore().collection("Cart").where('user_id', '==', localStorage.getItem("sessionId")).get();
      const datapercend = await response23.docs.map(doc => doc.data());
      if(datapercend.length>0)
      {
        if(datapercend[0].resturent_id==object.resturent_id)
        {
      let userRef2 = await firebase.firestore().collection('Cart').add(objectc);
      return 'data4';
        }
        else{
          return datapercend;
        }
      }
      else{
        let userRef2 = await firebase.firestore().collection('Cart').add(objectc);
      return 'data4';
      }  
}
  }
  
