import firebase from 'firebase/app';
// import firestore from "./firestore";
import 'firebase/firestore';
import 'firebase/auth';
import axios from 'axios';
export default async function Registerapi(
    firstname,
    lastname,
    email,
    password,
    number
) {
    const authusers = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
    const userRef = await firebase
        .firestore()
        .collection('Users')
        .doc(authusers.user.uid)
        .set({
            first_name: firstname,
            last_name: lastname,
            email: email,
            phone: number,
            created_date: Date.now(),
            uid: authusers.user.uid,
        });
    // localStorage.setItem('phone',number);
    // localStorage.setItem('userid',authusers.user.uid);
    // localStorage.setItem('resturent',"empty");
    // localStorage.setItem('firstname',firstname);
    // localStorage.setItem('lastname',lastname);
    var bodys = {
        fullname: firstname + ' ' + lastname,
        email: email,
        password: password,
        country_code: 'IT',
        phone: number,
    };
    await axios.post('https://foodapi.ucooking.it/register_user', bodys, {
        headers: {
            Accept: 'multipart/form-data',
            'Content-Type': 'multipart/form-data',
        },
    });
    await authusers.user.sendEmailVerification({
        url: 'https://fooddelivery-cae0d.web.app',
    });
    localStorage.setItem('mainsend', 'email');
}
