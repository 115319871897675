import firebase from "firebase/app";
import 'firebase/firestore';
// import firestore from "./firestore";
export default async function fetchgoodwords () {
               try {
                   var response =  await firebase.firestore().collection("goodwords").get();
                   const userData = await response.docs.map(doc => doc.data());
               return await userData;
              } catch {
           return 'error';
              }
}