import React, { Component } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import FetchSelectedResturent from '../API/FetchSelectedResturent.js';
import FetchLikes from '../API/FetchLikes.js';
import LikedDish from '../API/LikedDish.js';
import addIp from '../API/addIp.js';
import Header from '../Header/index.js';
import imagedir from '../API/imagedir.json';
// import firebase from 'firebase/app';
import axios from 'axios';
import language from '../API/language.json';


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dishs: [], resturent: [], ids: [], likecomand: false, Latitude: "", Longitude: "", likes: [], locationdata: [], loader: true, options3: {

                autoplay: false,
                autoplayTimeout: 5000,
                margin: 15,
                responsiveClass: true,
                responsive: {
                    0: { items: 1 },
                    576: { items: 2 },
                    768: { items: 3 },
                    992: { items: 3 },
                    1200: { items: 4 },
                    1600: { items: 4 },
                    1800: { items: 4, margin: 12 }
                }
            }
        }

    }
    // componentDidUpdate()
    // {
    //    console.log('add in update');
    //    if(localStorage.getItem('mongo') && localStorage.getItem('mongo')!==null && localStorage.getItem('mongo')=="5")
    //    {
    //          this.componfuncion();
    //    }

    // }
    componentDidMount = async () => {
        //          var oss="[{ 'end': '0.783291','start': '0','text': 'शेर' },{ 'end': '1.457792','start': '1.457792','text': 'जंगल' },{ 'end': '1.754245','start': '1.754245','text': 'का' },{ 'end': '2.241083','start': '2.241083','text': 'राजा' },{ 'end': '2.491301','start': '2.491301','text': 'होता' },{ 'end': '2.866627','start': '2.866627','text': 'है' },{ 'end': '3.747830','start': '3.747830','text': 'एक' },{ 'end': '4.270024','start': '4.270024','text': 'बार' },{ 'end': '5.053315','start': '5.053315','text': 'एक' },{ 'end': '5.504795','start': '5.504795','text': 'शेर' },{ 'end': '6.021550','start': '6.021550','text': 'सो' },{ 'end': '6.309844','start': '6.309844','text': 'रहा' },{ 'end': '6.745006','start': '6.745006','text': 'था' },{ 'end': '7.680603','start': '7.680603','text': 'तभी' },{ 'end': '8.431257','start': '8.431257','text': 'एक' },{ 'end': '8.948012','start': '8.948012','text': 'छोटा' },{ 'end': '9.546359','start': '9.546359','text': 'चूहा' },{ 'end': '10.378606','start': '10.378606','text': 'उसके' },{ 'end': '11.074864','start': '11.074864','text': 'ऊपर' },{ 'end': '11.531784','start': '11.531784','text': 'और' },{ 'end': '11.830958','start': '11.830958','text': 'नीचे' },{ 'end': '12.287878','start': '12.287878','text': 'दौड़ने' },{ 'end': '12.826390','start': '12.826390','text': 'लगा' },]";
        //          var obh = "[{ 'end': '0.783291','start': '0','text': 'शेर' },{ 'end': '1.457792','start': '1.457792','text': 'जंगल' },{ 'end': '1.754245','start': '1.754245','text': 'का' },{ 'end': '2.241083','start': '2.241083','text': 'राजा' },{ 'end': '2.491301','start': '2.491301','text': 'होता' },{ 'end': '2.866627','start': '2.866627','text': 'है' },{ 'end': '3.747830','start': '3.747830','text': 'एक' },{ 'end': '4.270024','start': '4.270024','text': 'बार' },{ 'end': '5.053315','start': '5.053315','text': 'एक' },{ 'end': '5.504795','start': '5.504795','text': 'शेर' },{ 'end': '6.021550','start': '6.021550','text': 'सो' },{ 'end': '6.309844','start': '6.309844','text': 'रहा' },{ 'end': '6.745006','start': '6.745006','text': 'था' },{ 'end': '7.680603','start': '7.680603','text': 'तभी' },{ 'end': '8.431257','start': '8.431257','text': 'एक' },{ 'end': '8.948012','start': '8.948012','text': 'छोटा' },{ 'end': '9.546359','start': '9.546359','text': 'चूहा' },{ 'end': '10.378606','start': '10.378606','text': 'उसके' },{ 'end': '11.074864','start': '11.074864','text': 'ऊपर' },{ 'end': '11.531784','start': '11.531784','text': 'और' },{ 'end': '11.830958','start': '11.830958','text': 'नीचे' },{ 'end': '12.287878','start': '12.287878','text': 'दौड़ने' },{ 'end': '12.826390','start': '12.826390','text': 'लगा' },]";
        // console.log('array length',Object.assign({}, oss).length);
        // var res= await firebase.firestore().collection('locations').get();
        // var drs=await res.docs.map(doc => doc.data());
        // if(drs && drs.length>0)
        // {
        //    this.setState({locationdata:drs});
        // }
        var response3 = await axios.post('https://foodapi.ucooking.it/location', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        const movies = await response3.data;
        this.setState({ locationdata: [...movies.reduce((map, obj) => map.set(obj.city, obj), new Map()).values()] });
        localStorage.setItem('mongo', "0");
        this.componfuncion();
        $.getJSON("https://jsonip.com?callback=?", function (data) {
            this.setting(data);
        }.bind(this));

    }
    componfuncion = async () => {

        localStorage.setItem('mongo', "0");
        if (localStorage.getItem('lat') && localStorage.getItem('lat') !== null && localStorage.getItem('long') && localStorage.getItem('long') !== null) {
            if (this.state.likecomand == false) {
                this.setState({ loader: true });
            }
            var dish = await FetchSelectedResturent();
            if (dish !== 'error' && dish.dishs.length > 0) {
                this.setState({ resturent: dish.resturent, ids: dish.idst });
                var title = []
                var i;
                for (var i = 0; i < dish.dishs.length; i++) {
                    for (let index2 = 0; index2 < dish.dishs[i].length; index2++) {
                        title.push({
                            //featured_image: imagedir.url + dish.dishs[i][index2].featured_image,

                            featured_image: dish.dishs[i][index2].featured_image,
                            dish_name: dish.dishs[i][index2].dish_name,
                            resturent_id: dish.dishs[i][index2].resturent_id,
                            resturent_name: dish.dishs[i][index2].resturent_name,
                            key: dish.idst[i][index2],
                            count: dish.dishs[i][index2].number ? dish.dishs[i][index2].number : 0,
                        });
                    }
                }
                this.setState({ dishs: title });
            }
            localStorage.setItem('mongo', "0");
            if (localStorage.getItem("userid") && localStorage.getItem("userid") !== null) {
                var likesdata = await FetchLikes();
                if (likesdata.length > 0 && likesdata !== "empty") {
                    this.setState({ likes: likesdata }, () => {


                        if (this.state.likes.length < 4) {
                            var counter = 4 - this.state.likes.length;
                            for (let index = 0; index < counter; index++) {
                                this.state.likes.push({ menus_id: "", image: "", name: "", resturent_name: "" });
                            }
                        }
                        this.setState({ likes: this.state.likes });
                    });
                }

                localStorage.setItem("sessionId", localStorage.getItem("userid"));
            }
            if (localStorage.getItem("sessionId") && localStorage.getItem("sessionId") == null) {
                localStorage.setItem("sessionId", Date.now());
            }
            this.setState({ loader: false, likecomand: false });
        }

    }
    gotorestu(e) {
        this.props.history.push('/Singlepage/' + e.currentTarget.id)
    }

    likefood = async (e) => {
        var Obj = {
            'image': e.currentTarget.dataset.div_image, 'name': e.currentTarget.dataset.div_name,
            'menu_id': e.currentTarget.id, 'resturent_id': e.currentTarget.dataset.div_restid, 'resturent_name': e.currentTarget.dataset.div_call
        };
        if (e.currentTarget.dataset.div_roll == "true") {
            var objIndex = this.state.likes.findIndex((obj => obj.menu_id == e.currentTarget.id));
            if (objIndex !== -1) {

                this.state.likes.splice(objIndex, 1);
                this.setState({ likes: this.state.likes });
            }
        }
        else {
            this.state.likes.push(Obj);
            this.setState({ likes: this.state.likes });
        }
        var data = await LikedDish(Obj, e.currentTarget.dataset.div_roll);
        this.setState({ likecomand: true }, () => {
            this.getlikes();
        })

    }
    getlikes = async () => {
        var likesdata = await FetchLikes();
        if (likesdata.length > 0 && likesdata !== "empty") {
            this.setState({ likes: likesdata }, () => {


                if (this.state.likes.length < 4) {
                    var counter = 4 - this.state.likes.length;
                    for (let index = 0; index < counter; index++) {
                        this.state.likes.push({ menus_id: "", image: "", name: "", resturent_name: "" });
                    }
                }
                this.setState({ likes: this.state.likes });
            });
        }
    }
    setting = async (data) => {
        var storeip = await addIp(data.ip);
    }

    fetchloader() {
        if (this.state.loader == true) {
            return "loaderclass singleloder displayblock";
        }
        else {
            return "loaderclass singleloder";
        }
    }
    onLocaion(e) {
        localStorage.setItem('lat', e.currentTarget.dataset.div_lat);
        localStorage.setItem('long', e.currentTarget.dataset.div_long);
        localStorage.setItem('addressm', e.currentTarget.dataset.div_name);
        window.location.reload();
    }

    render() {
        return (<div><Header />
            {localStorage.getItem('lat') == null ? <div className="showpop"><div className="childpop"><p>Please select a location</p>   {this.state.locationdata.length > 0 ? this.state.locationdata.map((j, x) => {
                return (<a onClick={this.onLocaion.bind(this)} data-div_lat={j.lat} data-div_long={j.long} data-div_name={j.city} className="btn">{j.city}</a>)
            }) : ""}</div></div> : <div className={this.fetchloader()}>
                <img
                    src="../../images/tiger.gif"
                    alt="loading"
                />
            </div>}


            {this.state.likes.length > 0 ?
                <section className="popular_dishes">
                    <div className="container">
                        <div className="heading">
                            <h2><span>{language.homepage.your}</span> {language.homepage.favourites}</h2>
                        </div>
                        <OwlCarousel  {...this.state.options3} touchDrag={this.state.likes.findIndex((obj => obj.image == "")) == -1 ? true : false}
                            mouseDrag={this.state.likes.findIndex((obj => obj.image == "")) == -1 ? true : false} className='owl-carousel popular_dishes_carousel owl.carousel.min owl-theme mt-4' loop={this.state.likes.findIndex((obj => obj.image == "")) == -1 ? true : false} nav={this.state.likes.findIndex((obj => obj.image == "")) == -1 ? true : false} >
                            {/* <div className="owl-carousel popular_dishes_carousel owl.carousel.min owl-theme mt-4"> */}
                            {this.state.likes.length > 0 ? this.state.likes.map((j, x) => {

                                return (
                                    <div className="item">
                                        <div className="imgdiv">
                                            <Link to={"/Singledish/" + j.menu_id}>
                                                <img src={j.image} className="w-100" />
                                            </Link>
                                        </div>
                                        <div className="protest pt-1 ">
                                            <p>{j.name}</p>
                                            <h4 id={j.resturent_id} onClick={this.gotorestu.bind(this)} >{j.resturent_name}</h4>
                                        </div>
                                        {localStorage.getItem("userid") && localStorage.getItem("userid") !== null && j.image !== "" ? <ul className="up1">
                                            <li onClick={this.likefood.bind(this)} data-div_restid={j.resturent_id} data-div_call={j.resturent_name} id={j.menu_id} data-div_name={j.name} data-div_roll={"true"} data-div_image={j.image}> <i class="fas fa-heart"></i></li>
                                        </ul> : ""}
                                    </div>)
                            }) : ""}

                            {/* </div> */}
                        </OwlCarousel>
                    </div>
                </section> : ""}

            {this.state.resturent.length > 0 ? this.state.resturent.map((m, p) => {
                return (
                    <span>{this.state.dishs.findIndex((obj => obj.resturent_id == m.resturent_id)) !== -1 ?
                        <section className="favourites">
                            <div className="container">
                                <div className="heading mt-0" id={m.resturent_id} onClick={this.gotorestu.bind(this)}>
                                    <h2><span>{language.homepage.restaurant}</span>{" " + m.name}</h2>
                                    <h3>{m.title}</h3>
                                </div>
                                <OwlCarousel items={4} {...this.state.options3} className='owl-carousel popular_dishes_carousel owl.carousel.min owl-theme mt-4' loop={this.state.dishs.length >= 4 ? true : false} nav>
                                    {this.state.dishs.length > 0 ? this.state.dishs.sort((a, b) => (a.count > b.count) ? 1 : -1).map((j, x) => {
                                        return (<>{m.resturent_id == j.resturent_id ? <div className="item">
                                            <div className="test" style={{ backgroundImage: 'url(' + j.featured_image + ')' }}>
                                                {/* <!--     <span><i className="fas fa-shopping-cart"></i></span> --> */}
                                                <h5>{j.dish_name}</h5>
                                                <Link to={"/Singledish/" + j.key}>View Items</Link>
                                                {localStorage.getItem("userid") && localStorage.getItem("userid") !== null ? <ul className="up1">
                                                    <li onClick={this.likefood.bind(this)} data-div_restid={this.state.dishs[0].resturent_id} data-div_call={j.resturent_name} id={j.key} data-div_name={j.dish_name} data-div_roll={this.state.likes.findIndex((obj => obj.menu_id == j.key)) !== -1 ? "true" : "false"} data-div_image={j.featured_image}>{this.state.likes.findIndex((obj => obj.menu_id == j.key)) !== -1 ? <i class="fas fa-heart"></i> : <i className="far fa-heart"></i>}</li>
                                                </ul> : ""}
                                            </div>
                                        </div> : null}</>)
                                    }) : ""}



                                </OwlCarousel>
                            </div>
                        </section> : ""}</span>)
            }) : ""}




            {/* <!---------------- Top Rated Restaurants  -----------------------> */}
            {this.state.resturent.length > 999 ?

                <section className="popular_dishes popular_dishes2">
                    <div className="container">
                        <h2><span>{language.homepage.top_rated}</span> {language.homepage.restaurant}</h2>
                        <OwlCarousel items={4} {...this.state.options3} className='owl-carousel popular_dishes_carousel owl.carousel.min owl-theme mt-4' loop={this.state.resturent.length >= 4 ? true : false} nav>

                            {this.state.resturent.map((l, x) => {
                                return (<div className="item">
                                    <div className="imgdiv2">
                                        <a id={l.uid} onClick={this.gotorestu.bind(this)}>
                                            <img src={imagedir.url + l.featured_images} alt="pro" />
                                        </a>
                                    </div>
                                    <div className="protest pt-1 text-center ">
                                        <p>{l.name}</p>
                                    </div>
                                </div>)
                            })}




                        </OwlCarousel>
                    </div>
                </section> : ""}
        </div>

        )
    }
}
export default Home;