import firebase from "firebase/app";
import 'firebase/firestore';
// import firestore from "./firestore";
export default async function LikedDish(object,check) {
  try{
    var response =  await firebase.firestore().collection("Favourites")
.where('user_id', '==', localStorage.getItem("userid")).where('menu_id', '==', object.menu_id).get();
const data4 = await response.docs.map(doc => doc.id);
      if(check=="false")
      {
        if(data4.length>0)
        {
         const userRef1 = await firebase.firestore().collection('Favourites').doc(data4[0]).set({name:object.name,image:object.image,
            menu_id:object.menu_id,user_id:localStorage.getItem('userid'),'resturent_id':object.resturent_id,'resturent_name':object.resturent_name
        });
        const data5 = await userRef1.docs.map(doc => doc.id);
    return data5;
        }
        else{
          const userRef2 = await firebase.firestore().collection('Favourites').add({name:object.name,image:object.image,
            menu_id:object.menu_id,user_id:localStorage.getItem('userid'),'resturent_id':object.resturent_id,'resturent_name':object.resturent_name
        });
        const data5 = await userRef2.docs.map(doc => doc.id);
    return data5;
        }
  
    
}
else{
if(data4.length>0)
{
        var error = await firebase.firestore().collection('Favourites').doc(data4[0]).delete();
    return await error;
}
}
  }
  catch(e){
    return "error"
  }
}