import firebase from "firebase/app";
import 'firebase/firestore';
// import firestore from "./firestore";
// import axios from "axios";
export default async function Fetchrestu (id) {
               try {
                // const documentSnapshot = await firebase.firestore().collection('restaurant')
                //   .doc(id)
                //   .get();
                  var documentSnapshot =  await firebase.firestore().collection("restaurant").where('resturent_id', '==', id).get();
                const userData = await documentSnapshot.docs.map(doc => doc.data());
                //  const userData = await documentSnapshot.data();
               return await userData;
              } catch {
           return 'error';
              }
}