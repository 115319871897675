
import React, { Component } from 'react';
import {  BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { createBrowserHistory } from 'history';
import Footer from './component/Footer';
import Header from './component/Header';
import Home from './component/Home';
import Restaurants from './component/Restaurants';
import Singledish from './component/Singledish';
import Singlepage from './component/Singlepage';
import Cart from './component/Cart';
import Checkout from './component/Checkout';
import Aboutus from './component/Aboutus';
import Policy from './component/Policy';
import Terms from './component/Terms';
import Contact from './component/contact';
import News from './component/News';
import Myprofile from './component/Myprofile';
import Myorder from './component/Myorder';
import Myfavorite from './component/Myfavorite';
import ChnagePassword from './component/ChnagePassword';
import delivery_address from './component/delivery_address';
import xpaysubmit from './component/xpaysubmit';
import paypalrs from './component/paypalrs';
function App() {

  return (
    <Router history={createBrowserHistory}>
      <div className="App">
      {/* <Route  component={Restaurants} exact path="/Restaurants" /> */} 
        <Switch>
        <Route component={Home} exact path="/" />
        <Route exact path="/Singlepage/:id" component={Singlepage} />
        <Route component={Cart} exact path="/Cart"/>
        <Route component={Myprofile} exact path="/Myprofile"/> 
        <Route component={Checkout} exact path="/Checkout"/> 
        <Route component={Aboutus} exact path="/Aboutus"/>
        <Route component={Myorder} exact path="/Myorder"/>
        <Route component={delivery_address} exact path="/delivery_address"/>
        <Route component={Myfavorite} exact path="/Myfavorite"/>
        <Route component={ChnagePassword} exact path="/ChnagePassword"/>
        <Route exact path="/Singledish/:id" component={Singledish} />
        <Route component={Policy} exact path="/Policy"/>
        <Route component={Terms} exact path="/Terms"/> 
        <Route component={News} exact path="/News"/> 
        <Route component={Contact} exact path="/contact"/>
       
        </Switch>
        {window.location.href.split('?').length == 2 ? "" : <Footer/> }
      </div>
      <Route component={xpaysubmit} exact path="/xpaysubmit"/>
      <Route component={paypalrs} exact path="/paypalrs"/> 
 
    </Router>
  );
}

export default App;
