import React, { Component } from 'react';
import { Link  } from "react-router-dom";
import language from'../API/language.json';
class Footer extends  React.Component {
    constructor(props) {
       super(props);
       this.state={baseUrl:"https://timesort.com",recomended:[],number:false
      }

      }
      render()
      {
          return(<div>
              {/* <!----------------------  main_footer ---------------------> */}
<footer className="main-footer">
   <div className="container">
      <div className="row">
         <div className="col-md-5 col-lg-4">
            <Link to={'/'}>
            <img className="wow zoomIn logof" data-wow-delay=".6s" src="../images/logo.png" alt="logo"/></Link>
            <p className="mt-4 w-75">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since .the 1500s, </p>
            <h6><img src="../images/appstore.png" alt="icon"/><img src="../images/googleplay.png" alt="icon"/></h6>
         </div>
         <div className="col-md-7 col-lg-5 menu">
            <div className="row">
               <div className="col-md-6">
                  <h3>{language.footer.site_links}</h3>
                  <ul>
                     <li><Link to={'/'}>{language.footer.home}</Link></li>
                     <li><Link to={'/Aboutus'}>{language.footer.about}</Link></li>
                     <li><Link to={'/News'}>{language.footer.news}</Link></li>
                     <li><Link to={'/contact'}>{language.footer.contact_us}</Link></li>
                     <li><Link to={'/Terms'}>{language.footer.terms_of_service}</Link></li>
                     <li><Link to={'/Policy'}>{language.footer.privacy_policy}</Link></li>
                     
                  </ul>
               </div>
               <div className="col-md-6">
                  <h3>{language.footer.social_links}</h3>
                  <ul>
                     <li><a href="facebook.com">{language.footer.facebook}</a></li>
                     <li><a href="https://twitter.com/">{language.footer.twitter}</a></li>
                     <li><a href="https://www.instagram.com/">{language.footer.instagram}</a></li>
                     <li><a href="https://www.linkedin.com/">{language.footer.linkedin}</a></li>
                  </ul>
               </div>
            </div>
         </div>
         <div className="col-lg-3 social ">
            <h3>{language.footer.sign_up}</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy .</p>
            <form>
               <input className="w-100" type="text" name="email" placeholder="Enter your email here "/>
               <button type="submit" value=""><span>{language.footer.getstart}</span> <i className="fas fa-arrow-circle-right"></i></button>
            </form>
         </div>
      </div>
   </div>
</footer>
<p className="text-center lastp">{language.footer.copyright}</p>



          </div>)
      }
      }

      export default Footer;


