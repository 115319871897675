import firebase from "firebase/app";
import 'firebase/firestore';
// import firestore from "./firestore";
export default async function Fetchreleted (id) {
               try {
                const documentSnapshot = await firebase.firestore().collection('menus')
                  .doc(id)
                  .get();
                const userData = await documentSnapshot.data();
                if(userData.related && userData.related.length>0)
                {
                   var reco=[];
                   var idsr=[];
                  for (let index = 0; index < userData.related.length; index++) {
                    const documentSnapshot2 = await firebase.firestore().collection('menus')
                    .doc(userData.related[index].key)
                    .get();
                  const userData2 = await documentSnapshot2.data();
                  const ids=await documentSnapshot2.id;
                   reco.push(userData2);
                   idsr.push(ids);
                  }
                  var object={'data':reco,'ids':idsr};
                  return await object;
                }
                else{
                  
                  return await "empty";
                }
                
              
              } catch {
           return 'error';
              }
}