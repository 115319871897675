
import React, { Component } from 'react';
import {  BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Login from './components/Login';
class Restaurants extends  React.Component {
    constructor(props) {
       super(props);
       this.state={email:"",pass:"",firstname:"",logemail:"",logpass:"",lastname:"",passtype2:"password",setemail:"",setpassword:"",number:"",passtype:"password"
      }
      }
      render()
      {
  return (
    <Router>
     <Switch>
    <Route  component={Login} exact path="/Login" />
    </Switch>
        </Router>
       
  );
}

}
export default Restaurants;
