import React, { Component } from 'react';
import { Link, Redirect  } from "react-router-dom";
import $, { param } from 'jquery';
import firebase from 'firebase/app';
import language from'../API/language.json';
import Header from '../Header/index.js';
class Myorder extends  React.Component {
    constructor(props) {
       super(props);
     
       this.state={imageVideoUrl:"",passo:"",passn:"",loader:false,phone:localStorage.getItem("phone") && localStorage.getItem("phone")!=="null" ? localStorage.getItem("phone") :"",web:localStorage.getItem("web") && localStorage.getItem("web")!=="null" ? localStorage.getItem("web") :"",lastname:localStorage.getItem("lastname"),firstname:localStorage.getItem("firstname"),city:localStorage.getItem("city") && localStorage.getItem("city")!=="null" ? localStorage.getItem("city") :"",fullname:localStorage.getItem("firstname")+" "+localStorage.getItem("lastname"),userImage:"",date:Date.now(),time:Date.now(),loader:"false"   
      }
      }
changepassword()
{
   if(this.state.passn=="" || this.state.passn.length<5 && this.state.passo=="" || this.state.passo.length<5)
   {
      this.setState({error:"Please enter minimum 6 digit of new passwords"},()=>
      {
         $('.errorpop2').addClass('displayblock');
        
      })
    
   }
   else{
      this.setState({loader:true});
      this.reauthenticate(this.state.passo).then(() => {
        var user = firebase.auth().currentUser;
        user.updatePassword(this.state.passn).then(() => {
         this.setState({loader:false,passo:"",passn:"",error:"Password update successfully"},()=>
         {
            $('.errorpop2').addClass('displayblock');
         })
        }).catch((error) => { 
         this.setState({loader:false,error:error.message},()=>
         {
            $('.errorpop2').addClass('displayblock');
         })
         });
      }).catch((error) => {   this.setState({loader:false,error:error.message},()=>
      {
         $('.errorpop2').addClass('displayblock');
      })});
   } 
}
reauthenticate = (passo) => {
   var user = firebase.auth().currentUser;
   var cred = firebase.auth.EmailAuthProvider.credential(
       localStorage.getItem('email'), passo);
   return user.reauthenticateWithCredential(cred);
 }
 removepop(){
    $('.errorpop2').removeClass('displayblock');
 }
firstpass(e)
{
    this.setState({passo:e.target.value});
}
secondpass(e)
{
    this.setState({passn:e.target.value});
}
      render()
      {
return(
    <><Header/>
<section class="profile_section">
<div className="op errorpop2 invalid_login">
      <div className="errorbtn2 error h-100"></div>
      <ul>
         <li>{this.state.error}</li>
         <button className="upbtn errorbtn2" onClick={this.removepop.bind(this)}  >{language.Checkoutpage.ok}</button>
      </ul>
   </div>
   {/* <div class="pro_profile">
      <div class="container">
         <div class="profilehead">
            <span>
            <img src={localStorage.getItem('image') && localStorage.getItem('image')!==null ? localStorage.getItem('image') : "images/empty.webp"} alt="img"/>
            </span>
            <h5>{this.state.fullname}</h5>
            <p><a href="#"><i class="fab fa-facebook-f"></i></a>
               <a href="#"><i class="fab fa-linkedin-in"></i></a>
               <a href="#"><i class="fab fa-instagram"></i></a>
               <a href="#"><i class="fab fa-twitter"></i></a>
            </p>
         </div>
     
      
      </div>
   </div> */}

   <div class="container">
      <div class="profile_in">
         <div class="profile_sider">
            <h4>{language.profilePages.ACTIVITY}</h4>
            <ul>
            <li><Link to={'/Myprofile'} class="reviews"> {language.profilePages.my_profile}</Link></li> 
             <li><Link to={'/delivery_address'}>{language.profilePages.delivery_address}</Link></li>
               <li><Link to={'/Myorder'}>{language.profilePages.order_history}</Link></li>
               <li><Link to={'/Myfavorite'}>{language.profilePages.favorite_dish}</Link></li>
               <li><Link to={'/ChnagePassword'}>{language.profilePages.change_password}</Link></li>
            </ul>
            {/* <h4>ONLINE ORDERING</h4>
            <ul>
               <li><a href="order_history.php">Order History</a></li>
               <li><a href="my_addresses.php">My addresses</a></li>
               <li><a href="favorite_orders.php">Favorite Orders</a></li>
            </ul>
            <h4>PAYMENTS</h4>
            <ul>
               <li><a href="ucooking_credits.php">Ucooking Credits</a></li>
               <li><a href="manage_wallets.php">Manage Wallets</a></li>
               <li><a href="manage_cards.php">Manage Cards</a></li>
            </ul>
            <h4>TABLE BOOKING</h4>
            <ul>
               <li><a href="#">Your Bookings</a></li>
            </ul> */}
         </div>
         
         <div class="reviews">
            <h3>{language.profilePages.change_password}</h3>

           
         
               <div class="form-group">
                  <label>{language.profilePages.current_password}</label>
                  <input type="text" class="form-control" onChange={this.firstpass.bind(this)} value={this.state.passo} placeholder={language.profilePages.enter_current_password}/>
               </div>
               <div class="form-group">
                  <label>{language.profilePages.new_password}</label>
                  <input type="text" class="form-control" onChange={this.secondpass.bind(this)} value={this.state.passn} placeholder={language.profilePages.enter_new_password}/>
               </div>
               <div class="form-group">
               <button className="btn" type="button" onClick={this.changepassword.bind(this)} id="changepassword">{this.state.loader==true ? language.profilePages.update+"..." : language.profilePages.update}</button>
               </div>
       
    
         </div>
      </div>
   </div>
</section>



</>
)}}
export default Myorder;
