import React, { Component } from 'react';
import { Link  } from "react-router-dom";
import $ from 'jquery';
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import Fetchdish from '../API/Fetchdish.js';
import Fetchreleted from '../API/Fetchreleted.js';
import AddtoCart from '../API/AddtoCart.js';
import matchDish from '../API/matchDish.js';
import LikedDish from '../API/LikedDish.js';
// import addRatting from '../API/addRatting.js';
// import FetchRattig from '../API/FetchRattig.js';
// import ReactStars from "react-rating-stars-component";
import ShareLink from 'react-facebook-share-link';
import firebase from 'firebase/app';
import 'firebase/firestore';
// import firestore from '../API/firestore.js';
// import NumberFormat from 'react-number-format';
import Header from '../Header/index.js';
import DeleteCart from '../API/DeleteCart.js';
import language from'../API/language.json';
import imagedir from'../API/imagedir.json';

class Singledish extends  React.Component {
    constructor(props) {
       super(props);
       let dishid = null;
       const path = this.props.location.pathname;
       const params = this.props.location.pathname.split('/');
       dishid = params[params.length-1];
       this.state={show:false,ratings:[],releted:[],cosa:[],popdish:[],zzwait:"false",model:"",likes:"false",ratting:0,error:"",restuDesc:"",resutName:"",dishid:dishid,details:{},category:[],Ingredient:[],allergeni:[],count:1,price:0,video:""     
      }

      }
      componentDidUpdate()
      {
         if(this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1]!=="" && this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1]!== undefined)
         {
            if(this.state.dishid!==this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1])
            {
              
               this.setState({dishid:this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length-1]},()=>
               {
                  this.componentDidMount();
               });
            } 
         }
      }
      closeclearpop()
      {
         $('#clearcart').removeClass('show displaynoneclasss');
      }
 
      clearCart=async()=>
      {
         $('#clearcart').removeClass('show displaynoneclasss');
         var delted = await DeleteCart();
      }
      componentDidMount= async ()=>
      {
         window.scrollTo(0, 0)
         if(localStorage.getItem("userid")!==null)
         {
            var match = await matchDish(this.state.dishid);
            this.setState({show:match});
            localStorage.setItem("sessionId",localStorage.getItem("userid"));
         }
         if(localStorage.getItem("sessionId")==null)
         {
         localStorage.setItem("sessionId",Date.now());
         }
       var dish = await Fetchdish(this.state.dishid);
       this.setState({cosa:dish.cosa ? dish.cosa:[],price:dish.price,price2:dish.price,details:dish,category:dish.category,Ingredient:dish.Ingredient,allergeni:dish.allergeni,video:dish.video ? dish.video:""});
      // var fetchrating = await FetchRattig(this.state.dishid);
      // this.setState({ratings:fetchrating});
      var responsive =  await firebase.firestore().collection("restaurant").where('resturent_id', '==', dish.resturent_id).get();
      const descriptions = await responsive.docs.map(doc => doc.data());
      this.setState({restuDesc:descriptions[0].description,resutName:descriptions[0].name});
      var likesd= await firebase.firestore().collection("Favourites").where('menu_id', '==', this.state.dishid).where('user_id', '==', localStorage.getItem("userid")).get();
      const likes = await likesd.docs.map(doc => doc.data());
      if(likes && likes.length>0)
      {
         this.setState({likes:"true"});
      }
      else{
         this.setState({likes:"false"});
      }
      var releted = await Fetchreleted(this.state.dishid);
      if(releted.data!==undefined && releted!=="empty" && releted.data.length>0 && releted.data.length>0)
       {
         var titled=[]
         for (var i = 0; i < releted.data.length; i++) {
         titled[i] = {
         image: releted.data[i].featured_image,
         name: releted.data[i].dish_name,
         price:releted.data[i].price,
         description:releted.data[i].description,
         key:releted.ids[i],
         };
         }
         this.setState({releted:titled});
      }
      }
      // ratingChanged(newRating)
      // {
      //    this.setState({ratting:newRating})
      // }
      // submitrating= async ()=>
      // {
      //    if(this.state.ratting==0)
      //    {
      //       this.setState({error:"Please select ratting"},()=>
      //       {
      //          $('.errorpop2').addClass('displayblock');
      //       });
      //    }
      //    else{
      //       var object={name:this.state.details.dish_name,price:this.state.price2,image:this.state.details.featured_image,date:Math.floor(new Date() / 1000),
      //          menu_id:this.state.dishid,resturent_id:this.state.details.resturent_id,user_id:localStorage.getItem("userid"),ratting:this.state.ratting,email:localStorage.getItem("email")};
      //       this.setState({error:"Thanku for ratting"},()=>
      //       {
      //          $('.errorpop2').addClass('displayblock');
      //       });
      //       var objIndex = this.state.ratings.findIndex((obj => obj.user_id == localStorage.getItem("userid")));
      //       if(objIndex!== -1)
      //       {
      //          this.state.ratings[objIndex].ratting=this.state.ratting;
      //          this.setState({ratings:this.state.ratings});
      //       }
      //       else{
      //          this.state.ratings.push(object);
      //          this.setState({ratings:this.state.ratings});
      //       }
      //          var dish = await addRatting(object);
      //          var dish = await Fetchdish(this.state.dishid);
      //          this.setState({price:dish.price,price2:dish.price,details:dish,category:dish.category,Ingredient:dish.Ingredient,allergeni:dish.allergeni});
      //    } 
      // }
      // removepop()
      // {
      //    $('.errorpop2').removeClass('displayblock');
      // }
      incress()
      {
         var data = this.state.count+1;
         var price = Number(this.state.price2)*data;
         this.setState({count:data,price:price});
      }
      dicrees()
      {
         if(this.state.count>1)
         {
            var data = this.state.count-1;
            var price = Number(this.state.price2)*data;
            this.setState({count:data,price:price});
         }
      }
      addtocart= async ()=>
      {
         if(this.state.wait!=="true")
         {
            // console.log('under function')
         this.setState({wait:"true"});
          var object={'dish_name':this.state.details.dish_name,'actual_price':this.state.price2,'image':this.state.details.featured_image,
         'price':this.state.price,'quantity':this.state.count,'ingrident':this.state.Ingredient,'allergeni':this.state.allergeni,'category':this.state.category,
         'peso':this.state.details.Peso,'menu_id':this.state.dishid,'resturent_name':this.state.resutName,'resturent_id':this.state.details.resturent_id,'user_id':localStorage.getItem("sessionId"),'confirm_by_restu':"pending"}
            var dish = await AddtoCart(object);
            if(dish=='data3' || dish=='data4')
            {
            this.props.history.push('/Cart');
            }
            else
            {
               this.setState({wait:"false",model:"open",popdish:dish},()=>
               {
                
                  $('#clearcart').addClass('show displaynoneclasss');
               })
            }
         }
      }
      goback()
      {
         this.props.history.goBack();
      }
      likedish=async()=>
      {
         var Obj={'image':this.state.details.featured_image,'name':this.state.details.dish_name,
         'menu_id':this.state.dishid,'resturent_id':this.state.details.resturent_id,'resturent_name':this.state.details.resturent_name};
         if(this.state.likes=="true")
         {
            this.setState({likes:"false"});
            var store = await LikedDish(Obj,"true");
         }
         else{
            this.setState({likes:"true"});
            var store = await LikedDish(Obj,"false");
   
         }
      }
     
    
      render()
      {
          return(
              <div><Header/>
<section className="single_menu">
   <div className="container">
   <button className="backbtn" onClick={this.goback.bind(this)}><i class="fas fa-arrow-left"></i></button>
      <div className="row">
      <div className="col-md-5 singlemenu_left">
         <div className="text-center w-100 img_round">
         <img src={this.state.details ? imagedir.url+this.state.details.featured_image: ""} alt="img"/>
      </div>
      <ul>

         <li><a data-toggle="modal" data-target="#exampleModalVideo"><img src="../../images/icon1.png" alt="icon"/> {language.singledish.tutorial}</a></li>
         <li onClick={this.likedish.bind(this)}><a>{this.state.likes=="true" ? <img src="../../images/ht.png" alt="icon"/> : <img src="../../images/icon22.jpg" alt="icon"/> } {language.singledish.like}</a></li>
<li class="dropdown">
  <a class="btn dropdown-toggle"id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="../../images/icon33.png" alt="icon"/>{language.singledish.share}</a>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

  <ShareLink link={'https://ucooking-fb04b.web.app/Sinledish/'+this.state.dishid}>
   {link => (
      <a class="dropdown-item" target='_blank' href={link} ><i class="fab fa-facebook-f"></i> {language.singledish.share_with_facebook}</a>
   )}
</ShareLink>
{/* <TwitterShareButton url={'https://ucooking-fb04b.web.app/Sinlepage/'+this.state.restuid}>
  {shareCount => <a class="dropdown-item" ><i class="fab fa-twitter"></i>Share Twitter</a>}
</TwitterShareButton> */}
    {/* <a class="dropdown-item"><i class="fab fa-instagram"></i> Share Instagram</a> */}
  
  </div>
</li>
         {/* <li><a href="#"><img src="../../images/icon33.png" alt="icon"/> Share</a></li> */}
      </ul>
      <h6> {language.singledish.categories} {this.state.category.length>0 ? this.state.category.map((j, x) => {
                    return(<a href="#" >{j.name+", "}</a>)}) : "" }</h6>
      </div>
      <div className="col-md-7 singlemenu_right">
         <h3>{this.state.details ? this.state.details.dish_name: ""}</h3>
         <h2>{this.state.details ?  "€ "+Number(this.state.price).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 })  : ""}</h2>
         <h5>
            {/* <span className="stars">
            { Math.round(this.state.details.ratting)==1 || Math.round(this.state.details.ratting)>1 ? <i className="fas fa-star"></i>: <i className="far fa-star"></i>}
            { Math.round(this.state.details.ratting)==2 || Math.round(this.state.details.ratting)>2 ? <i className="fas fa-star"></i>: <i className="far fa-star"></i>}
            { Math.round(this.state.details.ratting)==3 || Math.round(this.state.details.ratting)>3 ? <i className="fas fa-star"></i>: <i className="far fa-star"></i>}
            { Math.round(this.state.details.ratting)==4 || Math.round(this.state.details.ratting)>4 ? <i className="fas fa-star"></i>: <i className="far fa-star"></i>}
            { Math.round(this.state.details.ratting)==5 || Math.round(this.state.details.ratting)>5 ? <i className="fas fa-star"></i>: <i className="far fa-star"></i>}
          </span> */}
          {/* {this.state.show==true ? <span class="rattingbtn" data-toggle="modal" data-target="#ratting">Write a Review</span> : ""} */}
        </h5>

<div className="mb-4 mt-4">
         <div className="number"> 
                     <span  onClick={this.dicrees.bind(this)} className="minus">-</span>
                     <input type="text" value={this.state.count}/>
                     <span onClick={this.incress.bind(this)} className="plus">+</span>
                  </div>
                  <a onClick={this.addtocart.bind(this)} className="addcart" ><i className="fas fa-shopping-cart"></i> {this.state.wait=="true" ? "Please wait.....": "Add"}</a>
</div>
         <div className="int1 mt-4">
            <h5>{language.singledish.character_interpretation}</h5>
<p>{this.state.details.description}</p>
</div>
<div className="int1">
<p><b>{language.singledish.ingredienti}:</b>  {this.state.Ingredient.length>0 ? this.state.Ingredient.map((j, x) => {
                    return(<span>{j+", "}</span>)}) : "" }</p>
<p><b>{language.singledish.allergeni}:</b>  {this.state.allergeni.length>0 ? this.state.allergeni.map((j, x) => {
                    return(<span>{j+", "}</span>)}) : "" }</p>
<p><b>{language.singledish.minutes_of_preparation}:</b> {this.state.details.Peso}</p>
<p><b>{language.singledish.modalita_di_conservazione}:</b> {this.state.details.conservazione}</p>
<p><b>{language.singledish.modalita_di_rinvenimento}:</b> {this.state.details.rinvenimento}</p>
         </div>

         <div className="int1">
<h5>{this.state.resutName!="" ? "Who is "+this.state.resutName:""}</h5>
<p dangerouslySetInnerHTML={{__html:this.state.restuDesc!=="" ? this.state.restuDesc : ""}}></p>
</div>
{this.state.cosa.length>0 ? <>
   <div className="int1">
 <h5>{language.singledish.cosa_receverai_a_cosa} </h5>
{this.state.cosa.map((m,n)=>
{
   return(
  
   <><b>{m.name!=="" ? m.name :""}</b> <p>{m.description!=="" ? m.description : ""}</p></>
   )
})}</div></>
:""}

      </div>
   </div>
   </div>

</section>
{/* {this.state.ratings && this.state.ratings.length>0 ?
<section className="mainratting">
	<div className="container">
		<h3>Reatting List</h3>
		<div className="table-responsive">
            <table className="table table-bordered">
               <thead>
                  <tr>
                    <th scope="col">No.</th>
                     <th scope="col">Dish Name</th>
                     <th scope="col">Status</th>
                     <th scope="col">Price</th>
                      <th scope="col">Email</th>
                  </tr>
               </thead> 
               <tbody>
               { this.state.ratings.map((l, x) => {
               return( <tr>
                    <td><span className="img"><img src={l.image} alt="img"/></span></td>
                     <td>
                        <h4>{l.name}</h4>
                     </td>
                     <td>
                     	<h6>
                        	<span className="stars">
                           {(() => {
                              if(l.ratting==1)
                              {
                                 return(<i className="fas fa-star"></i>)
                              }
                              else if(l.ratting==2)
                              {
                                 return(<><i className="fas fa-star"></i>
                                 <i className="fas fa-star"></i></>)
                              }
                              else if(l.ratting==3)
                              {
                                 return(<><i className="fas fa-star"></i>
                                 <i className="fas fa-star"></i>
                                 <i className="fas fa-star"></i></>)
                              }
                               else if(l.ratting==4)
                              {
                                 return(<><i className="fas fa-star"></i>
                                 <i className="fas fa-star"></i>
                                 <i className="fas fa-star"></i>
                                 <i className="fas fa-star"></i></>)
                              }
                               else if(l.ratting==5)
                              {
                                 return(<><i className="fas fa-star"></i>
                                 <i className="fas fa-star"></i>
                                 <i className="fas fa-star"></i>
                                 <i className="fas fa-star"></i>
                                 <i className="fas fa-star"></i></>)
                              }
                           })()}
                        	</span>
                        	</h6>
                        </td>
                     <td>{l.price}</td>
                     <td>{l.email}</td>
                  </tr>)})}
                </tbody>
              </table>
            </div>

            <nav aria-label="..." className="text-center">
               <ul className="pagination">
                  <li className="page-item Previous">
                     <a className="page-link" href="#" tabindex="-1">Previous</a>
                  </li>
                  <li className="page-item">
                     <a className="page-link" href="#">1</a>
                  </li>
                  <li className="page-item">
                     <a className="page-link" href="#">2</a>
                  </li>
                  <li className="page-item">
                     <a className="page-link" href="#">3</a>
                  </li>
                  <li className="page-item">
                     <a className="page-link" href="#">4</a>
                  </li>
                  <li className="page-item">
                     <a className="page-link" href="#">5</a>
                  </li>
                  <li className="page-item Previous">
                     <a className="page-link" href="#">Next</a>
                  </li>
               </ul>
            </nav>
	</div>
</section>
:""} */}





{/* 
<!---------------- related_dishes -----------------------> */}
 { this.state.releted.length>0 ?
<section className="popular_dishes related_dishes">
   <div className="container">
      <h2 className="mb-4"><span>{language.singledish.related}</span> {language.singledish.product}</h2>

      

      { this.state.releted.length>0 ? this.state.releted.map((j, x) => {
   return( 
      <div className="test">
         <div className="row">
            <div className="col-sm-3">
               <img className="w-100" src={imagedir.url+j.image} alt="icon"/>
            </div>
             <div className="col-sm-6">
               {/* <h5>Eggplant or Zucchini Parmigiana - for 6 to 8 people</h5> */}
               <h2>{j.name}</h2>
{/* <h6>Eggplant parmigiana</h6> */}
<p>{j.description}</p>
{/* <p><b>Click Add and Choose the quantity you prefer.</b></p> */}
<Link to={"/Singledish/"+j.key}>{language.singledish.product_detail}</Link>
            </div>
             <div className="col-sm-3 text-center">
               <h3>{"€ "+Number(j.price).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</h3>
                <Link to={"/Singledish/"+j.key} className="addcart"><i className="fas fa-shopping-cart"></i> Add</Link>
                {/* <p className="time"><i className="far fa-clock"></i> Order now and receive from 08:30 tomorrow</p> */}
            </div>
         </div>
      </div>)}):<span>{language.singledish.empty_related_dishes}</span>}
</div>
</section>:""}





{/* <!--*********************** get_more ********************-->
<!-- <section className="get_more" style="background-image: url(./../../images/upbg.jpg);">
   <div className="container">
      <div className="row">
         <div className="col-md-4 ">
            <div className="get_more_left">
               <h2>Get More with our Mobile App</h2>
               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
               <ul>
                  <li><span>1</span> Order From any Location</li>
                  <li><span>2</span> Get important updates</li>
                  <li><span>3</span> Order From any Location</li>
               </ul>
            </div>
         </div>
         <div className="col-md-4 ">
            <div className="get_more_center text-center">
               <img src="../../images/appstore.png" alt="icon">
               <br>
               <img src="../../images/googleplay.png" alt="icon">
            </div>
         </div>
         <div className="col-md-4 text-center">
            <img className="w-75" src="../../images/umin.png" alt="img">
         </div>
      </div>
   </div>
</section>
 --> */}




{/* <!-- Modal --> */}
<div className="modal fade" id="exampleModalVideo" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div className="modal-content ">
     
      <div className="modal-body">
         <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
     <iframe className="w-100" height="415" src={this.state.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

{/* <div className="modal fade" id="ratting" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document" defaultStyle="max-width: 400px;">
    <div className="modal-content">

      <div className="modal-body reatting">
         <h5 className="modal-title" id="exampleModalLongTitle">Reatting</h5>
        <span type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </span>
        <div>
          <h6>
          <ReactStars
    count={5}
    onChange={this.ratingChanged.bind(this)}
    size={24}
    activeColor="#ffd700"
  />
          </h6>
<button type="button" onClick={this.submitrating.bind(this)}  className="btn btn-primary">Submit</button>
        </div>
      </div>
    </div>
  </div> */}
  {/* <div className="op errorpop2 invalid_login">
      <div className="errorbtn2 error h-100"></div>
      <ul>
         <li>{this.state.error}</li>
         <button className="upbtn errorbtn2" data-dismiss={this.state.error=="Thanku for ratting" ? "modal" : ""}  onClick={this.removepop.bind(this)}  >ok</button>
      </ul>
   </div> */}
{/* </div> */}


 <div className="modal fade" id="clearcart" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document" defaultStyle="max-width: 400px;">
    <div className="modal-content">

      <div className="modal-body cleardish">
         <h5 className="modal-title" id="exampleModalLongTitle">{language.singledish.clear_cart}</h5>
        <span type="button" className="close" onClick={this.closeclearpop.bind(this)} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </span>
        <p>{this.state.popdish.length>0 ? language.singledish.your_cart_has_existing_items_from+" "+this.state.popdish[0].resturent_name+". "+ language.singledish.do_you_want_to_clear_it_and_add_items+" ?" :""}</p>
        <div className="test">
           <h4><span>{language.singledish.restaurant}</span>{this.state.popdish.length>0 ? this.state.popdish[0].resturent_name:""}</h4>
            {/* <p>Connaught Place</p> */}
            </div>
        
        <div className="test">
        {this.state.popdish.length>0 ? this.state.popdish.map((j, x) => {
                    return(<div className="testin">
                    <span className="image"><img src={imagedir.url+j.image } alt="img"/></span>
                 <h5>{j.dish_name}</h5>
               <p>{j.category.length>0 ? "Type Of Dish : "+j.category[0].name : "Type Of Dish :"   }</p>
                 </div>)}):""}
        
        {/* <div className="testin">
           <span className="image"><img src={this.state.details ? this.state.details.featured_image: ""} alt="img"/></span>
        <h5>1 x Classic Margherita [11 Inch]</h5>
      <p>Type Of Cheese : Chipotle Cheese (Smokey spicy taste)</p>
        </div> */}
        </div>
       
        <div class="modal-footer">
        <button type="button" class="btn proceed" onClick={this.closeclearpop.bind(this)}>{language.singledish.close}</button>
        <button type="button" class="btn clear" onClick={this.clearCart.bind(this)}>{language.singledish.clear_cart}</button>
      </div>
        
      </div>
    </div>
  </div> 

</div>

</div>
)
}
}
export default Singledish;