import firebase from "firebase/app";
import 'firebase/firestore';
// import firestore from "./firestore";
export default async function FetchLikes (email) {
    try{
          var response =  await firebase.firestore().collection("Favourites")
            .where('user_id', '==', localStorage.getItem("userid")).get();
            const data = await response.docs.map(doc => doc.data());
               if(data.length>0)
               {
                   return await data;
               }
               else{
                   return await "empty";
               }
            }
            catch{

            }     
}