
import firebase from "firebase/app";
import 'firebase/firestore';
// import firestore from "./firestore";
export default async function DeleteItem (key) {
               try {
                const documentSnapshot = await firebase.firestore().collection('Cart').doc(key).delete();
               return await documentSnapshot;
              } catch {
           return 'error';
              }
}
