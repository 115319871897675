import firebase from "firebase/app";
import 'firebase/firestore';
// import firestore from "./firestore";
export default async function updateuser (object) {
               try {
                 var update = await firebase.firestore().collection('Users').doc(localStorage.getItem("userid")).update(object);
                var dataimage =  await firebase.firestore().collection('Users').doc(localStorage.getItem("userid")).get();
               
                  const userData =   await dataimage.data();
                  localStorage.setItem('phone',userData.phone);
                  localStorage.setItem('city',userData.city);
                  localStorage.setItem('web',userData.web);
                  localStorage.setItem('firstname',userData.first_name);
                  localStorage.setItem('lastname',userData.last_name);
                  localStorage.setItem('image',userData.image ? userData.image : null);
                }
           catch {
           return 'error';
              }
}