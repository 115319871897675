import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import $, { param } from 'jquery';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/firestore';
import updateuser from '../API/updateuser.js';
import moment from 'moment';
import Header from '../Header/index.js';
import language from '../API/language.json';
import imagedir from '../API/language.json';

class Myprofile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            url: '',
            imageVideoUrl: '',
            orders: [],
            loader: false,
            activePage: 1,
            finalydata: [],
            phone:
                localStorage.getItem('phone') &&
                    localStorage.getItem('phone') !== 'null'
                    ? localStorage.getItem('phone')
                    : '',
            web:
                localStorage.getItem('web') &&
                    localStorage.getItem('web') !== 'null'
                    ? localStorage.getItem('web')
                    : '',
            lastname: localStorage.getItem('lastname'),
            firstname: localStorage.getItem('firstname'),
            city:
                localStorage.getItem('city') &&
                    localStorage.getItem('city') !== 'null'
                    ? localStorage.getItem('city')
                    : '',
            fullname:
                localStorage.getItem('firstname') +
                ' ' +
                localStorage.getItem('lastname'),
            userImage: '',
            date: Date.now(),
            time: Date.now(),
            loader: 'false',
        };
    }
    componentDidMount = async () => {
        var response = await firebase
            .firestore()
            .collection('orders')
            .where('user_id', '==', localStorage.getItem('userid'))
            .get();
        const userData = await response.docs.map((doc) => doc.data());
        if (userData.length > 0) {
            var title1 = [];
            for (let index = 0; index < userData.length; index++) {
                for (
                    let index2 = 0;
                    index2 < userData[index].items.length;
                    index2++
                ) {
                    title1.push({
                        name: userData[index].items[index2].dish_name,
                        image: userData[index].items[index2].image,
                        quantity: userData[index].items[index2].quantity,
                        total: userData[index].items[index2].price,
                        date: this.getdateformat(
                            userData[index].current_date / 1000
                        ),
                    });
                }
            }
        }
        this.setState({ orders: title1 }, () => {
            this.handlePageChange(1);
        });
    };
    updateform() {
        if (this.state.loader !== true) {
            this.setState({ loader: true });
            if (this.state.imageVideoUrl !== '') {
                var body = { image: this.state.imageVideoUrl };
                axios
                    .post(
                        'https://foodapi.ucooking.it/imageurl_ucooking',
                        body,
                        {
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                        }
                    )
                    .then((res) => {
                        if (res.data.image) {
                            var object = {
                                first_name:
                                    this.state.firstname == ''
                                        ? localStorage.getItem('firstname')
                                        : this.state.firstname,
                                last_name:
                                    this.state.lastname == ''
                                        ? localStorage.getItem('lastname')
                                        : this.state.lastname,
                                phone:
                                    this.state.phone == ''
                                        ? localStorage.getItem('phone')
                                        : this.state.phone,
                                city:
                                    this.state.city == ''
                                        ? localStorage.getItem('city') &&
                                            localStorage.getItem('city') !==
                                            'null'
                                            ? localStorage.getItem('city')
                                            : ' '
                                        : this.state.city,
                                web:
                                    this.state.web == ''
                                        ? localStorage.getItem('web') &&
                                            localStorage.getItem('web') !== 'null'
                                            ? localStorage.getItem('web')
                                            : ' '
                                        : this.state.web,
                                image: res.data.image,
                            };
                            this.updatewithimage(object);
                        }
                    });
            } else {
                var object = {
                    first_name:
                        this.state.firstname == ''
                            ? localStorage.getItem('firstname')
                            : this.state.firstname,
                    last_name:
                        this.state.lastname == ''
                            ? localStorage.getItem('lastname')
                            : this.state.lastname,
                    phone:
                        this.state.phone == ''
                            ? localStorage.getItem('phone')
                            : this.state.phone,
                    city:
                        this.state.city == ''
                            ? localStorage.getItem('city') &&
                                localStorage.getItem('city') !== 'null'
                                ? localStorage.getItem('city')
                                : ' '
                            : this.state.city,
                    web:
                        this.state.web == ''
                            ? localStorage.getItem('web') &&
                                localStorage.getItem('web') !== 'null'
                                ? localStorage.getItem('web')
                                : ' '
                            : this.state.web,
                };
                this.updatewithimage(object);
            }
        }
    }
    updatewithimage = async (object) => {
        var data = await updateuser(object);
        this.setState({ loader: false });
    };
    changefile = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            var streetaddress = reader.result.substr(
                0,
                reader.result.indexOf('/')
            );
            if (streetaddress == 'data:image') {
                this.setState({
                    imageVideoUrl: reader.result,
                });
            }
        };
    };
    changephone(e) {
        this.setState({ phone: e.target.value });
    }
    changeweb(e) {
        this.setState({ web: e.target.value });
    }
    changelast(e) {
        this.setState({ lastname: e.target.value });
    }
    changefirst(e) {
        this.setState({ firstname: e.target.value });
    }
    changecity(e) {
        this.setState({ city: e.target.value });
    }
    weekDays = (dayNumber) => {
        if (dayNumber == 0) {
            return 'Sunday';
        }
        if (dayNumber == 6) {
            return 'Saturday';
        }
        if (dayNumber == 5) {
            return 'Friday';
        }
        if (dayNumber == 4) {
            return 'Thursday';
        }
        if (dayNumber == 3) {
            return 'Wednesday';
        }
        if (dayNumber == 2) {
            return 'Tuesday';
        }
        if (dayNumber == 1) {
            return 'Monday';
        }
    };
    getdateformat(datedata) {
        if (datedata) {
            var postedDate = moment.unix(datedata).format('YYYY-MM-DD');
            var currentDate = moment(new Date()).format('YYYY-MM-DD');
            var eventDate = datedata;
            var date = '';
            var duration = moment.duration(
                moment(postedDate).diff(moment(currentDate))
            );
            var daysDifference = duration.asDays();
            if (daysDifference <= -8 || daysDifference > 7) {
                const day = moment.unix(eventDate).format('D');
                const month = moment.unix(eventDate).format('MMM');
                const time = moment.unix(eventDate).format('h:mma');
                var lasttag = '';
                switch (day % 10) {
                    case 1:
                        if (day != 11) {
                            lasttag = 'st';
                        } else {
                            lasttag = 'th';
                        }
                        break;
                    case 2:
                        if (day != 12) {
                            lasttag = 'nd';
                        } else {
                            lasttag = 'th';
                        }
                        break;
                    case 3:
                        if (day != 13) {
                            lasttag = 'rd';
                        } else {
                            lasttag = 'th';
                        }
                        break;
                    default:
                        lasttag = 'th';
                        break;
                }
                date = `${month} ${day}${lasttag}, ${time}`;
                return date;
            }
            if (daysDifference < -1 && daysDifference > -8) {
                date =
                    'last' +
                    ' ' +
                    this.weekDays(moment(postedDate).weekday()) +
                    ' ' +
                    'at' +
                    ' ' +
                    moment.unix(eventDate).format('h:mma');
                return date;
            }
            if (daysDifference < 0 && daysDifference >= -1) {
                date = 'yesterday at ' + moment.unix(eventDate).format('h:mma');
                return date;
            }
            if (daysDifference >= 0 && daysDifference < 1) {
                date = moment.unix(eventDate).format('h:mma');
                return date;
            }
            if (daysDifference >= 1 && daysDifference < 2) {
                date = 'tomorrow at ' + moment.unix(eventDate).format('h:mma');
                return date;
            }
            if (daysDifference >= 2 && daysDifference < 7) {
                date =
                    'this' +
                    ' ' +
                    this.weekDays(moment(postedDate).weekday()) +
                    ' ' +
                    'at' +
                    ' ' +
                    moment.unix(eventDate).format('h:mma');
                return date;
            }
            if (daysDifference == 7) {
                date =
                    'next' +
                    ' ' +
                    this.weekDays(moment(postedDate).weekday()) +
                    ' ' +
                    'at' +
                    ' ' +
                    moment.unix(eventDate).format('h:mma');
                return date;
            }
        }
    }
    handlePageChange(pageNumber) {
        var title = [];
        for (let index = 0; index < this.state.orders.length; index++) {
            if (index <= pageNumber * 5 && pageNumber * 5 - 5 <= index) {
                if (title.length <= 4) {
                    title.push(this.state.orders[index]);
                }
            }
        }
        this.setState({ activePage: pageNumber, finalydata: title });
    }
    render() {
        return (
            <>
                <Header />
                <section class='profile_section'>
                    {/* <div class="pro_profile">
      <div class="container">
         <div class="profilehead">
            <span>
            <img src={localStorage.getItem('image') && localStorage.getItem('image')!==null && localStorage.getItem('image')!=="null" ? localStorage.getItem('image') : "images/empty.webp"} alt="img"/>
            </span>
            <h5>{this.state.fullname}</h5>
            <p>
            </p>
         </div>
      </div>
   </div> */}

                    <div class='container'>
                        <div class='profile_in'>
                            <div class='profile_sider'>
                                <h4>{language.profilePages.ACTIVITY}</h4>
                                <ul>
                                    <li>
                                        <Link to={'/Myprofile'} class='reviews'>
                                            {' '}
                                            {language.profilePages.my_profile}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/delivery_address'}>
                                            {
                                                language.profilePages
                                                    .delivery_address
                                            }
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/Myorder'}>
                                            {
                                                language.profilePages
                                                    .order_history
                                            }
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/Myfavorite'}>
                                            {
                                                language.profilePages
                                                    .favorite_dish
                                            }
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/ChnagePassword'}>
                                            {
                                                language.profilePages
                                                    .change_password
                                            }
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div class='reviews prof'>
                                <h3>{language.profilePages.my_profile}</h3>

                                <div class='form-group'>
                                    <label>
                                        {language.profilePages.first_name}
                                    </label>
                                    <input
                                        type='text'
                                        class='form-control'
                                        onChange={this.changefirst.bind(this)}
                                        value={this.state.firstname}
                                        placeholder={
                                            language.profilePages.first_name
                                        }
                                    />
                                </div>
                                <div class='form-group'>
                                    <label>
                                        {language.profilePages.last_name}
                                    </label>
                                    <input
                                        type='text'
                                        class='form-control'
                                        onChange={this.changelast.bind(this)}
                                        value={this.state.lastname}
                                        placeholder={
                                            language.profilePages.last_name
                                        }
                                    />
                                </div>
                                <div class='form-group'>
                                    <label>
                                        {language.profilePages.website}
                                    </label>
                                    <input
                                        type='text'
                                        class='form-control'
                                        onChange={this.changeweb.bind(this)}
                                        value={this.state.web}
                                        placeholder={
                                            language.profilePages.website
                                        }
                                    />
                                </div>
                                <div class='form-group'>
                                    <label>
                                        {language.profilePages.phone_number}
                                    </label>
                                    <input
                                        type='text'
                                        class='form-control'
                                        onChange={this.changephone.bind(this)}
                                        value={this.state.phone}
                                        placeholder={
                                            language.profilePages.phone_number
                                        }
                                    />
                                </div>
                                <div class='form-group'>
                                    <label>{language.profilePages.city}</label>
                                    <input
                                        type='text'
                                        class='form-control'
                                        onChange={this.changecity.bind(this)}
                                        value={this.state.city}
                                        placeholder={language.profilePages.city}
                                    />
                                </div>
                                <div class='form-group'>
                                    <label>
                                        {language.profilePages.profile_image}
                                    </label>
                                    <input
                                        type='file'
                                        class='form-control'
                                        onChange={this.changefile.bind(this)}
                                    />
                                </div>
                                <div class='banneruser form-group'>
                                    <span>
                                        <img
                                            src={
                                                this.state.imageVideoUrl == ''
                                                    ? localStorage.getItem(
                                                        'image'
                                                    ) &&
                                                        localStorage.getItem(
                                                            'image'
                                                        ) !== null
                                                        ? imagedir.url +
                                                        localStorage.getItem(
                                                            'image'
                                                        )
                                                        : 'images/empty.webp'
                                                    : this.state.imageVideoUrl
                                            }
                                            className='w-100'
                                            alt='img'
                                        />
                                    </span>
                                </div>

                                <div class='form-group'>
                                    <button
                                        type='button'
                                        onClick={this.updateform.bind(this)}
                                        class='btn '
                                    >
                                        {this.state.loader == true
                                            ? language.profilePages.update +
                                            '...'
                                            : language.profilePages.update}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
export default Myprofile;
