import firebase from "firebase/app";
import 'firebase/firestore';
// import firestore from "./firestore";
export default async function LikeResturest(obj,check) {
  try{
      if(check=="false")
      {
    const userRef2 = await firebase.firestore().collection('Favourite_restaurants').add(obj);
    const data4 = await userRef2.docs.map(doc => doc.id);
    return data4;
}
else{
    var response =  await firebase.firestore().collection('Favourite_restaurants')
.where('user_id', '==', localStorage.getItem("userid")).where('resturent_id', '==', obj.resturent_id).get();
const data4 = await response.docs.map(doc => doc.id);
if(data4.length>0)
{
        var error = await firebase.firestore().collection('Favourite_restaurants').doc(data4[0]).delete();
    return await error;
}
}
  }
  catch(e){
    return "error"
  }
}